<template>
  <v-container fluid class="pa-0">
    <template v-for="(row,index) in rowsModel">
      <MjEDocumentServiceInputRow :key="row.id"
                                  :description="row.description"
                                  :quantity="row.quantity"
                                  :price="row.price"
                                  :id="row.id"
                                  @changeRowData="(eventData) => changeRowData(eventData,index)"
                                  @removeRow="removeRow">

      </MjEDocumentServiceInputRow>
    </template>
    <v-row class="mt-2">
      <v-col>
        <v-btn @click="addRow" x-small color="primary">Aggiungi Riga
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import MjEDocumentServiceInputRow from "@/components/custom/MjEDocumentServiceInputRow";

export default {
  name: "MjEDocumentServices",
  components: {MjEDocumentServiceInputRow},
  props: {rows: {default: () => [{id: 0}]}},
  created() {

  },
  computed: {},
  data:
      function () {
        return {
          rowsModel: this.rows ?? [{id:0}]
        }
      },
  methods: {

    addRow() {
      this.rowsModel.push({id: Date.now()});
      this.$emit("change", this.rowsModel);
    },
    changeRowData(rowData, index) {
      this.$set(this.rowsModel, index, rowData);
      this.$emit("change", this.rowsModel);
    },
    removeRow(rowId) {
      if (this.rowsModel.length > 1) {
        this.rowsModel = this.rowsModel.filter((row) => row.id != rowId);
        this.$emit("change", this.rowsModel);
      }
    }
  },
  watch:{
    rows: {
      deep:true,
      handler:function(){
        this.rowsModel = this.rows ?? [{id:0}];
      }
    }
  }

}
</script>

<style scoped>

</style>