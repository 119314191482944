import mj_axios from "@/mj_axios";

const studioServiceMixin = {

    data: function () {
        return {
            studioServices: [],
            loadingStudioServices: 0,
        };
    },

    methods: {
        loadStudioServices() {
            this.loadingStudioServices++;
            const axios_promise = mj_axios.get('/api/studio_services');
            axios_promise.then(response => {
                this.studioServices = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi imballo, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingStudioServices--;
            });
            return axios_promise;
        },
    },

};

export default studioServiceMixin;