<template>
  <span>
  <v-tooltip bottom >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          :loading="pdf_downloading > 0" class="white--text ml-2" color="red"
          @click="show_account_balance_pdf_dialog = true;">
        <v-icon>{{ customers_selected.length == 1 ? 'mdi-file-table' : 'mdi-folder-zip-outline' }}</v-icon>
      </v-btn>

    </template>
    <span>Genera Estratto Conto</span>
  </v-tooltip>
  <v-dialog v-model="show_account_balance_pdf_dialog" max-width="300px">
    <v-card>
      <v-card-title class="small mb-4">Scarica Estratti Conto
        <v-spacer></v-spacer>
        <v-btn @click="show_account_balance_pdf_dialog = false" fab x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <MjDateOrTimePicker
            label='Da'
            v-model="account_balance_from"
            model_format='YYYY-MM-DD'
            mode='date'
            display_format='DD-MM-YYYY'
        ></MjDateOrTimePicker>
        <MjDateOrTimePicker
            label='A'
            v-model="account_balance_to"
            model_format='YYYY-MM-DD'
            mode='date'
            display_format='DD-MM-YYYY'
        ></MjDateOrTimePicker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red white--text" @click="downloadPDF" :loading="pdf_downloading > 0">Scarica PDF</v-btn>
        <v-btn color="blue darken-4 white--text" @click="downloadHTML" :loading="html_downloading > 0">Scarica DOC
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>
<script>

import mj_axios from "@/mj_axios";
import moment from "moment";
import MjDateOrTimePicker from "../../form/MjDateOrTimePicker.vue";

export default {
  name: "downloadAccountBalancePDFBtn",
  components: {MjDateOrTimePicker},
  props: {
    customers_selected: {type: Array, default: () => []},
  },
  data: () => ({
    show_account_balance_pdf_dialog: 0,
    pdf_downloading: 0,
    html_downloading: 0,
    account_balance_from: window.localStorage.getItem('account_balance_from') || moment().startOf('year').format('YYYY-MM-DD'),
    account_balance_to: window.localStorage.getItem('account_balance_to') || moment().endOf('year').format('YYYY-MM-DD'),
  }),
  methods: {
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },
    downloadPDF: function () {
      const customer_ids = this.customers_selected.map((customer) => customer.id);
      const account_balance_from = this.account_balance_from ? this.account_balance_from : '';
      const account_balance_to = this.account_balance_to ? this.account_balance_to : '';

      window.localStorage.setItem('account_balance_from', account_balance_from);
      window.localStorage.setItem('account_balance_to', account_balance_to);

      const url = `/api/account_balance_pdf?customer_ids=${JSON.stringify(customer_ids)}&from=${account_balance_from}&to=${account_balance_to}`;
      this.pdf_downloading++;

      //caso selezione singola per nome pdf
      const customer = (this.customers_selected && this.customers_selected.length == 1) ? this.customers_selected[0] : {};

      const filename = this.customers_selected.length == 1 ? `${customer['Ragione Sociale']} EstrattoConto.pdf` : `EstrattiConto.zip`;
      mj_axios.download(url, filename).then(() => {
        this.pdf_downloading--;
        this.show_account_balance_pdf_dialog = false;
      }).catch(() => {
        this.pdf_downloading--;
      });

    },
    downloadHTML: function () {
      const customer_ids = this.customers_selected.map((customer) => customer.id);
      const account_balance_from = this.account_balance_from ? this.account_balance_from : '';
      const account_balance_to = this.account_balance_to ? this.account_balance_to : '';

      window.localStorage.setItem('account_balance_from', account_balance_from);
      window.localStorage.setItem('account_balance_to', account_balance_to);

      const url = `/api/account_balance_html?customer_ids=${JSON.stringify(customer_ids)}&from=${account_balance_from}&to=${account_balance_to}`;
      this.html_downloading++;

      //caso selezione singola per nome pdf
      const customer = (this.customers_selected && this.customers_selected.length == 1) ? this.customers_selected[0] : {};

      const filename = this.customers_selected.length == 1 ? `${customer['Ragione Sociale']} EstrattoConto.html.doc` : `EstrattiConto.zip`;
      mj_axios.download(url, filename).then(() => {
        this.html_downloading--;
        this.show_account_balance_pdf_dialog = false;
      }).catch(() => {
        this.html_downloading--;
      });

    },
  }
}
</script>