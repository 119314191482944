import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"width":"600px","flat":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.title))]),_c(VCardSubtitle,{staticClass:"small"},[_vm._v(_vm._s(_vm.subtitle)+" ")]),_c(VCardText,[_c(VForm,{ref:"contactForm"},[_c(VTextField,{attrs:{"label":"Nome","rules":[_vm.rules.max(30)]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{attrs:{"label":"Email","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"label":"Telefono","rules":[_vm.rules.max(30)]},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}}),_c(VTextarea,{attrs:{"label":"Note","rows":"2","rules":[_vm.rules.max(100)]},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c(VBtn,{attrs:{"color":"primary","large":"","loading":_vm.sending >0},on:{"click":_vm.submitForm}},[_vm._v("Invia "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-send")])],1)],1)],1),_c(VSnackbar,{attrs:{"true":"showSnackbar"}},[_vm._v("adf")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }