<template>
<span>
  <v-tooltip bottom >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          color="green white--text"
          class="ml-2"
          @click="show_add_transactions_dialog = true"
      >
        <v-icon>mdi-credit-card-plus</v-icon>
      </v-btn>
    </template>
    <span>Aggiungi Transazione</span>
  </v-tooltip>
  <v-dialog v-model="show_add_transactions_dialog" max-width="600px">
    <v-card>
      <v-card-title>Aggiungi Transazione
        <v-spacer></v-spacer>
        <v-btn @click="show_add_transactions_dialog = false" fab x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <SchedaTransaction :force-disable-back-on-save="true"
                           :multiple-customer-mode="true"
                           :customers="customers_selected"
                           :router-replace-after-save="false"
                           @saved="show_add_transactions_dialog  = false">
        </SchedaTransaction>
      </v-card-text>
    </v-card>
  </v-dialog>
  </span>
</template>
<script>

import SchedaTransaction from "@/views/Custom/SchedaTransaction.vue";

export default {
  name: "downloadContractPDFBtn",
  components: {SchedaTransaction},
  props:{
    selected_ids: {type:Array, default:()=>[]},
    customers_selected:{type:Array, default:()=>[]},
  },
  data:()=>({
    accounting_contract_pdf_downloading: 0,
    show_add_transactions_dialog: false,
  }),
  methods:{

  }
}
</script>


<style scoped>

</style>