import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto",attrs:{"width":"600px","flat":""}},[_c(VCardTitle,{staticClass:"justify-center"},[_vm._v(_vm._s(_vm.title))]),_c(VCardSubtitle,{staticClass:"small"},[_vm._v(_vm._s(_vm.subtitle)+" ")]),_c(VCardText,[_c(VForm,{ref:"contactForm"},[_c(VTextField,{attrs:{"label":"Nome Studio","rules":[_vm.rules.required,_vm.rules.max(30)]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c(VTextField,{attrs:{"label":"Email","rules":[_vm.rules.required, _vm.rules.email]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"label":"Telefono","rules":[_vm.rules.max(30)]},model:{value:(_vm.mobile_number),callback:function ($$v) {_vm.mobile_number=$$v},expression:"mobile_number"}}),_c(VTextField,{attrs:{"label":"Password","rules":[_vm.rules.required,_vm.rules.min(8),_vm.rules.max(30)],"type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],attrs:{"type":_vm.alertType,"dense":""}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.showRegistrationButton),expression:"showRegistrationButton"}],attrs:{"color":"primary","large":"","loading":_vm.sending >0},on:{"click":_vm.submitForm}},[_vm._v("Registrati Adesso "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }