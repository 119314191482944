<template>
  <span>
    <v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2 mt-1 white--text" small fab v-bind='attrs' v-on='on' color="purple"
                   :loading="accounting_contract_pdf_sending > 0"
                   @click.stop='sendContractPDF'>
              <v-icon>
                mdi-email-edit-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Invia Contratto</span>
        </v-tooltip>
  </span>
</template>
<script>

import mj_axios from "@/mj_axios";

export default {
  name: "emailContractPDFBtn",
  props: {
    customers_selected: {type: Array, default: () => []},
  },
  data: () => ({
    accounting_contract_pdf_sending:0
  }),
  methods: {
    sendContractPDF: function () {
      this.accounting_contract_pdf_sending++;

      const customer_ids = this.customers_selected.map((customer) => customer.id);

      const axios_promise = mj_axios.get(`/api/send_contract_pdf?customer_ids=${JSON.stringify(customer_ids)}`);
      axios_promise.then(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "L'invio del contratto è stato inserito nella coda",
          visible: true,
        });
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.accounting_contract_pdf_sending--;
      });
      return axios_promise;
    },
  }
}
</script>