import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VOverlay,{attrs:{"value":_vm.loading_overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,[_c('div',[_vm._v("Sotto Adempimento - Task "+_vm._s(_vm.$route.params.id))]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VTextField,{attrs:{"label":"Cod","rules":[_vm.rules.max(255)]},model:{value:(_vm.form.cod.val),callback:function ($$v) {_vm.$set(_vm.form.cod, "val", $$v)},expression:"form.cod.val"}})],1),_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VTextField,{attrs:{"label":"Nome","rules":[_vm.rules.max(255)]},model:{value:(_vm.form.name.val),callback:function ($$v) {_vm.$set(_vm.form.name, "val", $$v)},expression:"form.name.val"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Nota","rows":"2"},model:{value:(_vm.form.note.val),callback:function ($$v) {_vm.$set(_vm.form.note, "val", $$v)},expression:"form.note.val"}})],1)],1),_c('MjSchedaToolbar',{attrs:{"error_show":_vm.error_show,"error_text":_vm.error_text,"edit_not_saved":_vm.edit_not_saved,"edit_mode":_vm.editMode,"new_button_visibile":true,"restore_item_show":_vm.restore_item_show},on:{"restore-item-click":function($event){return _vm.restoreItem()},"new-item-click":function($event){return _vm.newItem()},"save-item-click":function($event){return _vm.saveItem()},"go-back-click":function($event){return _vm.goBack()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }