import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":"","color":"blue white--text"},on:{"click":function($event){_vm.show_email_exporter = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-email-multiple")])],1)]}}])},[_c('span',[_vm._v("Esportazione Emails")])]),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.show_email_exporter),callback:function ($$v) {_vm.show_email_exporter=$$v},expression:"show_email_exporter"}},[_c(VCard,[_c(VCardTitle,{staticClass:"small mb-4"},[_vm._v("Esporta Emails "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.show_email_exporter = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_vm._v(" "+_vm._s(this.customerEmailList)+" ")]),_c(VCardActions,{staticClass:"pb-3"},[_c(VSpacer),_c(VBtn,{attrs:{"right":"","small":"","color":"primary"},on:{"click":_vm.copyCustomerEmailToClipboard}},[_vm._v(" Copia nella clipboard "),_c(VIcon,{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }