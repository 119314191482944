<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Forma Giuridica {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.required,rules.max(16)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Description'
                v-model='form.description.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>

        </v-row>

        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";


export default {
  name: "SchedaLegalStatus",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      description: {type: 'string', val: '', default_val: ''},
    },
    apiUrl: "/api/legal_statuses"
  }),

  methods: {}
};
</script>
