import Vue from 'vue'
import VueRouter from 'vue-router'

import {custom_routes} from './custom.router.js';

import ApiTesterView from './views/Misc/ApiTesterView.vue'
import TestView from './views/Misc/TestView.vue'
import TestStripeView from './views/Misc/TestStripeView.vue'
import UsersTableView from './views/Admin/UsersTableView.vue'
import SchedaCreditTransactionLog from './views/Admin/SchedaCreditTransactionLog.vue'
import CreditTransactionLogView from './views/Admin/CreditTransactionLogView.vue'
import ContactFormView from './views/ContactFormView.vue'


//user
import LoginView from './views/User/LoginView.vue'
import RegistrationView from './views/User/RegistrationView.vue'
import RecoverPasswordView from './views/User/RecoverPasswordView.vue'
import ResetPasswordView from './views/User/ResetPasswordView.vue'
import AccountActivationView from './views/User/AccountActivationView.vue'

import ListaItems from './views/ListaItems.vue'
import MjTableItems from './components/MjTableItems.vue'
import SchedaRoleView from './views/User/SchedaRoleView.vue'
import SchedaUserView from './views/User/SchedaUserView.vue'
import SchedaEntitaEsempioView from './views/SchedaEntitaEsempioView.vue'


import GenericErrorView from './views/GenericErrorView.vue'
import store from './store.js'
import mj_config from './mj_config.js'
import MyAccountView from "@/views/User/MyAccountView";

Vue.use(VueRouter)
const router = new VueRouter();
  const mj_template_routes =  [
    { path: '/test_api', component: ApiTesterView, meta: { title: 'Api Tester', role: ['SuperAdmin'], header: 'close' } },
    { path: '/test_view', component: TestView, meta: { title: 'Test View', role: ['SuperAdmin','Admin'] } },

    { path: '/scheda_role', name: 'NewSchedaRoleView ', component: SchedaRoleView , meta: { title: 'Scheda Ruolo', role: ['SuperAdmin'], header: 'close' } },
    { path: '/scheda_role/:id', name: 'SchedaRoleView ', component: SchedaRoleView , meta: { title: 'Scheda Ruolo', role: ['SuperAdmin'], header: 'close' } },
    { path: '/scheda_user', name: 'NewSchedaUser', component: SchedaUserView, meta: { title: 'Scheda Utente', role: ['SuperAdmin', 'Admin'], header: 'close' } },
    { path: '/scheda_user/:id', name: 'SchedaUser', component: SchedaUserView, meta: { title: 'Scheda Utente', role: ['SuperAdmin', 'Admin'], header: 'close' } },

    { path: '/', component: LoginView, meta: { title: 'Login', role: ['Public'] } },
    { path: '/utente/login', component: LoginView, meta: { title: 'Login', role: ['Public'] } },

    { path: '/utente/il_mio_profilo', name:'MyAccountView', component: MyAccountView, meta: { title: 'Il mio Profilo', role: ['SuperAdmin','Admin','Utente'] } },

    { path: '/error', component: GenericErrorView, meta: { title: 'Errore', role: ['Public'] } },


    //TODO role perchè può essere dinamico
    { path: '/lista/:id', component: ListaItems, meta: { title: 'Lista', role: ['Admin'] } },

    //Su table ci sono casi specifici
    // { path: '/table/ContatoriAttrezzature', component: MjTableItemsContatoriAttrezzature, meta: { title: 'Tabella', role:  ['SuperAdmin', 'Admin']} },

    { path: '/table/User', component: UsersTableView, meta: { title: 'Gestione Utenti', role: ['SuperAdmin', 'Admin'] } },
    { path: '/table/CreditTransactionLog', component: CreditTransactionLogView, meta: { title: 'Log Transazioni Crediti', role: ['SuperAdmin', 'Admin'] } },

    //e il caso generale
    { path: '/table/:id', component: MjTableItems, meta: { title: 'Tabella', role: ['SuperAdmin', 'Admin'] } },

    { path: '/scheda_entita_esempio', name: 'NewSchedaEntitaEsempio', component: SchedaEntitaEsempioView, meta: { title: 'Scheda Entita Esempio', role: ['SuperAdmin', 'Admin'], header: 'close' } },
    { path: '/scheda_entita_esempio/:id', name: 'SchedaEntitaEsempio', component: SchedaEntitaEsempioView, meta: { title: 'Scheda Entita Esempio', role: ['SuperAdmin', 'Admin'], header: 'close' } },
    { path: '/scheda_credit_transaction_log', name: 'NewSchedaCreditTransactionLog', component: SchedaCreditTransactionLog, meta: { title: 'Scheda Credit Transaction Log', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_credit_transaction_log/:id', name: 'SchedaCreditTransactionLog', component: SchedaCreditTransactionLog, meta: { title: 'Scheda Credit Transaction Log', role: ['SuperAdmin','Admin'], header: 'close' } },

    { path: '/test_stripe', name: 'TestStripe', component: TestStripeView, meta: { title: 'Test Stripe', role: ['SuperAdmin', 'Admin'], header: 'close' } },

    { path: '/utente/contact_form', component: ContactFormView, meta: { title: 'Registrati', role: ['Public'] } },
  ];

  const allow_registration_paths = [
{ path: '/utente/recupera_password', component: RecoverPasswordView, meta: { title: 'Recupera Password', role: ['Public'] } },
{ path: '/utente/resetta_password', component: ResetPasswordView, meta: { title: 'Resetta Password', role: ['Public'] } },
{ path: '/utente/registrati', component: RegistrationView, meta: { title: 'Registrati', role: ['Public'] } },
{ path: '/utente/attivazione', component: AccountActivationView, meta: { title: 'Account Attivato', role: ['Public'] } },

];

custom_routes.forEach((custom_route) => {
  router.addRoute(custom_route);
});

mj_template_routes.forEach((mj_template_route) => {
  router.addRoute(mj_template_route);
});
//in caso di duplicati (override di una path) vue router considera il primo definito (quindi custom_routes và prima)

if (mj_config.allow_registration()) {

  allow_registration_paths.forEach((allow_registration_path) => {
    router.addRoute(allow_registration_path);
  });
}

//global navigation guard
router.beforeEach(function (to, from, next) {
  (to, from, next)

  const resolve_resp = router.resolve(to);
  if( resolve_resp.resolved.matched.length > 0){
    //the route is exists.
    document.title = `${(to.meta.title || '')} - ${store.state.client_settings.APPNAME}`;
    const route_roles = to.meta.role;
    const user_role = store.state.token_obj.role || 'Public'
    //passa se la rotta è pubblica oppure il ruolo e la rotta corrispondono
    //nota bene, che essendo il ruolo modificabile clientside, bisogna sempre controverificare le risorse server side.
    if (route_roles && (route_roles.indexOf(user_role) !== -1 || route_roles.indexOf('Public') !== -1 || user_role === 'SuperAdmin')) {
      next();
    }
    else {

      //se è loggato allora mostro il modal
      if (store.state.token_obj != null &&
          store.state.token_obj !== "") {
        store.commit('update_modal_dialog', { title: 'Attenzione', text: 'Non sei autorizzato a visualizzare questo contenuto <br> Se non sei loggato o devi cambiare ruolo <a href="/utente/login">Vai al Login</a>', visible: true });
      }
      else { //altrimenti vai al login

        next({
          path: '/utente/login',
          params: { nextUrl: to.fullPath }
        })
      }
    }

  }else{
    //the route does not exists.

    next({
      path: '/error',
      query:{msg:'Pagina NON trovata'},

    })
  }



})
export default router;