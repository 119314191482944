<template>
  <v-container fluid class="pa-0">
    <section class="mx-0 px-0">
      <v-parallax :src="require('@/assets/images/landing/background4.png')" >

        <v-layout column align-center justify-center class="white--text">
          <h1 class="white--text text--darken-4 mb-2 display-1 text-xs-center"
              style="font-weight: 900; text-shadow: 1px 1px #222">
            Sinergy Manager
          </h1>
          <div class="white--text subheading mb-3 text-xs-center" style="font-weight: 900; text-shadow: 1px 1px #222">
            Software in  Cloud per il tuo Studio Professionale
          </div>
          <v-btn class="secondary grey--text text--darken-4 darken-1 mt-5" dark large href="#contact_form">
            Contattaci <v-icon right>mdi-send</v-icon>
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>
    <hr class="my-16 mx-auto secondary landing_hr" />
<!--    3 COLUMN KEY POINT -->
    <section class="my-16 mx-4 ">
      <v-row>
        <v-col cols="4">
          <v-card >
            <v-icon x-large class="primary--text mt-3" style="width:100%">mdi-cursor-default-click-outline</v-icon>
            <v-card-title class="font-weight-bold justify-center">Semplice da Usare</v-card-title>
            <v-card-text>Sinergy Manager è stato concepito da Professionisti per Professionisti. E' nato per soddisfare le esigenze gestionali dello Studio Professionale. Tutto ciò che ti serve a portata di click (o touch!)
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-icon x-large class="primary--text mt-3" style="width:100%">mdi-cloud-outline</v-icon>
            <v-card-title class="font-weight-bold justify-center">In Cloud</v-card-title>
            <v-card-text>Siamo in cloud! Vuol dire che non dovrai installare nessun software. Potrai
              accedere a tutte le funzinoalità da qualsiasi dispositivo e ovunque ti trovi, basterà avere un browser e
              una connessione a internet!
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-icon x-large class="primary--text mt-3" style="width:100%">mdi-folder-check-outline</v-icon>
            <v-card-title class="font-weight-bold justify-center">All in One</v-card-title>
            <v-card-text>Tutto in uno! Hai diverse funzioni che ti consentiranno di avere tutto sotto controllo con unico software: gestione parcellazione e compensi, adempimenti antiriclaggio, gestione ed assegnazione adempimenti di studio e molto altro
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <hr class="my-16 mx-auto secondary landing_hr" />
    <!-- FUNCTIONS-->
    <section class="my-16">
      <div class="text-center" >
        <v-sheet outlined color="primary white--text px-4 py-16 ">
          <h3 class="overline secondary--text font-weight-bold">Dai un occhio alle principali Funzionalità</h3>
          <div class="font-italic text--darken-3 px-8 mx-8">Il nostro software è sempre in espansione e miglioramento! Abbiamo molta considerazione anche dei suggerimenti dell'utenza per mettere appunto nuove feature!</div>
          <div class="my-4">
          <v-btn class="secondary black--text ma-2" ><v-icon left class="grey--text text--darken-3">mdi-card-account-details-outline</v-icon> Gestione Clienti</v-btn>
            <v-btn class="secondary black--text ma-2"><v-icon left class="grey--text text--darken-3">mdi-offer</v-icon>Gestione Servizi</v-btn>
            <v-btn class="secondary black--text ma-2"><v-icon left class="grey--text text--darken-3">mdi-check-all</v-icon>Gestione Adempimenti</v-btn>
            <v-btn class="secondary black--text ma-2"><v-icon left class="grey--text text--darken-3">mdi-text-box-outline</v-icon>Generazione Fattura Elettronica</v-btn>
            <v-btn class="secondary black--text ma-2"><v-icon left class="grey--text text--darken-3">mdi-card-bulleted-settings-outline</v-icon>Generazione Bollettini</v-btn>
            <v-btn class="secondary black--text ma-2"><v-icon left class="grey--text text--darken-3">mdi-format-list-bulleted</v-icon>Report Transazioni</v-btn>
            <v-btn class="secondary black--text ma-2"><v-icon left class="grey--text text--darken-3">mdi-file-pdf-box</v-icon>Esportazione Contratti e antiriciclaggio</v-btn>
          </div>
        </v-sheet>

      </div>
      <hr class="my-16 mx-auto secondary landing_hr" />
      <v-carousel class="mx-auto border rounded my-16"  cycle hide-delimiter-background progress style="max-width:640px" >
        <v-carousel-item v-for="(image,i) in carouselImages" :key="i">

          <v-img :src="image.requiredUrl" :alt="image.alt"/>
        </v-carousel-item>

      </v-carousel>
      <hr class="my-16 mx-auto secondary landing_hr" />
    </section>

    <section class="my-16">
      <v-img :src="require('@/assets/images/landing/landing_multi_device.png')" class=" mx-auto" max-width="800"></v-img>
    </section>
    <hr class="my-16 mx-auto secondary landing_hr" />
<!--    CONTACT ME-->
    <section class="text-center py-8" id="contact_form" >
      <MjStudioRegistrationForm class="" title="Registra il tuo studio"
                     subtitle="Ti invieremo una mail di conferma e dopo l'attivazione potrai accedere al software e provarlo in tutte le sue funzionalità..">

      </MjStudioRegistrationForm>
    </section>
    <div class="text-center">@Sinergy Manager</div>
  </v-container>
</template>

<script>
import MjStudioRegistrationForm from "../components/custom/MjStudioRegistrationForm.vue";

export default {
  name: "LandingView",
  components: {MjStudioRegistrationForm},
  data: function () {
    return {
      carouselImages: [
        {requiredUrl: require('@/assets/images/landing/screenshots/menu.png'), alt: 'Menu Funzionalità'},
        {requiredUrl: require('@/assets/images/landing/screenshots/customers.png'), alt: 'Gestione Clienti'},
        {requiredUrl: require('@/assets/images/landing/screenshots/edocuments.png'), alt: 'Fattura Elettronica'},
        {requiredUrl: require('@/assets/images/landing/screenshots/edocument.png'), alt: 'Fattura Elettronica'},
        {requiredUrl: require('@/assets/images/landing/screenshots/jobs.png'), alt: 'Gestione Adempimenti'},
        {requiredUrl: require('@/assets/images/landing/screenshots/transactions.png'), alt: 'Transazioni'},
      ]
    }
  }
}
</script>

<style scoped>
.landing_hr{
  height:2px ;
  border:none;
  width:80%
}
</style>