import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":"","color":_vm.btnColor}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c(VIcon,[_vm._v("mdi-account-group")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText))])])]}}]),model:{value:(_vm.show_collaborators),callback:function ($$v) {_vm.show_collaborators=$$v},expression:"show_collaborators"}},[_c('MjSelectableList',{attrs:{"title":"Collaboratori","load_items_url":"/api/collaborators","belong_url":"/api/parents_has_children","pivot_table":"customers","parent_col_name":"id","child_col_name":_vm.childColName,"parent_model_name":"Custom\\Customer","child_model_name":"User","relation_name":_vm.relationName,"list_title_field":"name","list_subtitle_field":"","max_height":"300px","exclusive":true,"parent_ids":_vm.selected_ids},on:{"close-popup":function($event){_vm.show_collaborators = false; _vm.$emit('close-popup')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }