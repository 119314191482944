<template>
    <v-container>
        <template v-if="results == null">
            <div class="primary--text">Chiudo l'anno contabile per <span
                    class="font-weight-bold lighten-3">({{ customers.length }})</span> Clienti Selezionati
            </div>
            <div class="text-caption">Il sistema calcola per l'anno indicato il bilancio, crea una voce di segno per il
                31-dicembre e una contraria per 01 gennaio dell'anno successivo<br> Potrai trovare le transazioni
                nell'apposita sezione
            </div>
            <v-text-field class="mt-6" label="Anno" hint="indica l'anno per il quale effettuare il bilanciamento"
                          v-model="year">
            </v-text-field>

            <v-row class="justify-end mt-6">
                <v-btn :color="confirmButtonColor"
                       @click="sure ? doAnnualBalance() : confirmButtonText = 'Sicuro?'; sure=true; confirmButtonColor = 'warning'"
                       :loading="processing > 0">{{ confirmButtonText }}
                </v-btn>
            </v-row>
        </template>
        <v-simple-table v-else class="mt-6">
            <thead>
            <th class="text-left">Cliente</th>
            <th>Importo Bilanciato</th>
            </thead>
            <tbody>
            <tr v-for="row in results" :key="row.id">
                <td>{{ row.business_name }}</td>
                <td class="text-right">{{ mj_string_utils.moneyFormat(row.balanced_amount) }}</td>
            </tr>
            </tbody>
        </v-simple-table>

    </v-container>
</template>

<script>
import mj_axios from "../../mj_axios";
import mj_string_utils from "../../misc/mj_string_utils";

export default {
    name: "AnnualBalancer",
    computed: {
        mj_string_utils() {
            return mj_string_utils
        }
    },
    props: {
        customers: {default: () => []}
    },
    data: () => ({
        year: new Date().getFullYear(),
        confirmButtonColor: 'primary',
        confirmButtonText: 'Procedi',
        sure: false,
        processing: 0,
        results: null,
    }),
    methods: {
        doAnnualBalance() {
            this.processing++;

            this.customerRows = this.customers.map((customer) => {

                return {id: customer.id, year: this.year};
            });

            const params = {
                "customersData": JSON.stringify(this.customerRows)
            };
            const axios_promise = mj_axios.post('/api/annual_balancer/actions/do', params);
            axios_promise.then((response) => {
                this.results = (response.data);
                this.$emit("saved", {});
            }).catch((e) => {
                let message =
                    e.response && e.response.data ? e.response.data.message : '';

                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: `Errore di salvataggio ${message} , è consigliato, prima di proseguire, risolvere il problema.`,
                    visible: true,
                });
            }).then(() => {
                this.processing--;
                this.confirmButtonText = "Procedi";
                this.sure = false;
                this.confirmButtonColor = "primary"
            });
            return axios_promise;
        },
    },
    watch: {
        customers: {
            deep: true,
            handler: function () {
                this.results = null;
            }
        }
    }
}
</script>

<style scoped>

</style>