import mj_anagrafica_utils from "@/misc/mj_anagrafica_utils.js"

var validationRulesMixin = {
        data: () => ({
                rules: {
                    required: value => !!value || "Campo Obbligatorio",
                    must_be_true: value => true == value || "Accettazione obbligatoria",
                    letters: value => {
                        const pattern = /^[A-Za-z]+$/
                        return (value == null || value === "" || pattern.test(value)) || "Inserisci solo lettere";
                    },
                    positive: value => parseFloat(value) > 0 || `Il numero deve essere positivo`,
                    integer: value => {
                        const pattern = /^-?\d+$/
                        return (value == null || value === "" || pattern.test(value)) || "Inserisci un Numero Intero";
                    },
                    float: value => {
                        //TODO - quella sotto è solo un copia e incolla di integer.
                        const pattern = /^-?\d+$/
                        return (value == null || value === "" || pattern.test(value)) || "Inserisci un Numero Decimale";
                    },
                    piva: value => {
                        //non valido se il valore non è presente (uso required se devo mettere questa regola)
                        if (value && value.length > 0) {
                            const resp = mj_anagrafica_utils.validatePIva(value);
                            return (resp && resp.code > 0) ? true : resp.message;
                        } else {
                            return true;
                        }
                    },
                    cf: value => {
                        //non valido se il valore non è presente (uso required se devo mettere questa regola)
                        if (value && value.length > 0) {
                            const resp = mj_anagrafica_utils.validateCF(value);
                            return (resp && resp.code > 0) ? true : resp.message;
                        } else {
                            return true;
                        }
                    },
                    email: value => {
                        //non valido se il valore non è presente (uso required se devo mettere questa regola)
                        if (value && value.length > 0) {
                            const resp = mj_anagrafica_utils.validateEmail(value);
                            return (resp && resp.code > 0) ? true : resp.message;
                        } else {
                            return true;
                        }
                    },
                    max(maxNum) {
                        return v => (v || '').length <= maxNum || 'Numero massimo di caratteri superato';
                    },
                    min(minNum) {
                        return v => (v || '').length >= minNum || `Digita almeno ${minNum} caratteri`;
                    },
                    minCount(minCount) {
                        return v => (v || '').length >= minCount || `Seleziona almeno ${minCount} elementi`;
                    },

                    phone(value) {
                        const pattern = /^([+]?\d{5,15})$/
                        return (value == null || value === "" || pattern.test(value)) || "Inserisci un Numero di Telefono Corretto";
                    },
                    iban: value =>{
                        const pattern = /^(?:(?:CR|DE|ME|RS|VA)\d{20}|(?:CZ|ES|SE|SK|TN)\d{22}|(?:DK|FI|FO|GL|SD)\d{16}|(?:AT|BA|EE|LT|XK)\d{18}|(?:AE|IL|TL)\d{21}|(?:LY|PT|ST)\d{23}|(?:IT|SM)\d{2}[A-Z]\d{10}[A-Za-z0-9]{12}|(?:HU|PL)\d{26}|(?:AL|CY)\d{10}[A-Za-z0-9]{16}|(?:CH|LI)\d{7}[A-Za-z0-9]{12}|(?:FR|MC)\d{12}[A-Za-z0-9]{11}\d{2}|(?:GB|IE)\d{2}[A-Z]{4}\d{14}|(?:KZ|LU)\d{5}[A-Za-z0-9]{13}|(?:GI|IQ)\d{2}[A-Z]{4}[A-Za-z0-9]{15}|(?:PK|RO)\d{2}[A-Z]{4}[A-Za-z0-9]{16}|(?:PS|QA)\d{2}[A-Z]{4}[A-Za-z0-9]{21}|AD\d{10}[A-Za-z0-9]{12}|AZ\d{2}[A-Z]{4}[A-Za-z0-9]{20}|BE\d{14}|BG\d{2}[A-Z]{4}\d{6}[A-Za-z0-9]{8}|BH\d{2}[A-Z]{4}[A-Za-z0-9]{14}|BR\d{25}[A-Z][A-Za-z0-9]|BY\d{2}[A-Za-z0-9]{4}\d{4}[A-Za-z0-9]{16}|DO\d{2}[A-Za-z0-9]{4}\d{20}|EG\d{27}|GE\d{2}[A-Z]\d{16}|GT\d{2}[A-Za-z0-9]{24}|GR\d{9}[A-Za-z0-9]{16}|HR\d{19}|IS\d{24}|JO\d{2}[A-Z]{4}\d{4}[A-Za-z0-9]{18}|KW\d{2}[A-Z]{4}[A-Za-z0-9]{22}|LC\d{2}[A-Z]{4}[A-Za-z0-9]{24}|LB\d{6}[A-Za-z0-9]{20}|LV\d{2}[A-Z]{4}\d{13}|MD\d{2}[A-Za-z0-9]{20}|MK\d{5}[A-Za-z0-9]{10}\d{2}|MR\d{25}|MT\d{2}[A-Z]{4}\d{5}[A-Za-z0-9]{18}|MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}|NL\d{2}[A-Z]{4}\d{10}|NO\d{13}|SA\d{4}[A-Za-z0-9]{18}|SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}|SI\d{17}|SV\d{2}[A-Z]{4}\d{20}|TR\d{8}[A-Za-z0-9]{16}|UA\d{8}[A-Za-z0-9]{19}|VG\d{2}[A-Z]{4}\d{16}|GE\d{2}[A-Z]{2}\d{16})$/
                        return (value == null || value === "" || pattern.test(value)) || "Inserisci IBAN valido";
                    }
                }
            }
        )
    }
;

export default validationRulesMixin;
