import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":_vm.label,"rules":_vm.rules.concat([_vm.validation_rule]),"prepend-icon":_vm.mode === 'date' ? 'mdi-calendar' : 'mdi-clock',"clearable":""},on:{"input":_vm.onInput,"blur":_vm.onBlur,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.pickerChange.apply(null, arguments)},"click:clear":function($event){return _vm.clear()}},model:{value:(_vm.formatted_model),callback:function ($$v) {_vm.formatted_model=$$v},expression:"formatted_model"}},on))]}}]),model:{value:(_vm.vmenu),callback:function ($$v) {_vm.vmenu=$$v},expression:"vmenu"}},[(_vm.mode == 'date')?_c(VDatePicker,{attrs:{"locale":"it"},on:{"change":_vm.pickerChange},model:{value:(_vm.model_value),callback:function ($$v) {_vm.model_value=$$v},expression:"model_value"}}):_vm._e(),(_vm.mode == 'time')?_c(VTimePicker,{attrs:{"locale":"it","format":"24hr"},on:{"change":_vm.pickerChange},model:{value:(_vm.model_value),callback:function ($$v) {_vm.model_value=$$v},expression:"model_value"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }