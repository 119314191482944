import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_c(VTextField,{attrs:{"append-icon":"mdi-magnify"},on:{"input":_vm.updateSearchInput}}),_c(VList,{staticStyle:{"overflow":"auto"},attrs:{"max-height":_vm.max_height}},[(_vm.showLoader)?_c(VListItem,[_c('transition',{attrs:{"name":"fade"}},[_c(VProgressLinear,{attrs:{"indeterminate":"","rounded":"","height":"16"}})],1)],1):_vm._e(),(_vm.error_text.length > 0 )?_c(VListItem,{staticClass:"danger"},[_c(VListItemIcon,[_c(VIcon,[_vm._v("mdi-alert-circle-outline")])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(this.error_text)}})],1)],1):_vm._e(),_vm._l((_vm.items),function(item,i){return [_c(VListItem,{directives:[{name:"show",rawName:"v-show",value:(item.visible),expression:"item.visible"}],key:i},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"input-value":item['selected'],"indeterminate":item['selected'] === 'indeterminate',"color":"primary"},on:{"click":function($event){return _vm.checkboxClick(item,i,item['selected'])}}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item[_vm.list_title_field])}}),_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(item[_vm.list_subtitle_field])}})],1)],1)]})],2)],1),_c(VCardActions,[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(this.items.length > 0),expression:"this.items.length > 0"}],on:{"click":_vm.saveRelations}},[_c(VIcon,[_vm._v("mdi-check")])],1),_c(VBtn,{on:{"click":function($event){return _vm.$emit('close-popup')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }