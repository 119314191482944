import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":"","outlined":"","shaped":""}},[_c(VCardSubtitle,{staticClass:"overline py-0 border_bottom mb-3 primary white--text"},[_vm._v("Studio Info")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"readonly":"","label":"Fornitore","value":_vm.loggedStudio.name,"persistent-hint":"","hint":"I Dati del fornitore sono quelli presenti nella configurazione Studio, la configurazione è a carico di Super Amministratore","loading":_vm.loadingLoggedStudio > 0}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }