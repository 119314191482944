import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{model:{value:(_vm.panels_model),callback:function ($$v) {_vm.panels_model=$$v},expression:"panels_model"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{attrs:{"dense":"","small":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,[_c(VBtn,{attrs:{"icon":"","x-small":""}},[_c(VIcon,[_vm._v("mdi-filter")])],1),_vm._v("Filtri di Ricerca ")],1),_c(VCol,{staticClass:"text-right mr-2"},[_c(VBtn,{attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();_vm.reset_all++;_vm.$emit('reset-all');}}},[_c(VIcon,[_vm._v("mdi-close-box-multiple")])],1)],1)],1)],1),_c(VExpansionPanelContent,[_vm._t("filters",null,{"reset_all":_vm.reset_all}),_vm._t("bottom")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }