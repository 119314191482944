<template>
  <v-container>
    <div class="primary--text">Modifica valida per <span
        class="font-weight-bold lighten-3">({{ customers.length }})</span> Clienti Selezionati
    </div>

    <v-row align="baseline">
      <v-col cols='3'>
        <MjTextField
            hint='Importo tariffa annua'
            type="money"
            v-model='yearly_accounting_amount'
            decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
            locale='it-IT'
        >
        </MjTextField>
      </v-col>
      <v-col>
        <v-btn small color="primary" @click="changeYearlyAccountingAmount" :loading="loading > 0">Cambia Tariffa</v-btn>
      </v-col>
    </v-row>
    <v-row align="baseline">
      <v-col cols='3'>
        <MjTextField

            hint='Variazione su tariffa attuale'
            v-model='yearly_accounting_amount_change_percentage'
            type="percent"
            decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
            locale='it-IT'
        >
        </MjTextField>
      </v-col>
      <v-col cols="3">
        <v-btn small color="primary" @click="changeYearlyAccountingAmountByPercentage" :loading="loading > 0">Aggiorna
          Tariffa
        </v-btn>
      </v-col>
    </v-row>


  </v-container>


</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjTextField from '@/components/form/MjTextField.vue';
import mj_axios from "@/mj_axios";

export default {
  name: "SchedaYearlyAccountAmount",
  mixins: [ValidationRulesMixin],
  components: {MjTextField},
  props: {
    customers: {default: () => []}
  },
  computed: {
    customers_ids: function () {
      return this.customers.map((c) => c.id);
    }
  },
  mounted() {

  },
  data: () => ({
    yearly_accounting_amount: 0,
    yearly_accounting_amount_change_percentage: 0,
    loading: 0,
  }),

  methods: {
    changeYearlyAccountingAmount: function () {

      this.loading++;
      const params = {"yearly_accounting_amount":this.yearly_accounting_amount, "customers_ids":this.customers_ids};
      const axios_promise = mj_axios.post('/api/update_yearly_accounting_amount', params);
      axios_promise.then(() => {
  this.$emit("saved");
      }).catch((e) => {
        const message = e.response.data && e.response.data.message ? (e.response.data.message) : '';

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema. ${message}`,
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    },
    changeYearlyAccountingAmountByPercentage: function () {

      this.loading++;
      const params = {"yearly_accounting_amount_change_percentage":this.yearly_accounting_amount_change_percentage, "customers_ids":this.customers_ids};
      const axios_promise = mj_axios.post('/api/update_yearly_accounting_amount', params);
      axios_promise.then(() => {

      }).catch((e) => {

        const message = e.response.data && e.response.data.message ? (e.response.data.message) : '';

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema. ${message}`,
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  },
  watch: {}
};
</script>
