import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('MjTableItems',{ref:"table_edocuments",attrs:{"loadItemsOnCreated":true,"model-name-prop":"EDocument","resource-collection-name":"EDocumentResourceListCollection"},on:{"selectedChange":function (selected) { return _vm.edocuments_selected = selected; }},scopedSlots:_vm._u([{key:"mj_filters_section",fn:function(props){return [_c('MjFilterGroup',{ref:"filter_group",staticClass:"mt-0 px-2",attrs:{"expand_filter":false},on:{"reset-all":function($event){_vm.$refs.table_edocuments.filters = [];
          _vm.$refs.table_edocuments.loadItems(true);}},scopedSlots:_vm._u([{key:"filters",fn:function(props_filters){
          var this$1 = this;
return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('MjDateIntervalFilter',{attrs:{"label-from":"Da","label-to":"A","time-model-format":"HH:mm:ss","time-display-format":"HH:mm","date-model-format":"YYYY-MM-DD","date-display-format":"DD/MM/YYYY","value-from":_vm.start_of_current_month,"value-to":_vm.end_of_current_month,"mode":"date","glue":"and","silent_reset":props_filters.reset_all,"filter_name":"date"},on:{"change":props.updateFilters}})],1)],1),(_vm.$route.params.customer_id == null)?_c(VRow,{attrs:{"align":"baseline"}},[_c(VCol,{attrs:{"cols":"9"}},[_c('MjComboBoxFilter',{attrs:{"label":"Cliente","load_items_url":"/api/list/Customer/CustomerResource4SelectCollection","filter_name":"customer_id","item_description_field":function (item) { return ("" + (item.business_name)); },"item_value_field":"id","glue":"and","priority":1,"silent_reset":props.reset_all},on:{"change":function (data) {_vm.current_customer_id_filter = data.val;this$1;_vm.updateFilters(data)}}})],1)],1):_vm._e()]}}],null,true)})]}},{key:"mj_top_toolbar_prepend",fn:function(data){return [(data.selected.length > 0)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 mt-1 white--text",attrs:{"small":"","fab":"","color":"purple","loading":_vm.downloadingXmls > 0},on:{"click":function($event){$event.stopPropagation();return _vm.downloadXmls(data.selected)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-folder-zip-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Scarica Zip con Xml")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }