<template>

  <v-alert type="warning" class="rounded-0" v-if="this.isLogged && this.isExpired">
    <div>Abbonamento Scaduto il <span class="font-weight-bold">{{this.subscriptionExpireDate.format('DD-MM-YYYY HH:mm')}}</span> </div>
    <div class="">Contatta il fornitore per il rinnovo.</div>
  </v-alert>

</template>

<script>
import mj_axios from "../mj_axios";
import moment from "moment";

export default {
  name: "MjSubscriptionStatus",
  mounted() {
    if (this.isLogged){
    this.getExpiredDate();}
  },
  computed:{
    isLogged() {
      return (
          this.$store.state.token_obj != null &&
          this.$store.state.token_obj !== ""
      );
    },
    isExpired(){ return this.subscriptionExpireDate != null && moment().isAfter(this.subscriptionExpireDate)}
  },
  data: function() {
    return {
      moment: moment,
      subscriptionExpireDate: null,
    }
  },
  methods: {
    getExpiredDate() {

      this.loading++;
      const axios_promise = mj_axios.get('/api/studio_logged');
      axios_promise.then(response => {
        this.subscriptionExpireDate = moment(response?.data?.data?.subscription_expire_date,'YYYY-MM-DD').endOf('day');

      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  },
  watch:{
    '$route'() {

      if (this.isLogged)
      {this.getExpiredDate();}
    }
  }
}
</script>

<style scoped>

</style>