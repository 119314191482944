<template>
  <v-container fluid>
    <MjTableItems
        model-name-prop="Assignment"
        resource-collection-name="AssignmentResourceListCollection"
        sort_by="Data Creazione"
        :sort_desc="true"
        :headersCustom="[{text:'Compenso annuale',value:'Compenso annuale', align:'right'},
        {text:'Anni durata',value:'Anni durata', align:'right'}]"

    >
    </MjTableItems>
  </v-container>
</template>

<script>

import MjTableItems from "@/components/MjTableItems";

export default {
  name: "TableAssignments",
  components: { MjTableItems},

};
</script>
