import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"0"}},[_c(VCardSubtitle,[_vm._v("Cliente: "+_vm._s((_vm.customer) ? _vm.customer.business_name : ' - '))]),_c(VCardText,[_c(VProgressLinear,{directives:[{name:"show",rawName:"v-show",value:(_vm.loading > 0),expression:"loading > 0"}],attrs:{"indeterminate":""}}),_c(VAutocomplete,{attrs:{"label":"Anno","item-text":"text","item-value":"value","return-object":true,"items":_vm.years},on:{"change":_vm.loadCedolini},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}}),_c(VSimpleTable,{attrs:{"dense":""}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Mese")]),_c('th',[_vm._v("# Cedolini")])])]),_c('tbody',_vm._l((_vm.months),function(month){return _c('tr',{key:month.value},[_c('td',[_vm._v(_vm._s(month.text))]),_c('td',[_vm._v(" "+_vm._s((_vm.cedolini && _vm.cedolini[month.value]) ? _vm.cedolini[month.value]["quantity"] : '-'))])])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }