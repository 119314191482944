import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"elevation":"0"}},[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"label":"Anno","auto-select-first":"","items":_vm.years,"return-object":false},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c(VAutocomplete,{attrs:{"label":"Mese","auto-select-first":"","items":_vm.months,"item-value":"value","return-object":false},model:{value:(_vm.month),callback:function ($$v) {_vm.month=$$v},expression:"month"}})],1)],1),_vm._l((_vm.customerRows),function(customerRow){return _c(VRow,{key:customerRow.customerId,attrs:{"align":"baseline"}},[_c(VCol,[_vm._v(" "+_vm._s(customerRow.business_name)+" ")]),_c(VCol,{attrs:{"cols":"3"}},[_c(VTextField,{attrs:{"reverse":"","label":"numero cedolini","type":"number"},model:{value:(customerRow.cedoliniQuantity),callback:function ($$v) {_vm.$set(customerRow, "cedoliniQuantity", $$v)},expression:"customerRow.cedoliniQuantity"}})],1)],1)})],2),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":_vm.confirmButtonColor,"loading":_vm.insertingCedolini > 0},on:{"click":function($event){_vm.sure ? _vm.insertCedolini() : _vm.confirmButtonText = 'Sicuro?'; _vm.sure=true; _vm.confirmButtonColor = 'warning'}}},[_vm._v(_vm._s(_vm.confirmButtonText)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }