<template>
    <div>
        <v-progress-linear indeterminate v-if="this.loadingMissingRequiredFields > 0"></v-progress-linear>
        <v-alert type="info" dense prominent v-if="this.missingRequiredFields && this.missingRequiredFields.length > 0">
            <div class="text-overline font-weight-bold">ATTENZIONE</div>
            Al fine di un corretto funzionamento del software, è consigliato completare i dati del proprio studio.
            <div>
                <a :href="`/#/scheda_studio/${this.$store.state.token_obj.studio_id}`" class="white--text">Completa
                    Adesso</a>
            </div>

        </v-alert>
    </div>
</template>

<script>
import StudioMixin from "@/mixins/entities/StudioMixin";

export default {
    name: "MjStudioProfileStatus",
    mixins: [StudioMixin],
    created() {
        this.getMissingRequiredFields();
    }
}
</script>

<style scoped>

</style>