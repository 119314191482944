<template>
    <v-text-field
        :disabled="disabled && disabled === true"
        :label="this.label"
        :dense="this.dense"
        v-model="formatted_model"
        :prefix="(suffix_mode) ? '' : symbol"
        :suffix="(suffix_mode) ? symbol : ''"
        @input="onInput"
        @blur="onBlur"
        :hint="hint"
        :persistent-hint="persistent_hint !== null"
        :rules="this.rules"
        clearable
        @change="$emit('change')"
        @click:clear="formatted_model = model_value = null"
        v-bind="$attrs"

    />

</template>


<script>
export default {
  name: "MjTextField",
  props: [
    "display_format",
    "locale",
    "value",
    "label",
    "type",
    "suffix_mode",
    "decimal_separator",
    "thousand_separator",
    "max_fraction_digits",
    "max_significant_digits",
    "dense",
    "rules",
    "hint",
    "persistent_hint",
    "disabled"
  ],
  computed: {
    symbol: function () {
      switch (this.type) {
        case "money":
          return "€";
        case "percent":
          return "%";
        default:
          return "";
      }
    },
  },
  data() {
    return {
      model_value: this.value,
      formatted_model: null,
      formatter: new Intl.NumberFormat(this.locale, {
        useGrouping: true,
        maximumFractionDigits: this.max_fraction_digits,
        maximumSignificantDigits: this.max_significant_digits,
      }),
      // validation_rule: val => {
      //   return val == null ? true : "formato corretto " + this.display_format;
      // }
    };
  },
  mounted() {
    // console.log(JSON.stringify(this.rules));
  },
  methods: {
    formatForDisplay: function (val) {
      if (val == null) return "";
      else {
        const formatted = this.formatter.format(val);

        if ('NaN' === formatted) {
          return "";
        } else {
          return formatted;
        }
      }
    },

    getRawModel: function (val) {
      if (val == "" || val == null) return null;
      else {
        val = val.replace(this.thousand_separator, "");
        val = val.replace(this.decimal_separator, ".");
        return parseFloat(val).toFixed(this.max_fraction_digits);
      }
    },

    onInput() {
      this.$emit("input", this.model_value);
      // return false;
      // this.$refs.form.validate();
      // console.log('validate ' + JSON.stringify(this.rules));
    },
    onBlur(evt) {
      this.model_value = this.getRawModel(evt.target.value);
      this.formatted_model = this.formatForDisplay(this.model_value);
      this.$emit("input", this.model_value);
    },
  },
  watch: {
    value: { immediate:true, handler (val) {

      this.model_value = val;
      if (val == "" || val == null) {
        this.formatted_model = "";
      } else {
        this.formatted_model = this.formatForDisplay(this.model_value);
      }
    },
    formatted_model: function () {
      // console.log("watch formatted " + val);
      // this.model_value = this.getRawModel(val);
      // this.formatted_model = this.formatForDisplay(this.model_value);
    }},
  },
};
</script>