<template>
  <v-container fluid>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <MjSoftTitle title="e-Doc" class="my-10 ml-2"></MjSoftTitle>
    <v-form ref="form">

      <MjStudioCard @load="studioLoaded"></MjStudioCard>
      <v-card class="mt-4" flat outlined shaped>
        <v-card-subtitle class="overline py-0 border_bottom mb-3 primary white--text">Cliente</v-card-subtitle>
        <v-card-text>
          <v-autocomplete v-model="selectedCustomerId"
                          :loading="loadingCustomers > 0"
                          :disabled="loadingCustomers > 0"
                          :rules="[rules.required]"
                          :items="customers" item-text="business_name" item-value="id"
          >
          </v-autocomplete>

          <v-row>
            <v-col cols="4">
              <MjToggleButtons s :mandatory="true" v-model="selectedDocumentType" small :description_as_tooltip="true"
                               load_items_url="/api/edocument_types"
                               item_value_field="value" item_description_field="text" active_class="primary">

              </MjToggleButtons>

            </v-col>
            <v-col>
              <MjDateOrTimePicker
                  label="Data Documento"
                  v-model="documentDate"
                  mode="date"
                  model_format="YYYY-MM-DD"
                  display_format="DD-MM-YYYY"
                  :rules="[rules.required]"
              >
              </MjDateOrTimePicker>
            </v-col>
            <v-col cols="3">
              <v-text-field label="N° Documento"
                            v-model="documentNumber"
                            :rules="[rules.required]"
                            :disabled="loadingSuggestedDocumentNumber> 0"
                            :loading="loadingSuggestedDocumentNumber> 0"
              >

              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mt-4 " flat outlined shaped>
        <v-card-subtitle class="overline py-0 border_bottom  primary white--text">Servizi
          ({{ this.insertedStudioServices ? this.insertedStudioServices.length : 0 }})
        </v-card-subtitle>
        <v-card-title></v-card-title>
        <v-card-text>
          <MjEDocumentServices :rows="this.insertedStudioServices" @change="changeServices"></MjEDocumentServices>
        </v-card-text>
      </v-card>
    </v-form>

  <MjEDocumentValidator class="my-4" ref="eDocValidator" :eDocumentId="$route.params.id"></MjEDocumentValidator>

    <MjSchedaToolbar
        class="mx-0 mt-3"
        :error_show="errorShow"
        :error_text="errorText"
        :edit_mode="editMode"
        :restore_item_show="false"
        v-on:save-item-click="saveEDocument()"
        v-on:go-back-click="goBackOrClose">
      <template v-slot:appendButton>
        <v-btn depressed
               outlined
               class="mt-2"
        :loading="downloadingXml>0"
               @click="downloadXml"
        >
          Scarica Xml
          <v-icon right>mdi-download</v-icon>
        </v-btn>
      </template>
    </MjSchedaToolbar>
  </v-container>
</template>

<script>
import CustomerMixin from "@/mixins/entities/CustomerMixin";
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker";
import moment from "moment";
import MjEDocumentServices from "@/components/custom/MjEDocumentServices";
import MjStudioCard from "@/components/custom/MjStudioCard";
import MjSchedaToolbar from "@/components/MjSchedaToolbar";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin";
import mj_axios from "@/mj_axios";
import MjSoftTitle from "@/components/MjSoftTitle";
import MjToggleButtons from "@/components/form/MjToggleButtons";
import MjEDocumentValidator from "@/components/custom/MjEDocumentValidator";

export default {
  name: "EDocumentFormView",
  mixins: [ValidationRulesMixin, CustomerMixin],
  components: {MjDateOrTimePicker, MjEDocumentServices, MjStudioCard, MjSchedaToolbar, MjSoftTitle, MjToggleButtons, MjEDocumentValidator},

  created() {
    this.loadCustomers();

    if (this.editMode) {
      this.loadEDocument();

    } else {
      this.loadSuggestedDocumentNumber();
    }
  },
  mounted(){
    if (this.editMode) {
      this.$refs.eDocValidator.validate();}
  },

  computed: {
    studio_name() {
      return this.$store.state.token_obj.studio_name
          ? this.$store.state.token_obj.studio_name
          : "";
    },
    editMode: function () {
      const editMode = (this.$route.params != null && this.$route.params.id != null) /*|| (this.form.id  && this.form.id["val"] != null)*/;
      return Boolean(editMode);
    },
    selectedCustomer: function(){
      return this.customers.find((customer) => {
        if (customer.id == this.selectedCustomerId) return customer;
      });
    }
  },
  data:
      function () {
        return {
          documentTypes: [
            {description: 'Fattura', value: 'TD01'},
            {description: 'Nota di Credito', value: 'TD04'}],
          selectedCustomerId: null,
          selectedDocumentType: null,
          insertedStudioServices: null,
          documentDate: moment().format('YYYY-MM-DD'),
          documentNumber: null,
          errorShow: false,
          errorText: false,
          loadingSuggestedDocumentNumber: 0,
          studioId: null,
          savingEDocument: 0,
          loading_overlay: false,
          downloadingXml: 0,
        }
      },
  methods: {
    changeServices(rows) {
      this.insertedStudioServices = rows.map((row) => {
        delete row.id;
        return row
      });
    },
    studioLoaded(studio) {
      this.studioId = studio.id;
    },
    loadEDocument() {
      this.loading++;
      const axios_promise = mj_axios.get(`/api/edocument/${this.$route.params.id}`);
      axios_promise.then(response => {

        const edocument = response.data.data;
        this.documentNumber = edocument.number;
        this.documentDate = edocument.date;
        this.selectedDocumentType = edocument.type;
        this.selectedCustomerId = edocument.customer_id;

        this.insertedStudioServices = edocument.e_document_services.map((service) => {
          return {id: service.id, description: service.description, price: service.price, quantity: service.quantity}
        })

      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    },
    saveEDocument() {
      this.loading_overlay = true;
      this.$refs.form.validate();
      const params = {
        studioId: this.studioId,
        customerId: this.selectedCustomerId,
        documentType: this.selectedDocumentType,
        documentDate: this.documentDate,
        documentNumber: this.documentNumber,
        serviceRows: this.insertedStudioServices
      }

      if (this.validateParams(params)) {
        this.savingEDocument++;

        let axios_promise;
        if (this.editMode) {
          const id = this.$route.params.id;
          axios_promise = mj_axios.put(`/api/edocument/${id}`, params); //edit
          this.$refs.eDocValidator.validate();
        } else {
          axios_promise = mj_axios.post('/api/edocument', params); //insert
        }

        axios_promise.then((response) => {
          const id = response.data.data.eDocumentId.toString();
          if (!this.editMode) {
            this.$router.replace({path: id, append: true});
          }

        }).catch((e) => {
          let message =
              e.response && e.response.data ? e.response.data.message : "E' consigliato prima di proseguire risolvere il problema";

          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: `Errore di salvataggio: ${message}.`,
            visible: true,
          });
        }).then(() => {
          this.savingEDocument--;
          this.loading_overlay = false;
        });
        return axios_promise;
      }
      else{
        this.loading_overlay = false;
      }

    },
    validateParams(params) {
      let message = null;
      let isValid = true;
      if (params?.studioId == null) {
        message = 'Attendi il caricamento dei dati dello studio prima di salvare la fattura, ritenta tra qualche secondo.'
        isValid = false;
      }

      isValid = isValid && params?.customerId != null
          && params?.documentType != null
          && params?.documentDate != null
          && params?.documentNumber != null
          && params?.serviceRows != null
          && params.serviceRows.every(serviceRow => {

            return serviceRow.quantity != null && parseInt(serviceRow.quantity) > 0 &&
                serviceRow.price != null && parseFloat(serviceRow.price) > 0 &&
                serviceRow.description != null && serviceRow.description.length > 0
          })

      if (message) {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: message,
          visible: true,
        });
      }
      return isValid;
    },
    loadSuggestedDocumentNumber() {
      this.loadingSuggestedDocumentNumber++;

      const axios_promise = mj_axios.get('/api/edocuments_suggested_number');
      axios_promise.then(response => {
        this.documentNumber = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingSuggestedDocumentNumber--;
      });
      return axios_promise;

    },
    downloadXml(){
      const url = `/api/edocuments/${this.$route.params.id}/actions/download_xml`;
      this.downloadingXml++;

      //caso selezione singola per nome pdf
      const filename =`${this.selectedDocumentType}_${this.selectedCustomer.business_name}_${this.$route.params.id}.xml`;
      mj_axios.download(url, filename)
          .then(() => {this.downloadingXml--;})
          .catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Errore!!",
          text: "Si è verificato un errore durante il download dell'edocument xml.",
          visible: true,
        });
            this.downloadingXml--;
      });
    },

    goBackOrClose() {
      window.history.length == 1 ? window.close() : this.$router.go(-1);
    }
  },


}
</script>

<style scoped>

</style>