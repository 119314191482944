import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"py-0 px-0",attrs:{"fluid":""}},[_c(VForm,{ref:"form"},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"7"}},[_c(VCombobox,{attrs:{"label":"Descrizione Servizio","loading":_vm.loadingStudioServices > 0,"rules":[_vm.rules.required],"items":_vm.studioServices,"item-value":function (service) { return ((service.cod) + " " + (service.description)); },"item-text":function (service) { return ((service.cod) + " " + (service.description)); },"return-object":true},on:{"change":_vm.changeRowData},model:{value:(_vm.descriptionModel),callback:function ($$v) {_vm.descriptionModel=$$v},expression:"descriptionModel"}})],1),_c(VCol,{attrs:{"md":"2"}},[_c(VTextField,{attrs:{"label":"Quantità","type":"number","rules":[_vm.rules.required],"reverse":""},on:{"input":_vm.changeRowData},model:{value:(_vm.quantityModel),callback:function ($$v) {_vm.quantityModel=$$v},expression:"quantityModel"}})],1),_c(VCol,{attrs:{"md":"2"}},[_c('MjTextField',{attrs:{"rules":[_vm.rules.required],"label":"Prezzo","type":"money","decimal_separator":",","thousand_separator":".","max_fraction_digits":"2","max_significant_digits":"8","locale":"it-IT","reverse":""},on:{"input":_vm.changeRowData},model:{value:(_vm.priceModel),callback:function ($$v) {_vm.priceModel=$$v},expression:"priceModel"}})],1),_c(VCol,{staticClass:"text-right",attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":_vm.removeRow}},[_c(VIcon,[_vm._v("mdi-minus-circle-outline")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }