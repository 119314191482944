<template>
  <v-tooltip bottom >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2 mt-1 white--text" small fab v-bind='attrs' v-on='on' color="amber"
             :loading="lettera_bonifico_pdf_downloading > 0"
             @click.stop='downloadLetteraBonificoPDF'>
        <v-icon>
          mdi-bank-transfer
        </v-icon>
      </v-btn>
    </template>
    <span>Scarica Lettera di Bonifico</span>
  </v-tooltip>
</template>
<script>



import mj_axios from "@/mj_axios";

export default {
  name: "downloadLetteraBonificoPDFBtn",
  components: {},
  props:{
    customers_selected:{type:Array, default:()=>[]},
  },
  data:()=>({
    lettera_bonifico_pdf_downloading: 0,
    letteraBonificoAmount: 0,
  }),
  methods:{
    downloadLetteraBonificoPDF: function () {
      if (this.customers_selected.length != 1) {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "Attenzione scegli solo un cliente",
          visible: true,
        });
      } else {
        const customer_ids = this.customers_selected.map((customer) => customer.id);

        const url = `/api/lettera_bonifico_pdf/actions/download_pdf?customers_ids=${JSON.stringify(customer_ids)}&amount=${this.letteraBonificoAmount}`;
        this.lettera_bonifico_pdf_downloading++;

        const filename = `${this.customers_selected[0]['Ragione Sociale']} Lettera Bonifico.pdf`;
        mj_axios.download(url, filename).then(() => this.lettera_bonifico_pdf_downloading--).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Errore!!",
            text: "Attenzione, Errore in fase di download..",
            visible: true,
          });
          this.lettera_bonifico_pdf_downloading--;
        });
      }
    },
  }
}
</script>
