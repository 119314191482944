<template>
  <span>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2 mt-1 white--text " small fab v-bind='attrs' v-on='on' color="brown"
             @click.stop='show_change_yearly_accounting_amount = true;'>
        <v-icon>
          mdi-cash-multiple
        </v-icon>
      </v-btn>
    </template>
    <span>Cambia tariffa annua</span>
  </v-tooltip>
  <v-dialog v-model="show_change_yearly_accounting_amount">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>Cambia tariffa contabilità annua</v-col>
          <v-col class="text-right">
            <v-btn x-small fab @click="show_change_yearly_accounting_amount= false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <SchedaYearlyAccountAmount
            :customers="customers_selected"
            @saved="show_change_yearly_accounting_amount  = false;$emit('saved')">
        </SchedaYearlyAccountAmount>
      </v-card-text>
    </v-card>
  </v-dialog>
  </span>
</template>
<script>

import SchedaYearlyAccountAmount from "@/views/Custom/SchedaYearlyAccountAmount.vue";

export default {
  name: "yearlyAccountingAmountBtn",
  components: {SchedaYearlyAccountAmount},
  props: {
    customers_selected: {type: Array, default: () => []},
  },
  data: () => ({
    show_change_yearly_accounting_amount: false,
  })
}
</script>


<style scoped>

</style>