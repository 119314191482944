// import TestView from './views/TestView.vue';
import SchedaLegalRepresentative from './views/Custom/SchedaLegalRepresentative.vue';
import SchedaCustomer from "./views/Custom/SchedaCustomer";
import SchedaStudioService from "./views/Custom/SchedaStudioService";
import SchedaLegalStatus from "./views/Custom/SchedaLegalStatus";
import SchedaPaymentMethod from "./views/Custom/SchedaPaymentMethod";
import SchedaTransaction from "./views/Custom/SchedaTransaction";
import SchedaBankAccount from "./views/Custom/SchedaBankAccount";
import SchedaBollettino from "./views/Custom/SchedaBollettino";
import SchedaTask from "./views/Custom/SchedaTask";
import TableCustomers from "./views/Custom/TableCustomers";
import TableTransactions from "./views/Custom/TableTransactions";
import MenuView from "./views/MenuView";
import MjTableItems from "./components/MjTableItems";
import SchedaStudioJob from "./views/Custom/SchedaStudioJob";
import SchedaStudio from "./views/Custom/SchedaStudio";
import DashboardJobs from "./views/Custom/DashboardJobs";
import TableCustomersForCedolini from "./views/Custom/TableCustomersForCedolini";
import TableEDocuments from "./views/Custom/TableEDocuments";

import CedoliniSavedView from "./views/Custom/CedoliniSavedView";
import EDocumentFormView from "./views/Custom/EDocumentFormView";
import LandingView from "./views/LandingView";

import PaymentInitView from "./views/Payment/PaymentInitView.vue";
import PaymentReturnView from "./views/Payment/PaymentReturnView.vue";
import TablePaymentLog from "./views/Payment/TablePaymentLog.vue";
import SchedaPaymentLog from "./views/Custom/SchedaPaymentLog.vue";
import SchedaWordTemplate from "./views/Admin/SchedaWordTemplate.vue";
import SchedaAssignment from "./views/Custom/SchedaAssignment.vue";
import TableAssignments from "./views/Custom/TableAssignments.vue";


export  const custom_routes = [

    { path: '/menu', component: MenuView, meta: { title: 'Menu', role: ['SuperAdmin','Admin','Collaboratore'] } },

    { path: '/scheda_legal_representative', name: 'NewSchedaLegalRepresentative', component: SchedaLegalRepresentative, meta: { title: 'Scheda Rappresentante Legale', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_legal_representative/:id', name: 'SchedaLegalRepresentative', component: SchedaLegalRepresentative, meta: { title: 'Scheda Rappresentante Legale', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_customer', name: 'NewSchedaCustomer', component: SchedaCustomer, meta: { title: 'Scheda Cliente', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_customer/:id', name: 'SchedaCustomer', component: SchedaCustomer, meta: { title: 'Scheda Cliente', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_studio_service', name: 'NewSchedaStudioService', component: SchedaStudioService, meta: { title: 'Scheda Servizi Studio', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_studio_service/:id', name: 'SchedaStudioService', component: SchedaStudioService, meta: { title: 'Scheda Servizi Studio', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_legal_status', name: 'NewSchedaLegalStatus', component: SchedaLegalStatus, meta: { title: 'Scheda Forma Giuridica', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_legal_status/:id', name: 'SchedaLegalStatus', component: SchedaLegalStatus, meta: { title: 'Scheda Forma Giuridica', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_payment_method', name: 'NewSchedaPaymentMethod', component: SchedaPaymentMethod, meta: { title: 'Scheda Metodi Pagamento', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_payment_method/:id', name: 'SchedaPaymentMethod', component: SchedaPaymentMethod, meta: { title: 'Scheda Metodi Pagamento', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_transaction', name: 'NewSchedaTransaction', component: SchedaTransaction, meta: { title: 'Scheda Transazione', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_transaction/:id', name: 'SchedaTransaction', component: SchedaTransaction, meta: { title: 'Transazione', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_bollettino', name: 'NewSchedaBollettino', component: SchedaBollettino, meta: { title: 'Scheda Bollettino', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_bank_account', name: 'NewSchedaBankAccount', component: SchedaBankAccount, meta: { title: 'Scheda Bank Account', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_bank_account/:id', name: 'SchedaBankAccount', component: SchedaBankAccount, meta: { title: 'Scheda Bank Account', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_task', name: 'NewSchedaTask', component: SchedaTask, meta: { title: 'Scheda Task', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_task/:id', name: 'SchedaTask', component: SchedaTask, meta: { title: 'Scheda Task', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_job', name: 'NewSchedaStudioJob', component: SchedaStudioJob, meta: { title: 'Scheda Job', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_job/:id', name: 'SchedaStudioJob', component: SchedaStudioJob, meta: { title: 'Scheda Job', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },

    { path: '/scheda_word_template', name: 'NewSchedaWordTemplate', component: SchedaWordTemplate, meta: { title: 'Scheda Modello Word', role: ['SuperAdmin'], header: 'close' } },
    { path: '/scheda_word_template/:id', name: 'SchedaWordTemplate', component: SchedaWordTemplate, meta: { title: 'Scheda Modello Word', role: ['SuperAdmin'], header: 'close' } },

    { path: '/scheda_studio', name: 'NewSchedaStudio', component: SchedaStudio, meta: { title: 'Scheda Studio', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/scheda_studio/:id', name: 'SchedaStudio', component: SchedaStudio, meta: { title: 'Scheda Studio', role: ['SuperAdmin','Admin'], header: 'close' } },
    { path: '/utente/il_mio_studio', name: 'IlMioStudio', component: SchedaStudio, meta: { title: 'Scheda Studio', role: ['SuperAdmin','Admin'], header: 'close' } },

    { path: '/table/Customer', component: TableCustomers, meta: { title: 'Lista Clienti', role: ['SuperAdmin', 'Admin','Collaboratore'] } },
    { path: '/table/CustomersForCedolini', component: TableCustomersForCedolini, meta: { title: 'Lista Clienti', role: ['SuperAdmin', 'Admin','Collaboratore'] } },
    { path: '/cedolini_saved/:customer_id', name: 'CedoliniSavedView', component: CedoliniSavedView, meta: { title: 'Cedolini Inseriti', role: ['SuperAdmin', 'Admin','Collaboratore'], header: 'close' } },

    { path: '/table/Transaction/', component: TableTransactions, meta: { title: 'Lista Transazioni', role: ['SuperAdmin', 'Admin','Collaboratore'] } },
    { path: '/table/Transaction/:customer_id', component: TableTransactions, meta: { title: 'Lista Transazioni', role: ['SuperAdmin', 'Admin','Collaboratore'] } },


    { path: '/table/EDocument/', component: TableEDocuments, meta: { title: 'Lista EDocuments', role: ['SuperAdmin', 'Admin','Collaboratore'] } },
    { path: '/table/EDocument/:id', component: TableEDocuments, meta: { title: 'Lista EDocuments', role: ['SuperAdmin', 'Admin','Collaboratore'] } },
    { path: '/table/PaymentLog', component: TablePaymentLog, meta: { title: 'Storico Pagamenti', role: ['SuperAdmin', 'Admin'] } },
    { path: '/table/Assignment', component: TableAssignments, meta: { title: 'Lista Incarichi', role: ['SuperAdmin', 'Admin','Collaborator'] } },

    { path: '/table/:id', component: MjTableItems, meta: { title: 'Tabella', role: ['SuperAdmin', 'Admin','Collaboratore'] } },

    { path: '/dashboard_jobs', component: DashboardJobs, meta: { title: 'DashboardJobs', role: ['SuperAdmin', 'Admin','Collaboratore'] } },

    { path: '/scheda_EDocument', name: 'NewSchedaEDocument',component: EDocumentFormView, meta: { title: 'E-Doc', role: ['SuperAdmin', 'Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_EDocument/:id', name: 'SchedaEDocument', component: EDocumentFormView, meta: { title: 'E-Doc', role: ['SuperAdmin', 'Admin','Collaboratore'], header: 'close' } },

    { path: '/landing_page', name: 'LandingView', component: LandingView, meta: { title: 'Sinergy Manager', role: ['Public'], sidebar:'hide'} },

    { path: '/pay_init', name: 'PaymentInitView', component: PaymentInitView, meta: { title: 'Sinergy Manager', role: ['SuperAdmin','Admin','Collaboratore'], } },
    { path: '/pay_return', name: 'PaymentReturnView', component: PaymentReturnView, meta: { title: 'Sinergy Manager', role: ['SuperAdmin','Admin','Collaboratore'], } },

    { path: '/scheda_payment_log', name: 'NewSchedaPaymentLog',component: SchedaPaymentLog, meta: { title: 'Nuovo Pagamento Offline', role: ['SuperAdmin'], header: 'close' } },
    { path: '/scheda_payment_log/:id', name: 'SchedaPaymentLog', component: SchedaPaymentLog, meta: { title: 'Pagamento Offline', role: ['SuperAdmin'], header: 'close' } },

    { path: '/scheda_assignment', name: 'NewSchedaAssignment', component: SchedaAssignment, meta: { title: 'Scheda Assignment', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },
    { path: '/scheda_assignment/:id', name: 'SchedaAssignment', component: SchedaAssignment, meta: { title: 'Scheda Assignment', role: ['SuperAdmin','Admin','Collaboratore'], header: 'close' } },



];
