<template>
  <v-container>

    <v-row fixed>
      <!--vrow necessaria per la width 100%-->
      <v-snackbar
          app
          v-model="snack_bar_show"
          type="warning"
          width="100%"
          color="info"
          vertical="vertical"
      >{{ error_text }}
      </v-snackbar>
    </v-row>
    <v-alert
        class='row wrap'
        type="info"
        border="left"
        elevation="2"
        dense
        colored-border
        v-show="edit_not_saved"
    >Modifiche non salvate
    </v-alert>
    <v-layout row wrap>
      <v-flex shrink>
        <v-btn v-if="back_button_visible" depressed outlined class="mr-2 mt-2" @click="$emit('go-back-click')"
               v-shortkey="['meta', 'b']" @shortkey="$emit('go-back-click')"
        >
          <v-icon left>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
            depressed
            type="button"
            color="primary"
            class="mr-2 mt-2"
            outlined
            @click="$emit('save-item-click')"
            v-shortkey="['meta', 's']" @shortkey="$emit('save-item-click')"
        >
          {{ this.edit_mode ? 'Aggiorna' : 'Salva' }}
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
        <v-btn
            depressed
            outlined
            v-show="restore_item_show"
            class="mr-4 mt-2"
            @click="$emit('restore-item-click')"
        >
          Ripristina
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
            depressed
            outlined
            v-if="new_button_visibile"
            class="mr-4 mt-2"
            @click="$emit('new-item-click')"
            v-shortkey="['meta', '+']" @shortkey="$emit('new-item-click')"

        >
          Nuovo
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <slot name="appendButton"></slot>
      </v-flex>
    </v-layout>
    <v-speed-dial
        v-if="speedDialVisible"
        style="z-index: 777;margin-bottom: 41px; margin-right: 7px"
        absolute
        fixed
        v-model="speedDial"
        :bottom="true"
        :right="true"
        direction="top"
        open-on-hover
    >
      <template v-slot:activator>
        <v-btn
            v-model="speedDial"
            color="secondary"
            fab
            large
        >
          <v-icon v-if="speedDial">
            mdi-close
          </v-icon>
          <span v-else class="speed_dial_closed_text">
            {{ speedDialTitle }}
          </span>
        </v-btn>
      </template>
      <v-btn
          fab
          dark
          small
          color="primary"
          @click="$emit('save-item-click')"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
      <v-btn
          fab
          dark
          small
          v-if="new_button_visibile"
          @click="$emit('new-item-click')"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>
<style>
.speed_dial_closed_text {
  font-size: smaller;
  max-width: 40px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script>


export default {
  name: "SchedaToolbar",
  props: {
    edit_mode: {},
    new_button_visibile :{},
    back_button_visible: {default: () => true},
    restore_item_show:{},
    edit_not_saved:{},
    error_show:{},
    error_text:{},
    speedDialVisible:{},
    speedDialTitle:{}
  },
  data: function () {
    return {
      snack_bar_show: this.error_show,
      speedDial: false
    }
  },
  watch: {
    error_show(val) {
      this.snack_bar_show = val;
    }
  }
};
</script>