<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Studio Service {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.max(16)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='7'>
            <v-text-field
                label='Descrizione'
                v-model='form.description.val'
                :rules="[rules.required,rules.max(255)]">
            </v-text-field>
          </v-col>

          <v-col cols='12' md='3'>
            <MjTextField
                label='Costo'
                v-model='form.cost.val'
                type="money"
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                locale='it-IT'
                :rules="[rules.required]">
            </MjTextField>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              prepend-icon="mdi-piggy-bank-outline"
              label='Conto'
              v-model='form.bank_account_id.val'
              item-text="name" item-value="id" :items="this.selects.bank_account_id" cache-items
          >
          </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Note'
                v-model='form.note.val'
                rows='2'
                >
            </v-textarea>
          </v-col>
        </v-row>
        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from '@/components/form/MjTextField.vue';


export default {
  name: "SchedaStudioService",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar, MjTextField},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      description: {type: 'string', val: '', default_val: ''},
      cost: {type: 'decimal', val: '', default_val: '0.00'},
      note: {type: 'text', val: '', default_val: ''},
      bank_account_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/bank_accounts'
      },
    },
    apiUrl: "/api/studio_services"
  }),

  methods: {}
};
</script>
