<template>
    <v-container>
        <v-overlay :value="loading_overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-container>
                <div>Studio {{ $route.params.id }}</div>
                <v-row>
                    <v-col cols='12' md='3'>
                        <v-text-field
                                label='Nome'
                                v-model='form.name.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols='12' md='4'>
                        <v-text-field
                                label='Email'
                                v-model='form.email.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-switch v-model="form.status.val" :true-value="1" :false-value="0"
                                  :readonly="role !== 'SuperAdmin'"
                                  :disabled="role !== 'SuperAdmin'"
                                  label="Studio Attivo"></v-switch>
                    </v-col>
                    <v-col cols="12" md="3">

                        <MjDateOrTimePicker v-if="role === 'SuperAdmin'"
                                label='Scadenza Abbonamento'
                                v-model='form.subscription_expire_date.val'
                                model_format='YYYY-MM-DD'
                                mode='date'
                                display_format='DD-MM-YYYY'
                                :rules="[]">
                        </MjDateOrTimePicker>
                        <v-text-field v-else  label='Scadenza Abbonamento' disabled :value="moment(form.subscription_expire_date.val,'YYYY-MM-DD').format('DD-MM-YYYY')">

                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                label='Sotto titolo'
                                v-model='form.subtitle.val'
                                :rules="[rules.required,rules.max(255)]"
                                hint="solitamente compare nei pdf sotto il nome dello studio">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                                label='Indirizzo'
                                v-model='form.address.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                label='Tel'
                                v-model='form.tel_number.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                label='Fax'
                                v-model='form.fax_number.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                label='Cell.'
                                v-model='form.mobile_number.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                label='Footer Line'
                                v-model='form.footer_line1.val'
                                :rules="[rules.required,rules.max(255)]"
                                hint="Indicazione aggiuntiva dello studio nei piè di pagina di email e report pdf (ad. iscrizione albo)"
                        >
                        </v-text-field>
                    </v-col>


                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-text-field
                                label='Partita Iva'
                                v-model='form.vat_number.val'
                                :rules="[rules.max(255)]"

                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field
                                label='Sito Web'
                                v-model='form.website.val'
                                :rules="[rules.max(255)]"

                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols='12' md='4'>
                        <v-text-field
                                label='Iban'
                                v-model='form.iban.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field
                                label='Cc Mail contratti'
                                v-model='form.cc_contract.val'
                                :rules="[rules.required,rules.email,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols='12' md='4'>
                        <v-text-field
                                label='Cc Mail estratto conto'
                                v-model='form.cc_account_balance.val'
                                :rules="[rules.required,rules.email,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                    <v-col cols='12' md='4'>
                        <v-text-field
                                label='Cc mail bollettino'
                                v-model='form.cc_bollettino.val'
                                :rules="[rules.email,rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>

                </v-row>
                <v-row>
                    <mj-image :text="form.name.val[0]"
                              :image-src="form.logo.val"
                              @img-changed="updateLogo"
                              shape="circle"
                              circle_size="100"
                    >
                    </mj-image>
                </v-row>
                <v-row>
                    <v-col cols='12'>
                        <v-textarea
                                label='Note'
                                v-model='form.note.val'
                                rows='2'
                        >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        <v-text-field
                                label='Titolo Responsabile studio'
                                v-model='form.manager_title.val'
                                :rules="[rules.required,rules.max(20)]">
                        </v-text-field>
                    </v-col>

                    <v-col md="3">
                        <v-text-field
                                label='Nome Responsabile Studio'
                                v-model='form.manager_name.val'
                                :rules="[rules.required,rules.max(100)]">
                        </v-text-field>
                    </v-col>
                    <v-col md="3">
                        <v-text-field
                                label='Cognome Responsabile studio'
                                v-model='form.manager_surname.val'
                                :rules="[rules.required,rules.max(100)]">
                        </v-text-field>
                    </v-col>
                    <v-col md="3">
                        <v-text-field
                                label='CF Responsabile'
                                v-model='form.manager_cf.val'
                                :rules="[rules.required,rules.cf,]">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>

                    <v-col md="3">
                        <MjDateOrTimePicker
                                label='Data nascita Responsabile'
                                v-model='form.manager_birth_date.val'
                                model_format='YYYY-MM-DD'
                                mode='date'
                                display_format='DD-MM-YYYY'
                                :rules="[rules.required]">
                        </MjDateOrTimePicker>
                    </v-col>
                    <v-col md="3">
                        <v-text-field
                                label='Citta nascita Responsabile'
                                v-model='form.manager_birth_city.val'
                                :rules="[rules.required,rules.max(22)]">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="4">
                        <v-text-field
                                label='Numero Ordine/Albo'
                                v-model='form.manager_ordine_commercialisti_number.val'
                                :rules="[rules.required,rules.max(45)]">
                        </v-text-field>
                    </v-col>
                    <v-col md="4">
                        <v-text-field
                                label='Citta Ordine/Albo'
                                v-model='form.manager_ordine_commercialisti_city.val'
                                :rules="[rules.required,rules.max(45)]">
                        </v-text-field>
                    </v-col>
                    <v-col md="4">
                        <v-text-field
                                label='Nome Ordine/Albo'
                                v-model='form.manager_ordine_commercialisti_name.val'
                                :rules="[rules.required,rules.max(255)]">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col md="4">
                        <v-text-field
                                label='Luogo Firma Contratti'
                                v-model='form.sign_city.val'
                                :rules="[rules.required,rules.max(45)]">
                        </v-text-field>
                    </v-col>
                    <v-col md="4">
                        <v-text-field
                                label='Sede Inps'
                                v-model='form.inps_city.val'
                                :rules="[rules.required, rules.max(45)]">
                        </v-text-field>
                    </v-col>
                    <v-col md="4">
                        <v-text-field
                                label='Direzione Lavoro Provinciale'
                                v-model='form.direzione_provinciale_city.val'
                                :rules="[rules.required,rules.max(45)]">
                        </v-text-field>
                    </v-col>
                </v-row>


                <MjSchedaToolbar
                        :error_show="error_show"
                        :error_text="error_text"
                        :edit_not_saved="edit_not_saved"
                        :edit_mode="editMode"
                        :new_button_visibile="true"
                        :restore_item_show="restore_item_show"
                        v-on:restore-item-click="restoreItem()"
                        v-on:new-item-click="newItem()"
                        v-on:save-item-click="saveItem()"
                        v-on:go-back-click="goBack()"
                />
            </v-container>
        </v-form>
    </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjImage from "@/components/form/MjImage";
import MjDateOrTimePicker from "@/components/form/MjDateOrTimePicker";
import moment from "moment";

export default {
    name: "SchedaStudio",
    mixins: [schedaMixin, ValidationRulesMixin],
    components: {MjSchedaToolbar, MjImage, MjDateOrTimePicker},
    beforeCreate() {
        if (this.$route.path === '/utente/il_mio_studio') {
            this.$router.replace(`/scheda_studio/${this.$store.state.token_obj.studio_id}`)
        }
    },
    computed:{
        role() {return this.$store.state?.token_obj?.role;},
        moment() {return moment;}
    },
    data: () => ({
        form: {
            name: {type: 'string', val: '', default_val: ''},
            subtitle: {type: 'string', val: '', default_val: ''},
            iban: {type: 'string', val: '', default_val: ''},
            cc_account_balance: {type: 'string', val: '', default_val: ''},
            note: {type: 'text', val: '', default_val: ''},
            logo: {type: 'string', val: ''},
            cc_bollettino: {type: 'string', val: '', default_val: ''},
            email: {type: 'string', val: '', default_val: ''},
            status: {type: 'integer', val: '', default_val: '0'},

            address: {type: 'string', val: '', default_val: ''},
            cc_contract: {type: 'string', val: '', default_val: ''},
            footer_line1: {type: 'string', val: '', default_val: ''},
            tel_number: {type: 'string', val: '', default_val: ''},
            fax_number: {type: 'string', val: '', default_val: ''},
            mobile_number: {type: 'string', val: '', default_val: ''},
            vat_number: {type: 'string', val: '', default_val: ''},
            website: {type: 'string', val: '', default_val: ''},

            manager_name: {type: 'string', val: '', default_val: ''},
            manager_title: {type: 'string', val: '', default_val: ''},
            manager_surname: {type: 'string', val: '', default_val: ''},
            manager_cf: {type: 'string', val: '', default_val: ''},
            manager_ordine_commercialisti_number: {type: 'string', val: '', default_val: ''},
            manager_ordine_commercialisti_city: {type: 'string', val: '', default_val: ''},
            manager_ordine_commercialisti_name: {type: 'string', val: '', default_val: ''},
            manager_birth_date: {type: 'date', val: '', default_val: ''},
            manager_birth_city: {type: 'string', val: '', default_val: ''},
            sign_city: {type: 'string', val: '', default_val: ''},
            inps_city: {type: 'string', val: '', default_val: ''},
            direzione_provinciale_city: {type: 'string', val: '', default_val: ''},

            subscription_expire_date: {type: 'date', val: '', default_val: ''},
        },
        apiUrl: "/api/studios"
    }),

    methods: {
        updateLogo(img_data) {
            this.form.logo.val = img_data;
        },
    }
};
</script>
