<template>
    <v-container>
        <v-form>

            <div class="mt-6">
                Attualmente il tuo abbonamento è valido fino a {{ subscription_expire_date }}
            </div>
            <v-slider label="Numero mensilità" hint="Indica il numero di mesi di abbonamento che vuoi acquistare"
                      type="number"
                      class="my-10"
                      min="1"
                      max="36"
                      step="1"
                      tick-size="4"
                      show-ticks="always"
                      thumb-label="always"
                      ticks
                      v-model="numberOfSubscriptionMonth"
                      persistent-hint/>

            <div class="my-6">
                Se il pagamento andrà a buon fine il tuo abbonamento sarà valido fino a
                <b>{{ new_subscription_expire_date }}</b>
            </div>

            <div class="mt-6 text-h6">
                Costo totale
                <span v-if="loadingPrice > 0"><v-progress-circular indeterminate/></span>
                <span v-else class="font-weight-bold accent--text">{{ mj_string_utils.moneyFormat(subscriptionPrice * numberOfSubscriptionMonth) }}</span>
            </div>
            <div class="caption mt-2 mb-4">Cliccando su paga adesso verrà avviata la procedura di pagamento tramite provider PayPal, potrai pagare tramite account PayPal o tramite carta</div>

            <v-btn color="primary" large :loading="initializing" @click="initPayment"> Paga Adesso
                <v-icon right light>mdi-credit-card-outline</v-icon>
            </v-btn>


        </v-form>
    </v-container>
</template>

<script>


import mj_axios from "@/mj_axios.js";
import moment from "moment";
import PaymentReturnView from "@/views/Payment/PaymentReturnView.vue";
import mj_string_utils from "@/misc/mj_string_utils";



export default {
    name: "PaymentView",
    computed: {
        mj_string_utils() {
            return mj_string_utils
        },
        PaymentReturnView() {
            return PaymentReturnView
        },
        baseUrl() {
          let port = window.location.port;
          if (port !==  '') port = ":" + port;
          return window.location.protocol + "//" + window.location.hostname  + port + "/"
        },

        subscription_expire_date() {
            return this.$store.state.token_obj.subscription_expire_date
                ? moment(this.$store.state.token_obj.subscription_expire_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : "";
        },

        new_subscription_expire_date() {
            return moment(this.$store.state.token_obj.subscription_expire_date, 'YYYY-MM-DD').add(this.numberOfSubscriptionMonth, 'month').format('DD-MM-YYYY')
        }
    },
    created() {
        this.getSubscriptionPrice();
    },
    data: () => ({
        initializing: false,
        numberOfSubscriptionMonth: 1,
        subscriptionPrice: null,
        loadingPrice:0,
    }),
    methods: {
        getSubscriptionPrice(){
            this.loadingPrice++;
            const axios_promise = mj_axios.get('/api/subscription_price');
            axios_promise.then(response => {
                this.subscriptionPrice = response.data.data;
            }).catch((response) => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: `Errore di inizializzazione del pagamento , ${response.data?.errorMessage}`,
                    visible: true,
                });
            }).then(() => {
                this.loadingPrice--;
            });
            return axios_promise;
        },
        initPayment() {
            this.initializing = true;
            const params = {
                "numberOfSubscriptionMonth": this.numberOfSubscriptionMonth,
                "returnUrl": this.baseUrl + this.$router.resolve({name: PaymentReturnView.name}).href,
                "cancelUrl": this.baseUrl + this.$router.resolve(this.$route).href
            };
            const axios_promise = mj_axios.post('/api/paypal/actions/init', params);
            axios_promise.then(response => {
                const approveUrl = response.data?.approveUrl;
                if (approveUrl) {
                    window.location = approveUrl;
                } else {
                    this.$store.commit("update_modal_dialog", {
                        title: "Attenzione!!",
                        text: `Errore di inizializzazione del pagamento , ${response.data?.errorMessage}`,
                        visible: true,
                    });
                }
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: `Errore di inizializzazione del pagamento , è consigliato, prima di proseguire, risolvere il problema.`,
                    visible: true,
                });
            }).then(() => {
                this.initializing = false;
            });
            return axios_promise;
        }
    },

}
</script>

<style scoped>

</style>