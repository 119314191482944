<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <v-row>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Titolo'
                v-model='form.title.val'
                :rules="[rules.required,rules.max(255)]">
            </v-text-field>
          </v-col>

          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Cliente'
                v-model='form.customers_id.val'
                item-text="business_name" item-value="id" :items="this.selects.customers_id" cache-items
            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Durata'
                v-model='form.year_duration.val'
                reverse
                type="number"
                step="1"
                hint="durata espressa in numero di anni"
                :rules="[rules.required]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='2'>
            <MjTextField
                label='Compenso annuale'
                v-model='form.year_amount.val'
                reverse
                type="money"
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                locale='it-IT'
                :rules="[rules.required]">
            </MjTextField>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Descrizione'
                v-model='form.description.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>

        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        >
          <template #appendButton>
            <v-btn
                depressed
                :disabled=" assigmentId == null"
                outlined
                class="mr-4 mt-2"
                color="purple darken-4"
                @click="makeAssignmentTransaction"
                :loading="makingTransactions > 0"
            >
              {{(assignmentTransactions.length > 0) ? 'Aggiorna' :'Genera'}} compensi
              <v-icon right>mdi-format-list-bulleted</v-icon>
            </v-btn>
          </template>
        </MjSchedaToolbar>
      </v-container>
    </v-form>

    <v-card max-width="400" class="mt-2">
      <v-card-subtitle class="text-center ">Compensi associati all'incarico</v-card-subtitle>
      <v-card-text>
        <v-progress-linear indeterminate color="primary" v-show="loadingAssigmentTransactions > 0"></v-progress-linear>
        <v-simple-table v-if="assignmentTransactions.length > 0">
          <thead>
          <tr>
            <th>Importo</th>
            <th>Data</th>
            <th>Id Cliente</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(transaction,index) in assignmentTransactions" :key="index">
            <td class="text-right">
              {{mj_string_utils.moneyFormat(transaction.amount)}}
            </td>
            <td class="text-center">
              {{moment(transaction.date,'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')}}
            </td>
            <td class="text-right">
              {{transaction.customer_id}}
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <div v-else-if="loadingAssigmentTransactions == 0" class="text-center warning--text font-weight-bold">
          Nessun compenso associato all'incarico
        </div>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from '@/components/form/MjTextField.vue';
import mj_axios from "@/mj_axios";
import mj_string_utils from "@/misc/mj_string_utils";
import moment from "moment";


export default {
  name: "SchedaAssignment",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar, MjTextField},
  props: {forceDisableBackOnSave: {default: () => true}},
  mounted() {
    this.loadAssignmentTransactions();
  },
  computed: {
    moment(){
      return moment;
    },
    mj_string_utils() {
      return mj_string_utils
    },
    assigmentId() {
      return this.$route?.params?.id;
    }
  },
  data: () => ({
    makingTransactions: 0,
    loadingAssigmentTransactions: 0,
    assignmentTransactions: [],
    form: {
      title: {type: 'string', val: '', default_val: ''},
      description: {type: 'text', val: '', default_val: ''},
      year_duration: {type: 'integer', val: '', default_val: 1},
      year_amount: {type: 'decimal', val: '', default_val: ''},
      customers_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/customers'},
    },
    apiUrl: "/api/assignments"
  }),

  methods: {
    loadAssignmentTransactions() {
      this.loadingAssigmentTransactions++;
      const params = {};
      const axios_promise = mj_axios.get(`/api/assignments/${this.assigmentId}/transactions`, params);
      axios_promise.then((response) => {
        this.assignmentTransactions = response.data.data;
      }).catch((e) => {
        const status = e.response?.status;
        if (status != 404) {

          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Error nella caricamento transazioni, qualcosa è andato storto contatta l'assistenza tecnica.",
            visible: true,
          });
        }
      }).then(() => {
        this.loadingAssigmentTransactions--;
      });
      return axios_promise;
    },

    makeAssignmentTransaction() {
      this.makingTransactions++;
      const params = {};
      const axios_promise = mj_axios.post(`/api/assignments/${this.assigmentId}/make_transactions`, params);
      axios_promise.then(() => {
        this.loadAssignmentTransactions();
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Error nella generazione, qualcosa è andato storto contatta l'assistenza tecnica.",
          visible: true,
        });
      }).then(() => {
        this.makingTransactions--;
      });
      return axios_promise;
    }
  }
};
</script>
