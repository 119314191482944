<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div class="primary--text" v-if="multipleCustomerMode">Aggiungo la transazione per <span class="font-weight-bold lighten-3">({{customers.length}})</span> Clienti Selezionati </div>
        <v-row class="align-baseline">

          <v-col v-if="!multipleCustomerMode"><h4>Movimento ID: {{ $route.params.id }}</h4></v-col>
          <v-col cols='12' md='6' v-if="!multipleCustomerMode">
            <v-autocomplete
                label='Cliente'
                v-model='form.customer_id.val'
                item-text="business_name" item-value="id" :items="this.selects.customer_id" cache-items
                :rules="[rules.required]">
            </v-autocomplete>
          </v-col>
          <v-col cols='12' v-if="!multipleCustomerMode" md='2'>
            <v-switch
                hint="Rata contabiltà annuale"
                :persistent-hint="true"
                readonly
                label=''
                :false-value="0"
                :true-value="1"
                v-model='form.is_yearly_installment.val'
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='3' >
            <MjToggleButtons :mandatory="true" v-model="form.type.val" small :description_as_tooltip="true" load_items_url="/api/transaction_types"
                             item_value_field="value" item_description_field="text" active_class="secondary"></MjToggleButtons>
          </v-col>
          <v-col cols='12' md='6'>
            <v-autocomplete
                label='Servizio Erogato'
                v-model='form.studio_service_id.val'
                item-text="description" item-value="id" :items="this.selects.studio_service_id" cache-items
                @change="suggestAmountByStudioService"

            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='3'>
            <MjTextField
                label='Importo'
                v-model='form.amount.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                locale='it-IT'
                :rules="[rules.required]">
            </MjTextField>
          </v-col>
        </v-row>
        <v-row class="align-baseline">

          <v-col cols='12' md='4'>
            <v-autocomplete
                label='Metodo Pagamento'
                v-model='form.payment_method_id.val'
                item-text="description" item-value="id" :items="this.selects.payment_method_id" cache-items
            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='8'>
            <MjDateTimePicker
                label='Data'
                v-model='form.date.val'
                time_model_format='HH:mm:ss' time_display_format='HH:mm' date_model_format='YYYY-MM-DD' mode='date'
                date_display_format='DD-MM-YYYY'
                :rules="[rules.required]">
            </MjDateTimePicker>
          </v-col>
        </v-row>


        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Nota Interna'
                v-model='form.internal_note.val'
                rows='2'
                hint="Non visibile al cliente"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Nota Transazione'
                v-model='form.transaction_note.val'
                rows='2'
                hint="Riportata nel bilancio"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="!multipleCustomerMode"
            :back_button_visible="!multipleCustomerMode"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from '@/components/form/MjTextField.vue';
import MjDateTimePicker from '@/components/form/MjDateTimePicker.vue';
import moment from "moment";
import MjToggleButtons from "../../components/form/MjToggleButtons";

export default {
  name: "SchedaTransaction",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjToggleButtons, MjSchedaToolbar, MjTextField, MjDateTimePicker},
  props: {
    multipleCustomerMode:{ default: () =>false},
    customers: {default: () => []}

  },
  mounted() {
    //su default val perchè passato dalle prop deve restare tale anche se resetto
    this.form.customer_ids.default_val = this.customers.map(customer => customer.id);
  },
  data: () => ({
    form: {
      type: {type: 'string', val: '', default_val: ''},
      amount: {type: 'decimal', val: '', default_val: ''},
      date: {type: 'datetime', val: '', default_val: moment().format('YYYY-MM-DD HH:mm:ss')},
      is_yearly_installment: {type: 'boolean', val: '', default_val: '0'},
      internal_note: {type: 'text', val: '', default_val: ''},
      transaction_note: {type: 'text', val: '', default_val: ''},
      payment_method_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/payment_methods'},
      customer_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/customers'},
      studio_service_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/studio_services'},
      customer_ids : {type: 'array', val:[]},

    },
    apiUrl: "/api/transactions"
  }),

  methods: {
    suggestAmountByStudioService(item_id){
      const studio_service = this.selects.studio_service_id.find((studio_service) =>  studio_service.id === item_id);
      this.form.amount.val = studio_service.cost;
    }
  },
  watch:{

    customers:{
      handler: function(){
      this.form.customer_ids.val = this.customers.map(customer => customer.id);}
      ,deep:true
    }
  }
};
</script>
