import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 mt-1 white--text blue darken-3",attrs:{"small":"","fab":"","loading":_vm.accounting_contract_html_downloading > 0},on:{"click":function($event){$event.stopPropagation();return _vm.downloadContractHTML.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-file-sign ")])],1)]}}])},[_c('span',[_vm._v("Scarica Contratto DOC")])])}
var staticRenderFns = []

export { render, staticRenderFns }