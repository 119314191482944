import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VForm,[_c('div',{staticClass:"mt-6"},[_vm._v(" Attualmente il tuo abbonamento è valido fino a "+_vm._s(_vm.subscription_expire_date)+" ")]),_c(VSlider,{staticClass:"my-10",attrs:{"label":"Numero mensilità","hint":"Indica il numero di mesi di abbonamento che vuoi acquistare","type":"number","min":"1","max":"36","step":"1","tick-size":"4","show-ticks":"always","thumb-label":"always","ticks":"","persistent-hint":""},model:{value:(_vm.numberOfSubscriptionMonth),callback:function ($$v) {_vm.numberOfSubscriptionMonth=$$v},expression:"numberOfSubscriptionMonth"}}),_c('div',{staticClass:"my-6"},[_vm._v(" Se il pagamento andrà a buon fine il tuo abbonamento sarà valido fino a "),_c('b',[_vm._v(_vm._s(_vm.new_subscription_expire_date))])]),_c('div',{staticClass:"mt-6 text-h6"},[_vm._v(" Costo totale "),(_vm.loadingPrice > 0)?_c('span',[_c(VProgressCircular,{attrs:{"indeterminate":""}})],1):_c('span',{staticClass:"font-weight-bold accent--text"},[_vm._v(_vm._s(_vm.mj_string_utils.moneyFormat(_vm.subscriptionPrice * _vm.numberOfSubscriptionMonth)))])]),_c('div',{staticClass:"caption mt-2 mb-4"},[_vm._v("Cliccando su paga adesso verrà avviata la procedura di pagamento tramite provider PayPal, potrai pagare tramite account PayPal o tramite carta")]),_c(VBtn,{attrs:{"color":"primary","large":"","loading":_vm.initializing},on:{"click":_vm.initPayment}},[_vm._v(" Paga Adesso "),_c(VIcon,{attrs:{"right":"","light":""}},[_vm._v("mdi-credit-card-outline")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }