<template>
  <v-card>
    <v-card-title>
      <v-row justify="space-between">
        <v-col cols="10"><h4 class="mb-2 text--primary">Dettaglio Adempimenti</h4></v-col>
        <v-col class="text-right">
          <v-btn fab x-small @click="$emit('close-dialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle>
      <v-row>
        <v-col cols="1">
          <v-icon>mdi-account</v-icon>
        </v-col>
        <v-col class="overline">Cliente: {{ customer ? customer.business_name : 'N.D.' }}</v-col>
      </v-row>
      <v-row class="mt-0" v-if="loading == 0">
        <v-col cols="1">
          <v-icon :class="[jobCompleted ? 'green--text' : 'red--text']">mdi-check-all</v-icon>
        </v-col>
        <v-col class="overline font-weight-bold" :class="[jobCompleted ? 'green--text' : 'red--text']">
          Job: {{ job ? job.name : 'N.D.' }}
        </v-col>
      </v-row>
    </v-card-subtitle>
    <v-card-text>

      <v-progress-linear indeterminate v-show="loading > 0 "></v-progress-linear>

      <v-list dense two-line>
        <v-subheader>Sotto adempimenti</v-subheader>
        <v-list-item-group v-if="customer_task_job_statuses.length > 0">
          <template v-for="task_job_status in this.customer_task_job_statuses">
            <v-list-item :key="task_job_status.id"  @click="changeCustomerTaskStatus(task_job_status)">
              <v-list-item-content>
                <v-list-item-title>{{ task_job_status.cod }}</v-list-item-title>
                <v-list-item-subtitle>{{ task_job_status.name }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-progress-circular size="22" v-if="changingTaskStatus[task_job_status.id] > 0" indeterminate></v-progress-circular>
                  <v-icon v-else  :class="[task_job_status.status == 1 ? 'green--text' : 'red--text']">
                    {{
                      task_job_status.status && task_job_status.status == 1 ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline'
                    }}
                  </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="task_job_status.id + new Date().getTime()"></v-divider>
          </template>
        </v-list-item-group>
        <v-list-item v-else>
          <v-list-item-title>
            <v-icon class="mr-1 ">mdi-alert-outline</v-icon>  Nessun sotto adempimento associato</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import mj_axios from "@/mj_axios";

export default {
  name: "StudioJobStatus",
  props: {
    customer: {},
    job: {},
    year: null,

  },
  computed:{
    jobCompleted:function(){
      return this.customer_task_job_statuses.every((customer_task_job_status) => customer_task_job_status.status == 1);
    }
  },
  created() {
    this.loadJobStatus();
  },
  data: () => ({
    customer_task_job_statuses: [],
    changingTaskStatus: [],
    loading: 0
  }),
  methods: {
    loadJobStatus() {
      if (this.loading == 0) //evito doppio caricamento dovuto a doppia watch
      {
        this.customer_task_job_statuses = [];
        this.loading++;
        const axios_promise = mj_axios.get(`/api/studio_jobs_status/${this.job.id}/customers/${this.customer.id}/year/${this.year}`);
        axios_promise.then(response => {
          this.customer_task_job_statuses = response.data;
        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        }).then(() => {
          this.loading--;
        });
        return axios_promise;
      }
    },
    changeCustomerTaskStatus: function(task_job_status){
      this.$set(this.changingTaskStatus,task_job_status.id,1);
      const taskId = task_job_status.task_id ? task_job_status.task_id : task_job_status.pivot.task_id;
      const params = {};
      const axios_promise = mj_axios.post(`/api/studio_jobs_status/${this.job.id}/task/${taskId}/customers/${this.customer.id}/year/${this.year}/actions/change_state`, params);
      axios_promise.then(() => {
        task_job_status.status = task_job_status.status == 1 ? 0 : 1;
        this.updateCustomerTaskJobStatus(task_job_status);
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.$set(this.changingTaskStatus,task_job_status.id,this.changingTaskStatus[task_job_status.id]-1);

      });
      return axios_promise;
    },
    updateCustomerTaskJobStatus:function(task_job_status)
    {
      const index = this.customer_task_job_statuses.indexOf(task_job_status);
      this.$set( this.customer_task_job_statuses,index,task_job_status);
    }
  },
  watch: {
    job: function () {
      this.loadJobStatus();
    },
    customer: function () {
      this.loadJobStatus();
    }
  }
}
</script>

<style scoped>

</style>