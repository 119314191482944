<template>
  <v-card width="600px" flat class="mx-auto">
    <v-card-title class="justify-center">{{ title }}</v-card-title>
    <v-card-subtitle class="small">{{
        subtitle
      }}
    </v-card-subtitle>

    <v-card-text>
      <v-form ref="contactForm" >
        <v-text-field label="Nome" :rules="[rules.max(30)]" v-model="name"></v-text-field>
        <v-text-field label="Email" :rules="[rules.required, rules.email]" v-model="email"></v-text-field>
        <v-text-field label="Telefono" :rules="[rules.max(30)]" v-model="tel"></v-text-field>
        <v-textarea label="Note" rows="2" :rules="[rules.max(100)]" v-model="note"></v-textarea>
        <v-btn color="primary" @click="submitForm" large :loading="sending >0 ">Invia <v-icon right>mdi-send</v-icon></v-btn>

      </v-form>
    </v-card-text>
    <v-snackbar true="showSnackbar">adf</v-snackbar>
  </v-card>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin";
import mj_axios from "@/mj_axios";

export default {
  name: "MjContactForm",
  mixins: [ValidationRulesMixin],
  props: ['title', 'subtitle'],
  data: function () {
    return {
      name: '',
      email: '',
      tel: '',
      note: '',
      sending: 0,
      showSnackBar: true,
    }
  },
  methods: {
    submitForm:function() {

      if (this.$refs.contactForm.validate()) {
        this.sending++;
        const params = {name: this.name, email: this.email, tel: this.tel, note: this.note};
        const axios_promise = mj_axios.post('/api/contact_form/actions/send_contact_form', params);
        axios_promise.then(() => {

        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        }).then(() => {
          this.sending--;
        });
        return axios_promise;
      }
    }
  }
}
</script>

<style scoped>

</style>