/* 
SCOPO DEL COMPONENTE: Raggruppare diversi filtri, fornendo unica interazione (ad esempio unico clear all per pulire tutti i filtri)
Le interazioni parent -> slot avvengono attraverso delle prop (nell'esempio reset all)
*/

<template>
  <v-expansion-panels v-model="panels_model">
    <v-expansion-panel >
      <v-expansion-panel-header dense small>
        <v-row justify="center" align="center">
          <v-col>
            <v-btn icon x-small>
              <v-icon>mdi-filter</v-icon>
            </v-btn>Filtri di Ricerca
          </v-col>
          <v-col class="text-right mr-2">
            <v-btn icon x-small @click.stop="reset_all++;$emit('reset-all');">
              <v-icon>mdi-close-box-multiple</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot name="filters" :reset_all="reset_all"></slot>
        <slot name="bottom"></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: "MjFilterGroup",
  props: ["expand_filter"],
  computed: {},

  data: function () {
    return {
      reset_all: 0,
      panels_model:(this.expand_filter) ? 0 : false
    };
  },
  created() {},
  methods: {},
};
</script>