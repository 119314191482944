<template>
  <span>
<v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                :loading="email_sending > 0" class="white--text ml-2" color="blue"
                @click="show_account_balance_send_email_dialog = true">
              <v-icon>mdi-email-outline</v-icon>
            </v-btn>
          </template>
          <span>Invia email con estratto conto</span>
        </v-tooltip>
    <v-dialog v-model="show_account_balance_send_email_dialog" max-width="300px">
      <v-card>

        <v-card-title class="small mb-4">Invio Estratti Conto
          <v-spacer></v-spacer>
          <v-btn @click="show_account_balance_send_email_dialog = false" fab x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <MjDateOrTimePicker
              label='Da'
              v-model="account_balance_from"
              model_format='YYYY-MM-DD'
              mode='date'
              display_format='DD-MM-YYYY'
          ></MjDateOrTimePicker>
          <MjDateOrTimePicker
              label='A'
              v-model="account_balance_to"
              model_format='YYYY-MM-DD'
              mode='date'
              display_format='DD-MM-YYYY'
          ></MjDateOrTimePicker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="blue white--text" @click="sendAccountBalanceEmail" :loading="email_sending > 0">Invia</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>

import mj_axios from "@/mj_axios";
import moment from "moment";
import MjDateOrTimePicker from "../../form/MjDateOrTimePicker.vue";

export default {
  name: "emailAccountBalancePDFBtn",
  components: {MjDateOrTimePicker},
  props: {
    customers_selected: {type: Array, default: () => []},
  },
  data: () => ({
    show_account_balance_send_email_dialog: 0,
    email_sending: 0,

    account_balance_from: moment().startOf('year').format('YYYY-MM-DD'),
    account_balance_to: moment().endOf('year').format('YYYY-MM-DD'),
  }),
  methods: {
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },
    sendAccountBalanceEmail: function () {
      this.email_sending++;
      //todo
      const customer_ids = this.customers_selected.map((customer) => customer.id);
      const account_balance_from = this.account_balance_from ? this.account_balance_from : '';
      const account_balance_to = this.account_balance_to ? this.account_balance_to : '';
      const axios_promise = mj_axios.get(`/api/send_account_balance_mail?customer_ids=${JSON.stringify(customer_ids)}&from=${account_balance_from}&to=${account_balance_to}`);
      axios_promise.then(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "L'invio delle email è stato inserito nella coda",
          visible: true,
        });
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.email_sending--;
      });
      this.show_account_balance_send_email_dialog = false;
      return axios_promise;
    },
  }
}
</script>