<template>
    <v-card width="600px" flat class="mx-auto">
        <v-card-title class="justify-center">{{ title }}</v-card-title>
        <v-card-subtitle class="small">{{
            subtitle
            }}
        </v-card-subtitle>

        <v-card-text>
            <v-form ref="contactForm">
                <v-text-field label="Nome Studio" :rules="[rules.required,rules.max(30)]" v-model="name"></v-text-field>
                <v-text-field label="Email" :rules="[rules.required, rules.email]" v-model="email"></v-text-field>
                <v-text-field label="Telefono" :rules="[rules.max(30)]" v-model="mobile_number"></v-text-field>
                <v-text-field label="Password" :rules="[rules.required,rules.min(8),rules.max(30)]" type="password"
                              v-model="password"></v-text-field>
                <v-alert v-show="showAlert" :type="alertType" dense>
                    {{ alertText }}
                </v-alert>
                <v-btn color="primary" @click="submitForm" large :loading="sending >0 " v-show="showRegistrationButton">Registrati Adesso
                    <v-icon right>mdi-check</v-icon>
                </v-btn>

            </v-form>
        </v-card-text>

    </v-card>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin";
import mj_axios from "@/mj_axios";

export default {
    name: "MjStudioRegistrationForm",
    mixins: [ValidationRulesMixin],
    props: ['title', 'subtitle'],
    data: function () {
        return {
            name: '',
            email: '',
            mobile_number: '',
            password: '',
            sending: 0,
            showAlert: false,
            alertText: '',
            alertType: 'error',
            showRegistrationButton: true,
        }
    },
    methods: {
        submitForm: function () {
            this.showAlert = false;
            if (this.$refs.contactForm.validate()) {
                this.sending++;
                const params = {
                    name: this.name,
                    email: this.email,
                    mobile_number: this.mobile_number,
                    password: this.password
                };
                const axios_promise = mj_axios.post('/api/studio/actions/registration', params);
                axios_promise.then(() => {
                    this.showRegistrationButton = false;
                    this.showAlert = true;
                    this.alertType = "info";
                    this.alertText = "Registrazione avvenuta con successo, riceverai a breve una mail per l'attivazione";
                }).catch((e) => {
                    const status = (e.response) ? e.response.status : null;

                    this.showAlert = true;
                    this.alertType = "error";

                    if (status == 400) {
                        this.alertText = e.response.data.message;
                    } else {
                        this.alertText = "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.";
                    }
                }).then(() => {
                    this.sending--;
                });
                return axios_promise;
            }
        }
    },
    watch: {
        email: {
            handler() {
                this.showAlert = false;
                this.showRegistrationButton = true;
            }
        }
    }

}
</script>

<style scoped>

</style>