import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 mt-1 white--text",attrs:{"small":"","fab":"","color":"brown"},on:{"click":function($event){$event.stopPropagation();_vm.show_change_yearly_accounting_amount = true;}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-cash-multiple ")])],1)]}}])},[_c('span',[_vm._v("Cambia tariffa annua")])]),_c(VDialog,{model:{value:(_vm.show_change_yearly_accounting_amount),callback:function ($$v) {_vm.show_change_yearly_accounting_amount=$$v},expression:"show_change_yearly_accounting_amount"}},[_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,[_vm._v("Cambia tariffa contabilità annua")]),_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"x-small":"","fab":""},on:{"click":function($event){_vm.show_change_yearly_accounting_amount= false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1),_c(VCardText,[_c('SchedaYearlyAccountAmount',{attrs:{"customers":_vm.customers_selected},on:{"saved":function($event){_vm.show_change_yearly_accounting_amount  = false;_vm.$emit('saved')}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }