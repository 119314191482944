import Vuex from 'vuex'
import Vue from 'vue'
import mj_axios from '@/mj_axios.js';
import VueCookies from 'vue-cookies'

Vue.use(Vuex, VueCookies)

const store = new Vuex.Store({
    state: {
        packageVersion: process.env.PACKAGE_VERSION || '0', //presa da package.json grazie ad una configurazione su vue.config.js
        client_settings: {},
        token_obj: window.$cookies.get("token_obj") || '',
        user_avatar:window.localStorage.getItem("user_avatar") || null,
        modal: { title: 'Attenzione', text: 'Qualcosa è andato storto', visible: false },
        user: {}, //not used
        modal_navigation_breadcumbs: [] //memorizziamo le rotte con meta.header == close, quelle che simulano un modal
    },
    mutations: {
        pushToModalNavigationBreadcumbs(state, route) {
            // state.modal_navigation_breadcumbs.push(route);
            Vue.set(state.modal_navigation_breadcumbs, this.state.modal_navigation_breadcumbs.length, route)
        },
        popToModalNavigationBreadcumbs(state) {
            //cancelliamo l'ultimo
            state.modal_navigation_breadcumbs.splice(this.state.modal_navigation_breadcumbs.length - 1, 1);
        },
        doublePopToModalNavigationBreadcumbs(state) {
            //cancelliamo l'ultimo e il penultimo
            //il penultimo perchè la navigation guard su router.js lo re-inserirà dopo il caricamento della rotta
            state.modal_navigation_breadcumbs.splice(this.state.modal_navigation_breadcumbs.length - 2, 2);
        },
        resetModalNavigationBreadcumbs(state) {
            state.modal_navigation_breadcumbs = [];
        },
        updateClientVar(state, client_settings) {
            this.state.client_settings = client_settings;
            document.title = client_settings.APPNAME;
        },
        login_success(state, token_obj) {
            //l'avatar viene tolto dal token_obj in quanto nel cookie supererebbe le dimensioni massime del valore cookie consentito, lo metto quindi in una variabile a parte
            this.commit('update_avatar', token_obj["avatar"]);
            token_obj["avatar"] = null;
            window.$cookies.set("token_obj", token_obj); //support json object
            mj_axios.defaults.headers.common['Authorization'] = `Bearer ${token_obj.access_token}`;
            state.token_obj = token_obj
        },
        login_fail() {

        },
        logout(state) {
            state.token_obj = '';
            //tolgo l'avatar dopo il logout..
            window.localStorage.setItem("user_avatar",null);
        },
        reloadLoggedUser(state,user)
        {
            Vue.set(this.state.token_obj,'name',user.name);
            Vue.set(this.state.token_obj,'email',user.email);
            if (user.credit)
            {Vue.set(this.state.token_obj,'credit',user.credit);}
            else
            {Vue.set(this.state.token_obj,'credit',0);}
        },
        update_avatar(state,data)
        {
            //dobbiamo memorizzarlo nel local storage altrimenti su refresh lo stato viene perso.
            if (data)
            {window.localStorage.setItem("user_avatar",data);}
            else {window.localStorage.removeItem("user_avatar");}
            this.state.user_avatar = data;
        },
        update_modal_dialog(state, modal_obj) {
            state.modal = modal_obj;
        }
    },
    actions: {
        //ottengo le client var
        fetchClientVar(context) {
            mj_axios
                .get("/api/var/client_var")
                .then(response => {
                    const client_settings = response.data;
                    mj_axios.defaults.baseURL = client_settings.SERVER_BASEURL
                    context.commit('updateClientVar', client_settings);
                })
                .catch(response => {
                    alert("error in get client setting " + response);
                });
        },
        //ricarichiamo i dati utente (inizialmente realizzato per aggiornare il credit
        //sulla mutation vengono aggiornati anche altri dati (name e email)
        reloadLoggedUser(context){
            if (this.state.token_obj && this.state.token_obj.id)
            mj_axios
                .get(`/api/user/users/${this.state.token_obj.id}`)
                .then(response => {
                     context.commit('reloadLoggedUser', response.data.data);
                })
                .catch(response => {
                    alert("error reload user " + response);
                });
        },
        //operazione di login
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                mj_axios({ url: "/api/user/login", data: user, method: 'POST' })
                    .then(resp => {
                        const token_obj = resp.data;
                        commit('login_success', token_obj);
                        resolve(resp);
                    })
                    .catch(err => {
                        window.$cookies.remove("token_obj");
                        reject(err);
                    })
            })
        },
        logout({ commit }) {
            return new Promise((resolve) => {

                mj_axios
                    .get("/api/user/logout")
                    .then(() => {

                    })
                    .catch(response => {
                        alert("error logout " + response);
                    })
                    .then(() => {
                        commit('logout')
                        window.$cookies.remove("token_obj");
                        delete mj_axios.defaults.headers.common['Authorization'];
                        resolve()
                    });
            })
        }
    },
    getters: {
        appVersion: (state) => {
            return state.packageVersion
        },
        isAdmin: (state) => state.token_obj.role?.includes("Admin") || state.token_obj.role?.includes('SuperAdmin'),


    }

});

export default store;