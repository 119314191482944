<template>
  <v-container fluid class="py-0 px-0">
    <v-form ref="form">
      <v-row align="center">
        <v-col cols="12" md="7">
          <v-combobox label="Descrizione Servizio"
                      :loading="loadingStudioServices > 0"
                      v-model="descriptionModel"
                      :rules="[rules.required]"
                      :items="studioServices" :item-value="(service) => `${service.cod} ${service.description}`"
                      :item-text="(service) => `${service.cod} ${service.description}`"

                      @change="changeRowData"
                      :return-object="true"
          ></v-combobox>
        </v-col>
        <v-col md="2">
          <v-text-field v-model="quantityModel" @input="changeRowData" label="Quantità" type="number"
                        :rules="[rules.required]" reverse></v-text-field>
        </v-col>
        <v-col md="2">

          <MjTextField v-model="priceModel" :rules="[rules.required]" @input="changeRowData" label="Prezzo" type="money"
                       decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                       locale='it-IT' reverse>
          </MjTextField>
        </v-col>
        <v-col cols="1" class="text-right">
          <v-btn icon color="red" @click="removeRow">
            <v-icon>mdi-minus-circle-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import StudioServiceMixin from "@/mixins/entities/StudioServiceMixin";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin";
import MjTextField from "../form/MjTextField";

export default {
  name: "MjEDocumentServiceInputRow",
  components: {MjTextField},
  mixins: [ValidationRulesMixin, StudioServiceMixin],
  props: ["id", "description", "quantity", "price"],
  created() {
    this.loadStudioServices();
  },

  computed: {},
  data:
      function () {
        return {
          console: console,
          descriptionModel: this.description,
          quantityModel: this.quantity,
          priceModel: this.price,
        }
      },
  methods: {
    removeRow() {
      this.$emit('removeRow', this.id)
    },
    changeRowData() {

      
      //dal v-combobox arriva un return object che può essere sia object (nel caso di elemento caricato da db) che stringa nel caso di testo digitato.
      //se è un oggetto lo riporto a string per uniformare la gestione.. dopo aver catturato il prezzo.

      if (this.descriptionModel?.cost){
        this.priceModel = this.descriptionModel.cost;
        this.descriptionModel = this.descriptionModel.description;
      }

      this.$refs.form.validate();
      const data = {
        id: this.id,
        description: this.descriptionModel,
        quantity: this.quantityModel,
        price: this.priceModel
      }
      this.$emit('changeRowData', data);
    }

  }

}
</script>

<style scoped>

</style>