<template>
    <v-container>

        <v-form ref="form">
            <v-container>
                <v-autocomplete label="Scegli lo studio" :items="studios" item-text="name" item-value="id"
                                v-model="studioId"
                                :rules="[rules.required]">
                </v-autocomplete>


                <v-slider label="Numero mensilità"
                          hint="Indica il numero di mesi per i quali rinnovare l'abbonamento"
                          type="number"
                          class="my-10"
                          min="1"
                          max="36"
                          step="1"
                          tick-size="4"
                          show-ticks="always"
                          thumb-label="always"
                          ticks
                          v-model="numberOfSubscriptionMonth"
                          persistent-hint/>

                <v-btn color="primary" @click="insertPayment" :disabled="confirmButtonDisabled">Procedi</v-btn>

            </v-container>
        </v-form>
    </v-container>
</template>

<script>

import StudioMixin from "@/mixins/entities/StudioMixin";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin";
import mj_axios from "../../mj_axios";

export default {
    name: "SchedaPaymentLog",
    mixins: [StudioMixin, ValidationRulesMixin],
    created() {
        this.loadStudios();
    },
    data: () => ({
        numberOfSubscriptionMonth: 1,
        studioId: null,
        confirmButtonDisabled: false,
    }),
    methods: {
        insertPayment(){
            if (this.$refs.form.validate()) {


                this.loading++;
                const params = {"studioId":this.studioId, "numberOfSubscriptionMonth":this.numberOfSubscriptionMonth};
                const axios_promise = mj_axios.post('/api/payment', params);
                axios_promise.then(() => {
                    this.$router.go(-1);
                }).catch(() => {
                    this.$q.notify({
                        message: "Errore di salvataggio, è consigliato, prima di proseguire, risolvere il problema.",
                    });
                }).then(() => {
                    this.loading--;

                });
                return axios_promise;

            }else {
                const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
                this.$vuetify.goTo(fieldHasError);

            }
        }
    }
};
</script>
