<template>
  <v-container fill-height fluid>
    <MjAlreadyLoggedDialog></MjAlreadyLoggedDialog>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8" lg="4">
        <h1 class="text-center mb-2">{{ this.$store.state.client_settings.APPNAME }}</h1>
        <h3 class="text-center mb-4">Accedi a {{ this.$store.state.client_settings.APPNAME }}</h3>
        <MjLogin/>

      </v-col>
    </v-row>
    <v-row  class="text-center" align="center" justify="center">
        <MjGoogleLoginButton v-if="googleLoginOn"/>
        <MjFacebookLoginButton v-if="facebookLoginOn"/>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store.js";
import mj_config from "@/mj_config.js";
import MjLogin from "@/components/MjLogin.vue"
import MjAlreadyLoggedDialog from "@/components/MjAlreadyLoggedDialog.vue"
import MjGoogleLoginButton from "@/components/social/MjGoogleLoginButton.vue";
import MjFacebookLoginButton from "@/components/social/MjFacebookLoginButton.vue";

export default {
  name: "LoginView",
  components: {MjGoogleLoginButton, MjLogin, MjFacebookLoginButton, MjAlreadyLoggedDialog},
  store,mj_config,
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build

  computed: {
    access_token: function () {
      return store.state.token_obj.access_token;
    },
    googleLoginOn:()=>mj_config.googleLoginOn(),
    facebookLoginOn:()=>mj_config.facebookLoginOn(),
  },
  data: () => ({
    showLogged: true,
    show_error: false,
    login_error_text: "",
    valid: true,
    showPassword: false,
    form: {
      password: "",
      email: ""
    },
    passwordRules: [
      v => !!v || "Password Obbligatora is required",
      v => (v && v.length >= 8) || "Digita almeno 8 caratteri"
    ],
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+\..+/.test(v) || "E-mail must be valid"
    ],
    lazy: false
  }),

  methods: {
    submit() {
      this.show_error = false;
      this.login_error_text = "";
      if (this.$refs.form.validate()) {
        const user = {
          email: this.form.email,
          password: this.form.password
        };

        store
            .dispatch("login", user)
            .then(() => {
              this.$router.replace("/menu");
            })
            .catch(e => {
              this.show_error = true; //usa arrow function per avere il this del componente
              this.login_error_text = e.response.data.message;
            });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logout").finally(() => {
        this.$router.replace("/utente/login");
      });
    }
  },
  watch: {
    form: {
      handler: function () {
        this.show_error = false;
        return true;
      },
      deep: true
    }
  }
};
</script>
