<template>
  <v-container fluid>
    <MjTableItems
        :loadItemsOnCreated="true"
        ref="table_edocuments"
        model-name-prop="EDocument"
        resource-collection-name="EDocumentResourceListCollection"
        @selectedChange="(selected) => edocuments_selected = selected"

    >
      <template slot="mj_filters_section"  slot-scope="props">

        <MjFilterGroup
            ref="filter_group"
            :expand_filter="false"
            class="mt-0 px-2"
            @reset-all="
            $refs.table_edocuments.filters = [];
            $refs.table_edocuments.loadItems(true);
          "
        >

          <template slot="filters" slot-scope="props_filters">
            <v-row no-gutters>
              <v-col>
                <MjDateIntervalFilter
                    label-from="Da"
                    label-to="A"
                    time-model-format="HH:mm:ss"
                    time-display-format="HH:mm"
                    date-model-format="YYYY-MM-DD"
                    date-display-format="DD/MM/YYYY"
                    :value-from="start_of_current_month"
                    :value-to="end_of_current_month"
                    mode="date"
                    glue="and"
                    @change="props.updateFilters"
                    :silent_reset="props_filters.reset_all"
                    filter_name="date"
                />
              </v-col>
            </v-row>
            <v-row align="baseline"  v-if="$route.params.customer_id == null">
              <v-col cols="9">
                <MjComboBoxFilter
                    label="Cliente"
                    load_items_url="/api/list/Customer/CustomerResource4SelectCollection"
                    filter_name="customer_id"
                    :item_description_field="(item) => `${item.business_name}`"
                    item_value_field="id"
                    glue="and"
                    :priority="1"
                    @change="(data) => {current_customer_id_filter = data.val;this;updateFilters(data)}"
                    :silent_reset="props.reset_all"
                />
              </v-col>
              
            </v-row>
          </template>
        </MjFilterGroup>

      </template>
      <template slot="mj_top_toolbar_prepend" slot-scope="data">
        <v-tooltip bottom v-if='data.selected.length > 0'>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2 mt-1 white--text" small fab v-bind='attrs' v-on='on' color="purple"
                   :loading="downloadingXmls > 0"
                   @click.stop='downloadXmls(data.selected)'>
              <v-icon>
                mdi-folder-zip-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Scarica Zip con Xml</span>
        </v-tooltip>
      </template>
    </MjTableItems>
  </v-container>
</template>
<style>

</style>
<script>

import MjTableItems from "@/components/MjTableItems";
import MjComboBoxFilter from "@/components/filters/MjComboBoxFilter";
import MjFilterGroup from "@/components/filters/MjFilterGroup";
import moment from "moment";
import MjDateIntervalFilter from "@/components/filters/MjDateIntervalFilter";
import mj_axios from "../../mj_axios";

export default {
  name: "TableEDocuments",
  components: { MjTableItems,MjComboBoxFilter, MjFilterGroup, MjDateIntervalFilter},

  data: () => ({
    start_of_current_month: moment().startOf('month').format('YYYY-MM-DD'),
    end_of_current_month: moment().endOf('month').format('YYYY-MM-DD'),
    downloadingXmls: 0,

  }),
  methods: {

    updateFilters: function (data) {
      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs.table_edocuments.updateFilters(data);

      })
    },
    downloadXmls: function(selectedEDocumentIds){
      const ids = JSON.stringify(selectedEDocumentIds.map((edoc)=>edoc.id));
      const url = `/api/edocuments/${ids}/actions/download_xmls_zip`;
      this.downloadingXmls++;

      //caso selezione singola per nome pdf
      const filename =`archivio_edoc.zip`;
      mj_axios.download(url, filename)
          .then(() => {this.downloadingXmls--;})
          .catch(() => {
            this.$store.commit("update_modal_dialog", {
              title: "Errore!!",
              text: "Si è verificato un errore durante il download dell'edocument xml.",
              visible: true,
            });
            this.downloadingXmls--;
          });
    }

  },
  watch: {}
};
</script>
