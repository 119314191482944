<template>
  <v-tooltip bottom >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="ml-2 mt-1 white--text blue darken-3" small fab v-bind='attrs' v-on='on'
             :loading="accounting_contract_html_downloading > 0"
             @click.stop='downloadContractHTML'>
        <v-icon>
          mdi-file-sign
        </v-icon>
      </v-btn>
    </template>
    <span>Scarica Contratto DOC</span>
  </v-tooltip>
</template>
<script>

import mj_axios from "@/mj_axios";
export default {
  name: "downloadContractHTMLBtn",
  components: {},
  props:{
    customers_selected:{type:Array, default:()=>[]},
  },
  data:()=>({
    accounting_contract_html_downloading: 0
  }),
  methods:{
    downloadContractHTML: function () {
      if (this.customers_selected.length != 1) {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "Attenzione scegli solo un cliente",
          visible: true,
        });
      } else {
        const customer_id = this.customers_selected[0]["id"];

        const url = `/api/accounting_contract_html?customer_id=${JSON.stringify(customer_id)}`;
        this.accounting_contract_html_downloading++;


        const filename = `${this.customers_selected[0]['Ragione Sociale']} Dichiarazione Tenuta Contabilita.html.doc`;
        mj_axios.download(url, filename).then(() => this.accounting_contract_html_downloading--).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Errore!!",
            text: "Attenzione, controlla i dati obbligatori del cliente e assicurati che abbia un legale rappresentante",
            visible: true,
          });
          this.accounting_contract_html_downloading--;
        });
      }
    },
  }
}
</script>