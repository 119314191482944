<template>

    <v-card elevation="0">
      <v-card-subtitle>Cliente: {{(customer) ? customer.business_name : ' - '}}</v-card-subtitle>

      <v-card-text>
        <v-progress-linear indeterminate v-show="loading > 0"></v-progress-linear>
        <v-autocomplete label="Anno" v-model="year"
                        item-text="text"
                        item-value="value"
                        :return-object="true"
                        :items="years"
                        @change="loadCedolini"
                        >
        </v-autocomplete>
        <v-simple-table dense>
          <thead>
          <tr>
            <th>Mese</th>
            <th># Cedolini</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="month in months" :key="month.value">
            <td>{{ month.text }}</td>
            <td> {{ (cedolini && cedolini[month.value]) ? cedolini[month.value]["quantity"] : '-' }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>


</template>

<script>
import moment from "moment";
import mj_string_utils from "@/misc/mj_string_utils.js";
import mj_axios from "@/mj_axios";

export default {
  name: "CedoliniSavedView",
  props: {

  },
  computed: {
    years: function () {
      let years = [];
      const currentYear = new Date().getFullYear();
      for (let i = 50; i > 0; i--) {
        years.push({text:currentYear - i,value:currentYear- i});
      }
      for (let i = 0; i < 50; i++) {
        years.push({text:currentYear + i,value:currentYear+ i});
      }

      return years;
    },
    months: function () {
      let months = [];

      for (let i = 1; i <= 12; i++) {
        const monthDescription = mj_string_utils.capitalize(moment().locale('it').set('month', i - 1).format('MMM'))
        months.push({'text': monthDescription, value: i});
      }
      return months;
    },

  },
  mounted() {
    this.customer_id = this.$route.params.customer_id

    this.loadCedolini();
    this.loadCustomer();
  },
  data: () => ({
    moment: moment,
    loading: 0,
    mj_string_utils: mj_string_utils,
    year: {text:new Date().getFullYear(), value:new Date().getFullYear()},
    month: {},
    customer: null,
    cedolini: {},
    customer_id:null,
  }),
  methods: {
    loadCustomer:function(){
      this.loading++;
      const axios_promise = mj_axios.get(`/api/customer/${this.customer_id}`);
      axios_promise.then(response => {
        this.customer = response.data.data
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    },
    loadCedolini: function () {
      this.loading++;

      const axios_promise = mj_axios.get(`/api/cedolini?customer_id=${this.customer_id}&year=${this.year.value}`);
      axios_promise.then((response) => {
        this.cedolini = response.data;
      }).catch((e) => {
        let message =
            e.response && e.response.data ? e.response.data.message : '';

        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: `Errore di salvataggio ${message} , è consigliato, prima di proseguire, risolvere il problema.`,
          visible: true,
        });
      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  },
  watch: {}
}
</script>

<style scoped>

</style>