<template>
  <v-menu
      :close-on-content-click="false"
      v-model="show_collaborators"
  >
    <template v-slot:activator="{ on:menu, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on:tooltip }">
          <v-btn
              fab
              small
              :color="btnColor"
              class="ml-2"
              v-on="{...tooltip, ...menu}"
              v-bind="attrs"
          >
            <v-icon>mdi-account-group</v-icon>
          </v-btn>
        </template>
        <span>{{ tooltipText }}</span>
      </v-tooltip>
    </template>
      <MjSelectableList
          title="Collaboratori"
          load_items_url="/api/collaborators"
          belong_url="/api/parents_has_children"
          pivot_table="customers"
          parent_col_name="id"
          :child_col_name="childColName"
          parent_model_name="Custom\Customer"
          child_model_name="User"
          :relation_name="relationName"
          list_title_field="name"
          list_subtitle_field=""
          max_height="300px"
          :exclusive="true"
          :parent_ids="selected_ids"
          @close-popup="show_collaborators = false; $emit('close-popup')"
      />

  </v-menu>
</template>

<script>
import MjSelectableList from "@/components/MjSelectableList.vue";

export default {
  name: "collaboratorLinkerBtn",
  components: {MjSelectableList},
  props: {
    selected_ids: {type: Array, default: () => []},
    relationName: {type: String, required: true},
    childColName: {required: true},
    btnColor: {type: String, default: () => "primary"},
    tooltipText: {type: String, required: true, default:() => 'Associa ..'},
  },
  data: () => ({
    show_collaborators: false,
  })
}
</script>


<style scoped>

</style>