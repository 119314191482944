<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h4>Genera Bollettino</h4>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div class="primary--text" v-if="multipleCustomerMode">Aggiungo la transazione per <span
            class="font-weight-bold lighten-3">({{ customers.length }})</span> Clienti Selezionati
        </div>
        <v-row class="align-baseline">
          <v-col cols='12' md='6' v-if="!multipleCustomerMode">
            <v-autocomplete
                multiple
                chips
                deletable-chips
                label='Clienti'
                v-model='form.customer_ids.val'
                item-text="business_name" item-value="id" :items="this.selects.customer_id" cache-items
                :rules="[rules.required, rules.minCount(1)]">
            </v-autocomplete>
          </v-col>

        </v-row>
        <v-row>

          <v-col cols='12' md='6'>
            <v-autocomplete
                label='Servizio Erogato'
                v-model='form.studio_service_id.val'
                item-text="description" item-value="id" :items="this.selects.studio_service_id" cache-items
                @change="suggestAmountByStudioService"
                :rules="[rules.required]"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='3'>
            <MjTextField
                label='Importo'
                v-model='form.amount.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                locale='it-IT'
                :rules="[rules.required]">
            </MjTextField>
          </v-col>
        </v-row>

        <v-row justify="start">
          <v-col >
            <v-btn color="primary" class="mt-1 mr-2" @click="downloadPDF" :loading="pdf_downloading > 0 ">
              <v-icon left>mdi-file-pdf-box</v-icon>
              Download PDF
            </v-btn>
            <v-btn color="primary" class="mr-2 mt-1" @click="downloadHTML" :loading="html_downloading > 0 ">
              <v-icon left>mdi-file</v-icon>
              Download Doc
            </v-btn>
            <v-btn color="secondary" class=" mt-1" @click="sendEmails" :loading="email_sending > 0 ">
              <v-icon left>mdi-email</v-icon>
              Invia PDF
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjTextField from '@/components/form/MjTextField.vue';
import mj_axios from "@/mj_axios";

export default {
  name: "SchedaBollettino",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjTextField},
  props: {
    multipleCustomerMode: {default: () => false},

    customers: {default: () => []}

  },
  mounted() {
    //su default val perchè passato dalle prop deve restare tale anche se resetto
    this.form.customer_ids.default_val = this.customers.map(customer => customer.id);
  },
  data: () => ({
    form: {
      amount: {type: 'decimal', val: '', default_val: ''},
      customer_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/customers'},
      studio_service_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/studio_services'},
      customer_ids: {type: 'array', val: []},

    },
    pdf_downloading: 0,
    html_downloading: 0,
    email_sending: 0,
    apiUrl: "/api/transactions"
  }),

  methods: {
    suggestAmountByStudioService(item_id) {
      const studio_service = this.selects.studio_service_id.find((studio_service) => studio_service.id === item_id);
      this.form.amount.val = studio_service.cost;
    },
    sendEmails() {
      if (this.$refs.form.validate()) {

        this.email_sending++;

        const params = {
          "customers_ids": JSON.stringify(this.form.customer_ids.val),
          "amount": this.form.amount.val,
          "studio_service_id": this.form.studio_service_id.val
        };
        const axios_promise = mj_axios.post('/api/bollettino/actions/email_pdf', params);
        axios_promise.then(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Info!!",
            text: "Invio email in coda..",
            visible: true,
          });
        }).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        }).then(() => {
          this.email_sending--;
        });
        return axios_promise;


      } else {
        const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
        this.$vuetify.goTo(fieldHasError);
      }
    },
    downloadPDF() {
      if (this.$refs.form.validate()) {
        const url = `/api/bollettino/actions/download_pdf?customers_ids=${JSON.stringify(this.form.customer_ids.val)}&amount=${this.form.amount.val}&studio_service_id=${this.form.studio_service_id.val}`;
        this.pdf_downloading++;

        //caso selezione singola per nome pdf
        const filename = this.form.customer_ids.val.length == 1 ? `Bollettino.pdf` : `Bollettini.zip`;
        mj_axios.download(url, filename).then(() => this.pdf_downloading--).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Errore!!",
            text: "Si è verificato un errore durante il download del pdf bollettino.",
            visible: true,
          });
          this.pdf_downloading--;
        });
      } else {
        const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
        this.$vuetify.goTo(fieldHasError);
      }
    },
    downloadHTML() {
      if (this.$refs.form.validate()) {
        const url = `/api/bollettino/actions/download_html?customers_ids=${JSON.stringify(this.form.customer_ids.val)}&amount=${this.form.amount.val}&studio_service_id=${this.form.studio_service_id.val}`;
        this.html_downloading++;

        //caso selezione singola per nome pdf
        const filename = this.form.customer_ids.val.length == 1 ? `Bollettino.html.doc` : `Bollettini.zip`;
        mj_axios.download(url, filename).then(() => this.html_downloading--).catch(() => {
          this.$store.commit("update_modal_dialog", {
            title: "Errore!!",
            text: "Si è verificato un errore durante il download del pdf bollettino.",
            visible: true,
          });
          this.html_downloading--;
        });
      } else {
        const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
        this.$vuetify.goTo(fieldHasError);
      }
    },
  },
  watch: {

    customers: {
      handler: function () {
        this.form.customer_ids.val = this.customers.map(customer => customer.id);
      }
      , deep: true
    }
  }
};
</script>
