<template>
  <v-container fluid class="pa-0 ">

<!--    CONTACT ME-->
    <section class="text-center" id="contact_form" >
      <MjContactForm class="ma-8" title="Contattaci per una Prova Gratuita"
                     subtitle="Una volta ricevuta la tua richiesta ti ricontatteremo per forniti gratuitamente un account con periodo di prova, toccherai con mano il nostro software e potrai stabilire se
      acquistare o meno un abbonamento.">

      </MjContactForm>

    </section>
  </v-container>
</template>

<script>
import MjContactForm from "@/components/custom/MjContactForm";

export default {
  name: "LandingView",
  components: {MjContactForm},
  data: function () {
    return {
      carouselImages: [
        {requiredUrl: require('@/assets/images/landing/screenshots/menu.png'), alt: 'Menu Funzionalità'},
        {requiredUrl: require('@/assets/images/landing/screenshots/customers.png'), alt: 'Gestione Clienti'},
        {requiredUrl: require('@/assets/images/landing/screenshots/edocuments.png'), alt: 'Fattura Elettronica'},
        {requiredUrl: require('@/assets/images/landing/screenshots/edocument.png'), alt: 'Fattura Elettronica'},
        {requiredUrl: require('@/assets/images/landing/screenshots/jobs.png'), alt: 'Gestione Adempimenti'},
        {requiredUrl: require('@/assets/images/landing/screenshots/transactions.png'), alt: 'Transazioni'},
      ]
    }
  }
}
</script>

<style scoped>
.landing_hr{
  height:2px ;
  border:none;
  width:80%
}
</style>