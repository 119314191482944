<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Modello Word con id: {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='12'>
            <v-text-field
                label='Titolo'
                v-model='form.title.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12'>
            <v-textarea
                label='Descrizione'
                v-model='form.description.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-expansion-panels v-model="instructionPanel">
          <v-expansion-panel >
            <v-expansion-panel-header>Lista tag validi
              <br>
            <v-progress-linear v-show="loadingWordTemplateReplaceKeys>0" indeterminate></v-progress-linear>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="(key,index) in word_template_replace_keys" :key="index">{{key}}</div>

            </v-expansion-panel-content>
          </v-expansion-panel>

        </v-expansion-panels>
        <v-card class="my-8">
          <v-card-title class="white--text blue-grey text-body-1">File Word</v-card-title>
          <v-card-text>
            <MjUploader
                :accepted_mime_types="['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document']"
                :max_files="1"
                ref="mj_uploader"
                belong_col_name="word_template_id"
                belong_api_url="/api/word_template_attachments"
            />
          </v-card-text>
        </v-card>
        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>

  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjUploader from "@/components/form/MjUploader.vue";
import mj_axios from "@/mj_axios";


export default {
  name: "SchedaWordTemplate",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjUploader, MjSchedaToolbar},
  mounted() {
    this.loadWordTemplateReplaceKeys();
  },
  data: () => ({
    instructionPanel: 1,
    word_template_replace_keys:{},
    loadingWordTemplateReplaceKeys:0,
    form: {
      user_id: {type: 'integer', val: '', default_val: '', loadSelect: true, apiUrl: '/api/user/users'},
      title: {type: 'string', val: '', default_val: ''},
      description: {type: 'text', val: '', default_val: ''},
    },
    apiUrl: "/api/word_templates"
  }),

  methods: {
    loadWordTemplateReplaceKeys(){
      this.loadingWordTemplateReplaceKeys++;
      const axios_promise = mj_axios.get('/api/word_template_replace_keys');
      axios_promise.then(response => {
        this.word_template_replace_keys = response.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento word template replace key",
          visible: true,
        });
      }).then(() => {
        this.loadingWordTemplateReplaceKeys--;
      });
      return axios_promise;

    }
  }
};
</script>
