import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VOverlay,{attrs:{"value":_vm.loading_overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"label":"Email"},model:{value:(_vm.form.email.val),callback:function ($$v) {_vm.$set(_vm.form.email, "val", $$v)},expression:"form.email.val"}}),_c(VTextField,{attrs:{"type":"password","rules":_vm.editMode ? [] : [_vm.rules.required, _vm.rules.min(8)],"label":"Password","autocomplete":"off","persistent-hint":"","hint":"Password non registrata nel sistema per motivi di sicurezza. Se vuoi cambiarla scrivi la nuova password nel campo di testo."},model:{value:(_vm.form.password.val),callback:function ($$v) {_vm.$set(_vm.form.password, "val", $$v)},expression:"form.password.val"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4","align-self":"center"}},[_c('mj-image',{attrs:{"text":_vm.form.name.val[0],"image-src":_vm.form.avatar.val,"shape":"circle","circle_size":"100"},on:{"img-changed":_vm.updateAvatar}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"label":"Nome"},model:{value:(_vm.form.name.val),callback:function ($$v) {_vm.$set(_vm.form.name, "val", $$v)},expression:"form.name.val"}})],1)],1),_vm._t("additional_fields",null,{"form":_vm.form}),_c('MjSchedaToolbar',{attrs:{"error_show":_vm.error_show,"error_text":_vm.error_text,"edit_not_saved":_vm.edit_not_saved,"edit_mode":_vm.editMode,"new_button_visibile":false},on:{"save-item-click":function($event){return _vm.saveItem()},"go-back-click":function($event){return _vm.goBack()}}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }