<template>
    <v-app>
        <MjHeader :show_app_name="false"/>
        <v-main class="mb-12 pt-16">
            <MjStudioProfileStatus v-if="is_logged && role !== 'SuperAdmin'"/>
            <MjSubscriptionStatus v-if="is_logged"/>
            <router-view :key="$route.path"/>

            <MjConfirmDialog
                    v-model="appDialogShow"
                    :dialog_title="appDialogTitle"
                    :dialog_text="appDialogText"
                    cancel_button_text='Ho Capito'
                    :confirm_button_visible='false'
                    @cancel-from-dialog='$store.commit("update_modal_dialog", {visible: false,});'
            >
            </MjConfirmDialog>
        </v-main>
        <v-main>
            <v-snackbar v-model="snackWithButtons" :timeout="timeout" bottom>
                {{ snackWithBtnText }}
                <v-btn dark class="primary" @click.stop="refreshApp">{{
                    snackBtnText
                    }}
                </v-btn>
                <v-btn dark icon @click="snackWithButtons = false">
                    <v-icon>mdi-close-circle</v-icon>
                </v-btn>
            </v-snackbar>
        </v-main>
        <MjFooter v-if="$route.meta.sidebar !== 'hide'"/>
    </v-app>
</template>

<script>
import MjHeader from "./components/MjHeader.vue";
import MjFooter from "./components/MjFooter.vue";
import MjConfirmDialog from "@/components/MjConfirmDialog";
import MjSubscriptionStatus from "@/components/MjSubscriptionStatus";
import MjStudioProfileStatus from "./components/custom/MjStudioProfileStatus.vue";


export default {
    name: "App",
    components: {MjStudioProfileStatus, MjHeader, MjFooter, MjConfirmDialog, MjSubscriptionStatus},
    computed: {
        appDialogShow() {
            return this.$store.state.modal.visible;
        },
        appDialogTitle() {
            return this.$store.state.modal.title;
        },
        appDialogText() {
            return this.$store.state.modal.text;
        },
        role() {return this.$store.state?.token_obj?.role;},
        is_logged() {
            return (
                this.$store.state.token_obj != null &&
                this.$store.state.token_obj !== ""
            );
        },
    },
    created() {

        if (navigator.serviceWorker) {
            /* mjSwUpdated viene emesso da registerserviceworker quando trova un update.
             in uqesto caso il nuovo service worker viene installato ma è in stato di waiting per l'attivazione.
          */

            // Listen for swUpdated event and display refresh snackbar as required.
            document.addEventListener("mjSwUpdated", this.showRefreshUI, {
                once: true,
            });
            // Refresh all open app tabs when a new service worker is installed.
            navigator.serviceWorker.addEventListener("controllerchange", () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    data: () => ({

        refreshing: false,
        registration: null,
        snackBtnText: "",
        snackWithBtnText: "",
        snackWithButtons: false,
        timeout: -1,
    }),
    methods: {
        showRefreshUI(e) {

            // Display a snackbar inviting the user to refresh/reload the app due
            // to an app update being available.
            // The new service worker is installed, but not yet active.
            // Store the ServiceWorkerRegistration instance for later use.

            this.registration = e.detail;
            this.snackBtnText = "Aggiorna";
            this.snackWithBtnText = "Nuova versione disponibile!";
            this.snackWithButtons = true;
        },

        refreshApp() {
            this.snackWithButtons = false;

            // Protect against missing registration.waiting.
            if (!this.registration || !this.registration.waiting) {
                return;
            }

            this.registration.waiting.postMessage("skipWaiting");
        },
    },
};
</script>
