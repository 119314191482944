import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('MjTableItems',{ref:"table_customers",attrs:{"model-name-prop":"Customer","resource-collection-name":"CustomerResourceListCollection","sort_by":"Ragione Sociale","headersCustom":[{text:'TariffaAnn',value:'TariffaAnn', align:'right'},{text:'Cedolino',value:'Cedolino', align:'right'}],"baseQueryFilter":[{'name':'cedolino_rate','type':'gt','val':[0]}],"customEditItem":_vm.openCedoliniSavedView},on:{"selectedChange":function (selected) { return this$1.customers_selected = selected; }},scopedSlots:_vm._u([{key:"mj_top_toolbar_prepend",fn:function(data){return [(data.selected.length > 0)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2 mt-1 white--text",attrs:{"small":"","fab":"","color":"purple","loading":_vm.cedolino_transactions_saving > 0},on:{"click":function($event){$event.stopPropagation();_vm.showCedoliniDialog  = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-credit-card-multiple-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aggiungi Cedolini")])]):_vm._e()]}}])}),_c(VDialog,{attrs:{"max-width":"480px"},model:{value:(_vm.showCedoliniDialog),callback:function ($$v) {_vm.showCedoliniDialog=$$v},expression:"showCedoliniDialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Aggiungi Cedolini "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.showCedoliniDialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('CedoliniInsertView',{attrs:{"force-disable-back-on-save":true,"multiple-customer-mode":true,"customers":_vm.customers_selected,"router-replace-after-save":false},on:{"saved":function (){_vm.showCedoliniDialog = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }