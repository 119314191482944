<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <div>Studio Job {{ $route.params.id }}</div>
        <v-row>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='9'>
            <v-text-field
                label='Nome'
                v-model='form.name.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-col md="8"><v-autocomplete
                prepend-icon="mdi-check"
                label='Sotto adempimenti'
                chips
                deletable-chips
                multiple
                v-model='form.task_ids.val'
                item-text="name" item-value="id" :items="this.selects.task_ids" cache-items
            >
            </v-autocomplete></v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Nota'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>

        </v-row>

        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";


export default {
  name: "SchedaStudioJob",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjSchedaToolbar,},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      name: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
      task_ids: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/tasks'
      },
    },
    apiUrl: "/api/studio_jobs"
  }),

  methods: {}
};
</script>
