import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"pa-0",attrs:{"outlined":"","two-line":"","dense":"","nav":""}},[_c(VListItemGroup,{on:{"change":function($event){return _vm.$emit('input',_vm.wordTemplates[_vm.selected])}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[(_vm.loading > 0)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_vm._l((_vm.wordTemplates),function(wordTemplate,index){return [(index > 0)?_c(VDivider,{key:'divider'+ index}):_vm._e(),_c(VListItem,{key:index,staticClass:"my-0"},[_c(VListItemAvatar,[_c(VIcon,[_vm._v("mdi-microsoft-word")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(wordTemplate.title))]),_c(VListItemSubtitle,[_vm._v("descrizione")])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-chevron-right")])],1)],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }