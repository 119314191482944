import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":"","color":"green white--text"},on:{"click":function($event){_vm.show_add_transactions_dialog = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-credit-card-plus")])],1)]}}])},[_c('span',[_vm._v("Aggiungi Transazione")])]),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.show_add_transactions_dialog),callback:function ($$v) {_vm.show_add_transactions_dialog=$$v},expression:"show_add_transactions_dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Aggiungi Transazione "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.show_add_transactions_dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('SchedaTransaction',{attrs:{"force-disable-back-on-save":true,"multiple-customer-mode":true,"customers":_vm.customers_selected,"router-replace-after-save":false},on:{"saved":function($event){_vm.show_add_transactions_dialog  = false}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }