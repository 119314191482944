import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('MjTableItems',{ref:"table_customers",attrs:{"model-name-prop":"Customer","resource-collection-name":"CustomerResourceListCollection","sort_by":"Ragione Sociale","loadItemsOnCreated":false},on:{"selectedChange":function (selected) { return this$1.customers_selected = selected; }},scopedSlots:_vm._u([{key:"mj_filters_section",fn:function(){return [_c('MjFilterGroup',{ref:"filter_group",staticClass:"mt-6 px-2",attrs:{"expand_filter":true},on:{"reset-all":function($event){_vm.$refs.table_customers.filters = [];
          _vm.$refs.table_customers.loadItems(true);}},scopedSlots:_vm._u([{key:"filters",fn:function(ref){
          var this$1 = this;
          var reset_all = ref.reset_all;
return [(_vm.$route.params.customer_id == null)?_c(VRow,{attrs:{"align":"baseline"}},[_c(VCol,{attrs:{"cols":"6"}},[_c('MjComboBoxFilter',{attrs:{"label":"Forma Giuridica","load_items_url":"/api/list/LegalStatus/LegalStatusResourceListCollection","filter_name":"legal_status_id","item_description_field":function (item) { return ((item.Cod) + " - " + (item.Descrizione)); },"item_value_field":"id","glue":"and","priority":1,"silent_reset":reset_all},on:{"change":function (data) {this$1;_vm.updateFilters(data)}}})],1),_c(VCol,{attrs:{"cols":"6"}},[_c('MjToggleFilter',{attrs:{"small":true,"item_description_field":"description","item_value_field":"value","items_prop":[{description:'Cessati', value:1, icon:'mdi-close-octagon-outline',color:'red'},{description:'Attivi', value:0, icon:'mdi-check',color:'green'}],"selected_prop":0,"filter_name":"ceased","glue":"and","silent_reset":reset_all},on:{"change":function (data) {this$1;_vm.updateFilters(data)}}})],1)],1):_vm._e()]}}])})]},proxy:true},{key:"mj_top_toolbar_prepend",fn:function(ref){
          var selected = ref.selected;
          var selected_ids = ref.selected_ids;
return [(selected.length > 0)?_c('span',[_c('collaboratorLinkerBtn',{attrs:{"selected_ids":selected_ids,"relationName":"CollaboratorAccounting","childColName":"collaborator_accounting_id","btnColor":"primary","tooltipText":"Associa collaboratori per Contabilità"},on:{"close-popup":function($event){return _vm.$refs.table_customers.loadItems(true);}}}),_c('collaboratorLinkerBtn',{attrs:{"selected_ids":selected_ids,"relationName":"CollaboratorStudioJobs","childColName":"collaborator_studio_jobs_id","btnColor":"blue","tooltipText":"Associa collaboratori per Adempimenti"},on:{"close-popup":function($event){return _vm.$refs.table_customers.loadItems(true);}}}),_c('installment-generator-btn',{attrs:{"customers_selected":selected}}),_c('yearlyAccountingAmountBtn',{attrs:{"customers_selected":selected},on:{"saved":function($event){return _vm.$refs.table_customers.loadItems(true);}}}),_c('downloadContractHTMLBtn',{attrs:{"customers_selected":selected}}),_c('downloadContractPDFBtn',{attrs:{"customers_selected":selected}}),_c('addTransactionBtn',{attrs:{"customers_selected":selected}}),_c('downloadAccountBalancePDFBtn',{attrs:{"customers_selected":selected}}),_c('emailAccountBalancePDFBtn',{attrs:{"customers_selected":selected}}),_c('emailContractPDFBtn',{attrs:{"customers_selected":selected}}),_c('downloadLetteraBonificoPDFBtn',{attrs:{"customers_selected":selected}}),_c('annualBalancerBtn',{attrs:{"customers_selected":selected}}),_c('emailsExporterBtn',{attrs:{"customers_selected":selected}}),_c('wordTemplatesBtn',{attrs:{"customers_selected":selected}})],1):_vm._e()]}},{key:"mj_left_item_actions",fn:function(ref){
          var item = ref.item;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({path:("/table/Transaction/" + (item.id))})}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" mdi-account-cash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Estratto Conto")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }