<template>
  <v-container fluid>
    <MjTableItems
        ref="table_customers"
        model-name-prop="Customer"
        resource-collection-name="CustomerResourceListCollection"
        @selectedChange="(selected) => this.customers_selected = selected"
        sort_by="Ragione Sociale"
        :headersCustom="[{text:'TariffaAnn',value:'TariffaAnn', align:'right'},{text:'Cedolino',value:'Cedolino', align:'right'}]"
        :baseQueryFilter="[{'name':'cedolino_rate','type':'gt','val':[0]}]"
        :customEditItem="openCedoliniSavedView"
    >
      <template slot="mj_top_toolbar_prepend" slot-scope="data">
        <v-tooltip bottom v-if='data.selected.length > 0'>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-2 mt-1 white--text" small fab v-bind='attrs' v-on='on' color="purple"
                   :loading="cedolino_transactions_saving > 0"
                   @click.stop='showCedoliniDialog  = true'>
              <v-icon>
                mdi-credit-card-multiple-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Aggiungi Cedolini</span>
        </v-tooltip>
      </template>


    </MjTableItems>


    <v-dialog v-model="showCedoliniDialog" max-width="480px">
      <v-card >
        <v-card-title>Aggiungi Cedolini
          <v-spacer></v-spacer>
          <v-btn @click="showCedoliniDialog = false" fab x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <CedoliniInsertView
                            :force-disable-back-on-save="true"
                             :multiple-customer-mode="true"
                             :customers="customers_selected"
                             :router-replace-after-save="false"
                             @saved="()=> {showCedoliniDialog = false}">
          </CedoliniInsertView>
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<style>
.generated_report tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>
<script>

import MjTableItems from "@/components/MjTableItems";


import mj_axios from "@/mj_axios";
import moment from "moment";
import CedoliniInsertView from "@/views/Custom/CedoliniInsertView";

export default {
  name: "TableCustomersForCedolini",
  components: { MjTableItems,CedoliniInsertView},

  data: () => ({

    customers_selected: null,

    showCedoliniDialog: false,
    cedolino_transactions_saving: 0,

    formatter: new Intl.NumberFormat('it-IT', {
      useGrouping: true,
    }),
  }),

  methods: {
    //per uso nei tag del template
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },



    addCedoliniTransactions: function () {
      this.cedolinoTransationsSaving++;

      const customer_ids = this.customers_selected.map((customer) => customer.id);

      const axios_promise = mj_axios.get(`/api/send_contract_pdf?customer_ids=${JSON.stringify(customer_ids)}`);
      axios_promise.then(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "L'invio del contratto è stato inserito nella coda",
          visible: true,
        });
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.cedolinoTransationsSaving--;
      });
      return axios_promise;
    },
    openCedoliniSavedView:function(row){


      this.$router.push({name: "CedoliniSavedView", params: {customer_id: row.id}});
    },

    updateFilters: function (data) {
      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs.table_customers.updateFilters(data);

      })
    },
  },

  watch: {}
};
</script>
