<template>
  <span>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                color="blue darken-2 white--text"
                class="ml-2"
                @click="show_word_templates = true"
            >
              <v-icon>mdi-microsoft-word</v-icon>
            </v-btn>
          </template>
          <span>Modelli Word</span>
        </v-tooltip>
        <v-dialog v-model="show_word_templates" max-width="600px">
      <v-card>
        <v-card-title>Modelli Word
          <v-spacer></v-spacer>
          <v-btn @click="show_word_templates = false" fab x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          Numero clienti selezionati: {{customers_selected.length}}
          <WordTemplatesList v-model="wordTemplateSelected" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" right
                 class="mr-2"
                 :disabled="wordTemplateSelected == null"
                 :loading="downloading > 0"
                 @click="downloadWordFile"
          >
            Scarica <v-icon right>mdi-download</v-icon>
          </v-btn>
          <v-btn color="secondary"  class="mr-2"
                 :disabled="wordTemplateSelected == null"
                 :loading="sending_email > 0"
                 @click="emailWordFile">
            Invia Email <v-icon right>mdi-email-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>

import WordTemplatesList from "@/components/custom/wordTemplates/wordTemplatesList.vue";
import mj_axios from "@/mj_axios";

export default {
  name: "wordTemplatesBtn",
  components: {WordTemplatesList},
  props:{
    customers_selected:{type:Array, default:()=>[]},
  },
  computed:{
    customerIds(){
      return this.customers_selected.map((customer) => customer.id);
    }
  },
  data:()=>({
    show_word_templates: false,
    wordTemplateSelected:null,
    downloading:0,
    sending_email:0,
  }),
  methods:{

    emailWordFile: function () {
      this.sending_email++;

      const axios_promise = mj_axios.post(`/api/word_template/${this.wordTemplateSelected?.id}/actions/replace_and_email?customer_ids=${JSON.stringify(this.customerIds)}`);
      axios_promise.then(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "L'invio dei documenti è stato inserito nella coda, verifica la ricezione!",
          visible: true,
        });
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di invio, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.sending_email--;
      });
      return axios_promise;
    },
    downloadWordFile: function () {
      if (this.customers_selected.length < 1) {
        this.$store.commit("update_modal_dialog", {
          title: "Informazione!!",
          text: "Attenzione scegli almeno un cliente",
          visible: true,
        });
      } else {
        this.downloading++;

        const url = `/api/word_template/${this.wordTemplateSelected?.id}/actions/replace_and_download?customer_ids=${JSON.stringify(this.customerIds)}`;

        const filename = `${this.wordTemplateSelected.title}.${this.customerIds.length == 1 ? 'docx':'zip'}`;
        mj_axios.download(url, filename).then(() => this.downloading--)
            .catch( () => {
              this.$store.commit("update_modal_dialog", {
                title: "Errore!!",
                text: "Attenzione, formato del modello non valido o dati cliente, studio non identificati",
                visible: true,
              });
              this.downloading--;
            });
      }
    },
  }
}
</script>


<style scoped>

</style>