import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('MjTableItems',{ref:"table_transactions",attrs:{"loadItemsOnCreated":false,"model-name-prop":"Transaction","resource-collection-name":"TransactionResourceListCollection","base-query-filter":this.$route.params.customer_id ? [{name: 'customer_id',type: 'AND',val: [this.$route.params.customer_id],glue: 'and',}] : []},on:{"selectedChange":function (selected) { return _vm.transactions_selected = selected; }},scopedSlots:_vm._u([{key:"mj_filters_section",fn:function(props){return [_c('MjFilterGroup',{ref:"filter_group",staticClass:"mt-0 px-2",attrs:{"expand_filter":true},on:{"reset-all":function($event){_vm.$refs.table_transactions.filters = [];
            _vm.$refs.table_transactions.loadItems(true);}},scopedSlots:_vm._u([{key:"filters",fn:function(props_filters){
            var this$1 = this;
return [_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,[_c('MjDateIntervalFilter',{attrs:{"label-from":"Da","label-to":"A","time-model-format":"HH:mm:ss","time-display-format":"HH:mm","date-model-format":"YYYY-MM-DD","date-display-format":"DD/MM/YYYY","value-from":_vm.start_of_current_month,"value-to":_vm.end_of_current_month,"mode":"date","glue":"and","silent_reset":props_filters.reset_all,"filter_name":"date"},on:{"change":props.updateFilters}})],1)],1),(_vm.$route.params.customer_id == null)?_c(VRow,{attrs:{"align":"baseline"}},[_c(VCol,{attrs:{"cols":"9"}},[_c('MjComboBoxFilter',{attrs:{"label":"Cliente","load_items_url":"/api/list/Customer/CustomerResource4SelectCollection","filter_name":"customer_id","item_description_field":function (item) { return ("" + (item.business_name)); },"item_value_field":"id","glue":"and","priority":1,"silent_reset":props.reset_all},on:{"change":function (data) {_vm.current_customer_id_filter = data.val;this$1;_vm.updateFilters(data)}}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c('MjToggleFilter',{attrs:{"small":"","items_prop":[{text:'solo rate', value:1}],"item_description_field":"text","item_value_field":"value","filter_name":"is_yearly_installment","silent_reset":props.reset_all},on:{"change":function (data) {this$1;_vm.updateFilters(data)}}})],1)],1):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }