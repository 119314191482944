import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VTreeview,{class:_vm.size=='small' ? 'no_expand_icon' : '',attrs:{"shaped":"","search":_vm.search,"items":_vm.items,"dense":"","item-key":"id","item-children":"all_children","open-on-click":"","expand-icon":_vm.size=='small' ? '' : 'mdi-chevron-down'},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('router-link',{staticClass:"no_underline",attrs:{"to":(item.link) ? item.link : ''}},[_c(VListItem,[(!item.link)?_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,{attrs:{"large":_vm.size=="large"}},[_vm._v(_vm._s(open ? 'mdi-folder-open' : 'mdi-folder'))])],1):_c(VListItemIcon,{staticClass:"mr-3"},[_c(VIcon,{attrs:{"large":_vm.size=="large"}},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{class:[(_vm.size=="small") ? "subtitle-2" : "overline", "text-truncate" ]},[_vm._v(_vm._s(item.title))]),(item.sub_title)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VListItemSubtitle,_vm._g(_vm._b({staticClass:"text--secondary caption mt-1"},'v-list-item-subtitle',attrs,false),on),[_vm._v(_vm._s(item.sub_title))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.sub_title))])]):_vm._e()],1)],1)],1)]}}]),model:{value:(_vm.tree),callback:function ($$v) {_vm.tree=$$v},expression:"tree"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }