import mj_axios from "@/mj_axios";

const customerMixin = {

    data: function () {
        return {
            customers: [],
            loadingCustomers: 0,
        };
    },

    methods: {
        loadCustomers() {
            this.loadingCustomers++;
            const axios_promise = mj_axios.get('/api/logged-user/studio-jobs-customers');
            axios_promise.then(response => {
                this.customers = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento tipi clienti, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingCustomers--;
            });
            return axios_promise;
        },
    },

};

export default customerMixin;