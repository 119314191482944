import { render, staticRenderFns } from "./MjMyAccount.vue?vue&type=template&id=d20b0100&"
import script from "./MjMyAccount.vue?vue&type=script&lang=js&"
export * from "./MjMyAccount.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./MjMyAccount.vue?vue&type=custom&index=0&blockType=mj-my-account&%3Aadditional-form-fields=%7Bcognome%3A%7Btype%3A'string'%2Cval%3A''%7D%7D"
if (typeof block0 === 'function') block0(component)

export default component.exports