<template>
  <span>
 <v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                color="blue white--text"
                class="ml-2"
                @click="show_email_exporter = true"
            >
              <v-icon>mdi-email-multiple</v-icon>
            </v-btn>
          </template>
          <span>Esportazione Emails</span>
        </v-tooltip>
        <v-dialog v-model="show_email_exporter" max-width="600px">
      <v-card>
        <v-card-title class="small mb-4">Esporta Emails
          <v-spacer></v-spacer>
          <v-btn @click="show_email_exporter = false" fab x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ this.customerEmailList }}
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>
          <v-btn right small @click="copyCustomerEmailToClipboard" color="primary">
            Copia nella clipboard
            <v-icon class="ml-2" small>mdi-content-copy</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>


export default {
  name: "emailsExporterBtn",
  props: {
    customers_selected: {type: Array, default: () => []},
  },
  computed: {
    customerEmailList() {
      return this.customers_selected && this.customers_selected.length > 0 ?
          this.customers_selected.map(customer => {
            if (customer.Email) return customer.Email
          }).join(",") : null
    }
  },
  data: () => ({
    accounting_contract_pdf_sending:0,
    show_email_exporter: false,
  }),
  methods: {
    copyCustomerEmailToClipboard(){
      navigator.clipboard.writeText(this.customerEmailList)
    },
  }
}
</script>