//NEL BELONG COL NAME BISOGNA INDICARE IL NOME DELLA COLONNA NELLA TABELLA PIVOT CHE CONTIENE L'ALLEGATO
//ES. ALLEGATI MANY TO MANY ARTICOLI. DEVO INDICARE BELONG_COL_NAME = articolo_id IL VALORE VIENE PASSATO DAL MIXIN DOPO IL SALVATAGGIO AL METODO UPLOAD

//I METODI DEL COMPONENTE VENGONO CHIAMATI TRAMITE REFS DAL MIXIN SE VUOI USARLO CON EMIT O ALTRO VERIFICA CHE NON INTACCHI IL FUNZIONAMENTO SU SCHEDAMIXIN.JS

//Se ci sono problemi con la dropzone utilizza la gestione a contatore utilizzata in MjImage
<template>
  <v-container>
    <v-overlay absolute v-show="overlayVisible" class='mj_overlay'>
      <div class="d-flex flex-column align-center" justify="end">
        <v-btn elevation="2" fab color="primary">
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-btn>
        <v-btn color="secondary" class="mt-4">Trascina i file in questo riquadro per caricarli..</v-btn>
      </div>
    </v-overlay>
    tot files: {{ getTotalFilesCount() }}
    <div
        @drop.prevent="addDroppedFiles"
        @dragenter.prevent="dragEnter"
        @dragleave.prevent="dragLeave"
        @dragover.prevent
        class="dropzone"
    >
      <v-file-input

          class="dropzone"
          chips
          @change="this.addFilesByVInput"
          :multiple="multiple"
          label="Aggiungi File"
          show-size
          counter
          v-model="new_uploaded_files"
      />
      <v-card class="mx-auto my-4" tile>
        <v-list subheader dense>
          <v-subheader
          >Allegati Nel Database ({{
              this.db_uploaded_files.length
            }})
          </v-subheader
          >

          <v-list-item v-if="showLoader">
            <transition name="fade">
              <v-progress-linear
                  indeterminate
                  rounded
                  height="16"
              ></v-progress-linear>
            </transition>
          </v-list-item>

          <v-list-item
              v-for="(item, index) in this.db_uploaded_files"
              :key="item.id"
          >
            <v-list-item-avatar>
              <v-icon>mdi-paperclip</v-icon>
            </v-list-item-avatar>

            <v-list-item-content v-bind:class="{ strike: item.to_be_delete }">
              <v-list-item-title
                  v-text="
                  `${item.original_filename}  (${printHumanFileSize(
                    item.size
                  )})`
                "
              ></v-list-item-title>
              <v-list-item-subtitle class="caption">
                {{ item.descrizione }} / {{ item.nota }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-row justify="space-around">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="grey"
                        v-on="on"
                        icon
                        @click.stop="openEditDialog(item, index)"
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Info Allegato</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="accent"
                        v-on="on"
                        icon
                        @click="open(item)"
                    >
                      <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>
                  </template>
                  <span>Apri</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        fab
                        small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        @click="download(item, index)"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="danger"
                        v-on="on"
                        @click="setToBeDeleted(item, index)"
                        icon
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>Imposta per la cancellazione</span>
                </v-tooltip>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
      <v-snackbar v-model="card_error_show" app type="warning"
                  width="100%"
                  vertical="vertical">
        {{ card_error_message }}
        <v-btn color="accent" text @click="card_error_show = false"
        >Chiudi
        </v-btn
        >
      </v-snackbar>

      <v-dialog v-model="showInfoDialog" max-width="600">
        <v-card>
          <v-card-title>
            <span class="headline">Info File</span>
          </v-card-title>
          <v-card-subtitle class="mt-4">
            <div class="mt-2">
              <span>Nome File:</span>
              <div class="primary--text">
                {{
                  this.clicked_item ? this.clicked_item.original_filename : ""
                }}
              </div>
            </div>
            <div class="mt-2">
              Dimensione File:
              <div class="primary--text">
                {{
                  this.clicked_item
                      ? printHumanFileSize(this.clicked_item.size)
                      : ""
                }}
              </div>
            </div>
            <div class="mt-2">
              Nome sul disco:
              <div class="primary--text">
                {{ this.clicked_item ? this.clicked_item.storage_path : "" }}
              </div>
            </div>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      v-model="editing_item_descrizione"
                      rows="2"
                      counter="255"
                      label="Descrizione File"
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                      v-model="editing_item_nota"
                      rows="2"
                      counter="255"
                      label="Nota File"
                      hint="posizione cartaceo.."
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-snackbar v-model="dialog_error_show" app type="warning"
                      width="100%"
                      color="info"
                      vertical="vertical">
            {{ dialog_error_message }}
            <v-btn color="accent" text @click="dialog_error_show = false"
            >Chiudi
            </v-btn
            >
          </v-snackbar>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey"
                text
                @click="
                clicked_item = null;
                showInfoDialog = false;
              "
            >Chiudi
            </v-btn
            >
            <v-btn color="primary" text @click="saveAdditionalData()"
            >Salva
            </v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<style>

/*l'overlay non deve avere i pointer events altrimenti genera infiniti event dragenter/leave*/
.mj_overlay, .mj_overlay * {
  pointer-events: none;
}

/*rendere cursor pointer il componente per l'upload*/
.v-file-input__text {
  cursor: pointer !important;
}

/*style per indicare il file marcato per la cancellazione*/
.strike {
  text-decoration: line-through;
}
</style>
<script>
import mj_axios from "@/mj_axios.js";
import mj_string_utils from "@/misc/mj_string_utils.js";

export default {
  name: "MjUploader",
  props: {
    "belong_col_name": {type: String, required: true},
    "belong_api_url": {type: String, required: true},
    "multiple": {type: Boolean, default: () => true},
    "max_files": {type: Number, default: () => 10},
    "accepted_mime_types": {type: Array, required: false},
  },
  computed: {
    filesToBeDeleted() {
      return this.db_uploaded_files.filter(file => file.to_be_delete);
    },

  },
  data: () => ({
    overlayVisible: false,
    showLoader: false,
    api_url_allegato: "/api/allegato", //a differenza del belong api url questo è sempre fisso perchè nel template del progetto esiste allegato sempre.
    download_api_url: "/api/allegato_download", //anche questo è sempre fisso
    new_uploaded_files: [],
    db_uploaded_files: [],
    showInfoDialog: false,
    clicked_item: null, //indica l'item cliccato (usato in dialog)
    clicked_item_index: null, //indica l'index dell'array dei db_uploaded_files necessario per identificare velocemente l'elemento nell'array per aggiornamenti
    dialog_error_show: false,
    dialog_error_message: "",
    card_error_show: false,
    card_error_message: "",
    editing_item_descrizione: "",
    editing_item_nota: "",
  }),
  created() {
  },
  methods: {
    getTotalFilesCount() {
      return this.db_uploaded_files.length + this.new_uploaded_files.length - this.filesToBeDeleted.length;
    },
    dragEnter(e) {
      if (e.target.classList && e.target.classList.contains("dropzone")) {
        this.overlayVisible = true;
      }
    },
    dragLeave(e) {
      if (e.target.classList && e.target.classList.contains("dropzone")) {
        this.overlayVisible = false;
      }
    },
    addDroppedFiles(e) {
      this.dialog_error_show = false;
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;

      // this tip, convert FileList to array
      [...droppedFiles].forEach((f) => {
        this.new_uploaded_files.push(f);
      });


      if (!this.validateVFileInput()) {
        [...droppedFiles].forEach(() =>
            this.new_uploaded_files.pop());
        this.card_error_show = true;
      }
      this.overlayVisible = false;
    },
    reset() {
      this.showInfoDialog = false;
      this.new_uploaded_files = [];
      this.db_uploaded_files = [];
      this.showLoader = false;
    },
    printHumanFileSize(size) {
      if (size != null) {
        return mj_string_utils.humanFileSize(size);
      } else {
        return "N.D.";
      }
    },
    load(belong_id_value) {
      return new Promise((resolve, reject) => {
        this.showLoader = true;

        //parametri get per axios
        const config = {};
        config.params = {};
        config.params[this.belong_col_name] = belong_id_value; //?belong_col_name=belong_id_value
        //fine parametri

        mj_axios
            .get(this.belong_api_url, config)
            .then((data) => {
              this.db_uploaded_files = data.data.data;
              resolve();
            })
            .catch((e) => {
              reject(e);
            })
            .then(() => {
              this.showLoader = false;
            });
      });
    },

    setToBeDeleted(item, index) {
      //segno l'item da cancellare, l'effettiva cancellazione sarà effettuata in fase di salvataggio.
      item.to_be_delete = !item.to_be_delete;
      this.$set(this.db_uploaded_files, index, item);
    },
    //single id or array
    upload(belong_ids_value) {
      return new Promise((resolve, reject) => {
        if (this.new_uploaded_files) {
          let formData = new FormData();

          // files
          for (let file of this.new_uploaded_files) {
            formData.append("files[]", file, file.name);
          }

          if (Array.isArray(belong_ids_value)) {
            belong_ids_value = JSON.stringify(belong_ids_value);
          }

          formData.append(this.belong_col_name, belong_ids_value);

          mj_axios
              .post(this.belong_api_url, formData)
              .then(() => {
                this.new_uploaded_files = [];
                resolve();
              })
              .catch((e) => {
                reject(e);
              });
        }
      });
    },
    deleteSelected(belong_id_value) {
      return new Promise((resolve, reject) => {

        const ids = this.filesToBeDeleted.map(function (item) {
          return item.id;
        });

        this.showLoader = true;
        mj_axios
            .delete(
                `${this.belong_api_url}/${JSON.stringify(ids)}?${
                    this.belong_col_name
                }=${belong_id_value}`
            )
            .then(() => {
              resolve();
            })
            .catch((e) => {
              reject(e);
            })
            .then(() => {
              //ricarico dal db - meglio di eliminare gli item in quanto non ho riscontro di quale effettivamente cancellato e quale magari và in errore
              this.load(belong_id_value);
              this.showLoader = false;
            });
      });
    },
    download(item /*, index*/) {

      mj_axios.download(
          `${this.download_api_url}/${item.id}`,
          item.original_filename
      );
    },
    open(item) {
      mj_axios.open(`${this.download_api_url}/${item.id}`, "_blank");
    },
    openEditDialog(item, index) {
      this.clicked_item = item;
      this.clicked_item_index = index;
      this.editing_item_descrizione = this.clicked_item.descrizione;
      this.editing_item_nota = this.clicked_item.nota;
      this.showInfoDialog = true;
    },
    saveAdditionalData() {
      const params = {
        descrizione: this.editing_item_descrizione,
        nota: this.editing_item_nota,
      };
      const axios_promise = mj_axios.put(
          this.api_url_allegato + "/" + this.clicked_item.id,
          params
      );

      axios_promise
          .then(() => {
            //aggiorno l'item sull'array con le nuove info
            const item = this.db_uploaded_files[this.clicked_item_index];
            item.descrizione = this.editing_item_descrizione;
            item.nota = this.editing_item_nota;

            this.clicked_item = null;
            this.clicked_item_index = null;
            this.showInfoDialog = false;
          })
          .catch((e) => {
            let message =
                e.response && e.response.data ? e.response.data.message : "";

            this.dialog_error_message = "Errore nel Salvataggio " + message;
            this.dialog_error_show = true;
          });
    },
    addFilesByVInput() {
      if (!this.validateVFileInput()) {
        this.new_uploaded_files.pop();
        this.card_error_show = true;
      }
    },
    validateVFileInput() {
      if (this.accepted_mime_types) {
        const unsupportedMimeTypes = this.new_uploaded_files.filter((file) => !this.accepted_mime_types.includes(file.type))
        if (unsupportedMimeTypes.length > 0) {
          this.card_error_message = `I formati supportati sono ${this.accepted_mime_types.join(",")}`;
          return false;
        }
      }
      if (this.getTotalFilesCount() > this.max_files) {
        this.card_error_message = `Non puoi caricare più di ${this.max_files} files`;
        return false;
      }
      return true;

    },
  },
};
</script>