<template>
  <v-container fluid>
    <MjTableItems
        ref="table_customers"
        model-name-prop="Customer"
        resource-collection-name="CustomerResourceListCollection"
        @selectedChange="(selected) => this.customers_selected = selected"
        sort_by="Ragione Sociale"
        :loadItemsOnCreated="false"
    >
      <template v-slot:mj_filters_section="">
        <MjFilterGroup
            ref="filter_group"
            :expand_filter="true"
            class="mt-6 px-2"
            @reset-all="
            $refs.table_customers.filters = [];
            $refs.table_customers.loadItems(true);"
        >
          <template v-slot:filters="{reset_all}">
            <v-row align="baseline" v-if="$route.params.customer_id == null">
              <v-col cols="6">
                <MjComboBoxFilter
                    label="Forma Giuridica"
                    load_items_url="/api/list/LegalStatus/LegalStatusResourceListCollection"
                    filter_name="legal_status_id"
                    :item_description_field="(item) => `${item.Cod} - ${item.Descrizione}`"
                    item_value_field="id"
                    glue="and"
                    :priority="1"
                    @change="(data) => {this;updateFilters(data)}"
                    :silent_reset="reset_all"
                />
              </v-col>
              <v-col cols="6">
                <MjToggleFilter :small="true"
                                item_description_field="description" item_value_field="value"
                                :items_prop="[{description:'Cessati', value:1, icon:'mdi-close-octagon-outline',color:'red'},{description:'Attivi', value:0, icon:'mdi-check',color:'green'}]"
                                :selected_prop="0"
                                filter_name="ceased"
                                glue="and"
                                @change="(data) => {this;updateFilters(data)}"
                                :silent_reset="reset_all"
                />
              </v-col>
            </v-row>
          </template>
        </MjFilterGroup>
      </template>
      <template v-slot:mj_top_toolbar_prepend="{selected,selected_ids}">
        <span v-if="selected.length > 0">

        <collaboratorLinkerBtn :selected_ids="selected_ids" @close-popup=" $refs.table_customers.loadItems(true);"
        relationName="CollaboratorAccounting" childColName="collaborator_accounting_id" btnColor="primary"
                               tooltipText="Associa collaboratori per Contabilità"
        />

          <collaboratorLinkerBtn :selected_ids="selected_ids" @close-popup=" $refs.table_customers.loadItems(true);"
                                 relationName="CollaboratorStudioJobs" childColName="collaborator_studio_jobs_id" btnColor="blue"
                                 tooltipText="Associa collaboratori per Adempimenti"
          />

        <installment-generator-btn :customers_selected="selected" />
        <yearlyAccountingAmountBtn :customers_selected="selected" @saved=" $refs.table_customers.loadItems(true);"/>
        <downloadContractHTMLBtn :customers_selected="selected"/>
        <downloadContractPDFBtn :customers_selected="selected"/>
        <addTransactionBtn :customers_selected="selected"/>
        <downloadAccountBalancePDFBtn :customers_selected="selected"/>
        <emailAccountBalancePDFBtn :customers_selected="selected"/>
        <emailContractPDFBtn :customers_selected="selected"/>
        <downloadLetteraBonificoPDFBtn :customers_selected="selected"/>
        <annualBalancerBtn :customers_selected="selected"/>
        <emailsExporterBtn :customers_selected="selected"/>
        <wordTemplatesBtn :customers_selected="selected"/>
           
          </span>
      </template>
      <template v-slot:mj_left_item_actions="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mr-2" small fab v-bind='attrs' v-on='on'
                   @click.stop='$router.push({path:`/table/Transaction/${item.id}`})'>
              <v-icon>
                mdi-account-cash
              </v-icon>
            </v-btn>
          </template>
          <span>Estratto Conto</span>
        </v-tooltip>

      </template>

    </MjTableItems>


  </v-container>
</template>
<style>
.generated_report tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>
<script>

import MjTableItems from "@/components/MjTableItems";
import MjComboBoxFilter from "@/components/filters/MjComboBoxFilter";
import MjFilterGroup from "@/components/filters/MjFilterGroup";
import MjToggleFilter from "@/components/filters/MjToggleFilter.vue";

import collaboratorLinkerBtn from "@/components/custom/customerTools/collaboratorLinkerBtn.vue";
import yearlyAccountingAmountBtn from "@/components/custom/customerTools/yearlyAccountingAmountBtn.vue";
import downloadContractPDFBtn from "@/components/custom/customerTools/downloadContractPDFBtn.vue";
import downloadContractHTMLBtn from "@/components/custom/customerTools/downloadContractHTMLBtn.vue";
import downloadAccountBalancePDFBtn from "@/components/custom/customerTools/downloadAccountBalancePDFBtn.vue";
import addTransactionBtn from "@/components/custom/customerTools/addTransactionBtn.vue";
import emailAccountBalancePDFBtn from "@/components/custom/customerTools/emailAccountBalancePDFBtn.vue";
import emailContractPDFBtn from "@/components/custom/customerTools/emailContractPDFBtn.vue";
import downloadLetteraBonificoPDFBtn from "@/components/custom/customerTools/downloadLetteraBonificoPDFBtn.vue";
import annualBalancerBtn from "@/components/custom/customerTools/annualBalancerBtn.vue";
import emailsExporterBtn from "@/components/custom/customerTools/emailsExporterBtn.vue";
import wordTemplatesBtn from "@/components/custom/customerTools/wordTemplatesBtn.vue";
import installmentGeneratorBtn from "@/components/custom/customerTools/installmentGeneratorBtn.vue";

export default {
  name: "TableCustomers",
  components: {
    MjFilterGroup, MjComboBoxFilter, MjTableItems,
    collaboratorLinkerBtn, MjToggleFilter,
    yearlyAccountingAmountBtn, downloadContractPDFBtn, downloadContractHTMLBtn,
    downloadAccountBalancePDFBtn, emailAccountBalancePDFBtn, addTransactionBtn,
    emailContractPDFBtn, downloadLetteraBonificoPDFBtn, annualBalancerBtn, emailsExporterBtn,
    wordTemplatesBtn,installmentGeneratorBtn
  },

  data: () => ({
    customers_selected: null,
  }),
  methods: {
    updateFilters: function (data) {
      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs.table_customers.updateFilters(data);

      })
    },
  },
};
</script>
