import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.dialog_show),callback:function ($$v) {_vm.dialog_show=$$v},expression:"dialog_show"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"secondary"}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-information")])],1),_c(VToolbarTitle,[_vm._v("Info")]),_c(VSpacer),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VCardTitle,[_vm._v("Hai già fatto il login: "+_vm._s(_vm.$store.state.token_obj.email))]),_c(VCardText,[_vm._v("Non puoi visualizzare questa pagina perchè hai già fatto il login. "),_c('br'),_vm._v(" Se sei qui per sbaglio vai alla Home"),_c('br'),_vm._v(" Altrimenti effettua il logout per visualizzare la pagina richiesta. ")]),_c(VCardActions,[_c(VBtn,{staticClass:"mx-2 light",attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.$router.replace('/Menu')}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-home")]),_vm._v(" Home ")],1),_c(VBtn,{staticClass:"mx-2 light",attrs:{"small":"","outlined":""},on:{"click":_vm.logout}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-logout-variant")]),_vm._v(" Logout ")],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }