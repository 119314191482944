<!--
  Il filtro per forma giuridica è client-side.
  Si può portare server-side, ma bisogna rivedere tutti i controller e query.
  Vista la mole di dati "leggera" si preferisce lasciare il filtraggio lato client.
-->
<template>
  <v-container fluid>
    <v-row justify="space-between" class="align-baseline">
      <v-col cols="10"><h4 class="mb-2 text--primary">Dashboard Adempimenti</h4></v-col>

      <v-col class="text-right">
        <v-btn fab x-small @click="loadAll()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <div class="smaller my-1" v-if="loadingCustomers > 0">
      <v-progress-circular size="20" class="mr-2" striped indeterminate color="primary"
      ></v-progress-circular>
      Caricamento Clienti
    </div>
    <div class="smaller  my-1" v-if="loadingJobs > 0">
      <v-progress-circular size="20" class="mr-2" indeterminate color="secondary"
      ></v-progress-circular>
      Caricamento Adempimenti
    </div>
    <div class="smaller  my-1" v-if="loadingCustomerTaskPerJobCount>0">
      <v-progress-circular size="20" class="mr-2" indeterminate
                           color="green"></v-progress-circular>
      Caricamento Dati
    </div>


<v-row class="align-baseline">
  <v-col cols="2" >
    <v-text-field
        class="anno_field"
        label="Anno"
        v-model="year"
        @change="loadCustomerTaskPerJobCount"
    >
    </v-text-field>
  </v-col>
  <v-col>
    <MjComboBoxFilter
        dense
        label="Forma Giuridica"
        load_items_url="/api/list/LegalStatus/LegalStatusResourceListCollection"
        filter_name="legal_status_id"
        :item_description_field="(item) => `${item.Cod} - ${item.Descrizione}`"
        item_value_field="id"
        glue="and"
        :priority="1"
        @change="(data) => updateLegalStatusFilter(data)"
    />
  </v-col>
  <v-col v-if="$store.getters.isAdmin">
    <MjComboBoxFilter
        dense
        label="Collaboratore"
        @change="(data) => selectedCollaboratorIds = data.val"
        load_items_url="/api/collaborators"
        filter_name="collaborator_studio_jobs_id"
        :item_description_field="(item) => `${item['name']}`"
        item_value_field="id"
        glue="and"
        :priority="1"
    />
  </v-col>
</v-row>
    <v-simple-table dense class="job_table mt-12" fixed-header height="600">
      <thead class="small font-weight-thin grey--text">
      <tr>
        <th class="customer_th">Cliente</th>
        <th class="job_th" v-for="job in jobs" :key="job.id">
          <router-link :to="{ name: 'SchedaStudioJob', params: { id: job.id }}">
            {{ job.cod }}<br> {{ job.name }}
          </router-link>
        </th>
      </tr>
      </thead>
      <tbody>
      <template v-for="customer in customers" >
      <tr v-if="(selectedLegalStatusIds == null || selectedLegalStatusIds.length == 0
      || selectedLegalStatusIds.includes(customer.legal_status_id)) && (
        selectedCollaboratorIds == null || selectedCollaboratorIds.length == 0
        || selectedCollaboratorIds.includes(customer.collaborator_studio_jobs_id)
      )"
          :key="customer.id">
        <td class="customer_td" :title="customer.business_name">
          {{ customer.business_name }}
          <span class="text--disabled">{{customer.legal_status ? customer.legal_status.cod: 'N.D.'}}</span>
        </td>
        <td class="job_td" :class="getJobStatusClass(customer,job)" v-for="job in jobs" :key="job.id"
            @click="showStudioJobDialog(customer,job)">
          <v-progress-circular v-if="loadingCell[customer.id+'#'+job.id]" indeterminate size="21"></v-progress-circular>
          <div v-else>

            {{
              customerTaskPerJobCount[customer.id.toString()] && customerTaskPerJobCount[customer.id.toString()][job.id.toString()] ? customerTaskPerJobCount[customer.id.toString()][job.id.toString()] : '0'
            }}/
            {{ job.taskCount }}
          </div>

        </td>
      </tr>
      </template>
      </tbody>
    </v-simple-table>
    <v-dialog v-model="showJobDialog" width="600px">
      <StudioJobStatus
          :customer="currentCustomer"
          :job="currentJob"
          :year="this.year"
          @close-dialog="() => {showJobDialog=false; loadSingleCustomerTaskPerJobCount(currentCustomer,currentJob)}"
      ></StudioJobStatus>
    </v-dialog>
  </v-container>
</template>
<style scoped>

.job_table {
  border-collapse: collapse;
  overflow-x: auto;
}

.job_table td, .job_table th {
  border: solid thin #eee !important;
  min-width: 100px !important;
}

.job_table thead th {
  padding-left: 10px;
  border-bottom: solid 1px #eee;
  padding-bottom: 10px;
}

.customer_th {
  width: 8%;
  text-align: left;
}

.job_th {
  text-align: center;
}

.job_td {
  text-align: center;

}

.customer_td {

  height: 40px ;
  overflow: hidden;
  max-width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
<script>
import mj_axios from "@/mj_axios";
import StudioJobStatus from "./StudioJobStatus";
import MjComboBoxFilter from "@/components/filters/MjComboBoxFilter";
import CustomerMixin from "../../mixins/entities/CustomerMixin";

export default {
  name: "DashboardJobs",
  components: {StudioJobStatus,MjComboBoxFilter},
  mixins: [CustomerMixin],
  mounted() {
    this.loadAll();
  },
  data: () => ({
    loadingCell: {},
    showJobDialog: false,

    loadingJobs: 0,
    loadingCustomerTaskPerJobCount: 0,
    currentCustomer: null,
    currentJob: null,

    selectedLegalStatusIds:[],
    jobs: [],
    customerTaskPerJobCount: [],
    year: new Date().getFullYear(),
    selectedCollaboratorIds: null,
  }),
  methods: {
    loadAll: function () {
      this.loadCustomers();
      this.loadJobs();
      this.loadCustomerTaskPerJobCount();

    },

    loadJobs: function () {
      this.loadingJobs++;
      this.jobs = [];
      const axios_promise = mj_axios.get('/api/studio_jobs');
      axios_promise.then(response => {
        this.jobs = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento Adempimenti, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingJobs--;
      });
      return axios_promise;
    },
    loadCustomerTaskPerJobCount: function () {
      this.loadingCustomerTaskPerJobCount++;
      this.customerTaskPerJobCount = [];
      const axios_promise = mj_axios.get(`/api/customer_task_per_job_count?year=${this.year}`);
      axios_promise.then(response => {
        this.customerTaskPerJobCount = response.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.loadingCustomerTaskPerJobCount--;
      });
      return axios_promise;
    },
    loadSingleCustomerTaskPerJobCount: function (customer, job) {

      this.loadingCell[customer.id + '#' + job.id] = 1;
      this.$set(this.loadingCell, customer.id + '#' + job.id, true);
      const axios_promise = mj_axios.get(`/api/customer_task_per_job_count?customer_id=${customer.id}&studio_job_id=${job.id}&year=${this.year}`);
      axios_promise.then(response => {

        const json = response.data;
        const jobId = Object.keys(json)[0];
        const jobCount = json[jobId];
        if (jobCount)
          this.customerTaskPerJobCount[customer.id][jobId] = jobCount;
        else
        {
          delete this.customerTaskPerJobCount[customer.id][job.id];
        }
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Attenzione!!",
          text: "Errore di caricamento loadSingleCustomerTaskPerJobCount, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });
      }).then(() => {
        this.$set(this.loadingCell, customer.id + '#' + job.id, false);
        this.$forceUpdate();
      });
      return axios_promise;
    },
    getJobStatusClass: function (customer, job) {
      const taskDoneCount = (this.customerTaskPerJobCount[customer.id] && this.customerTaskPerJobCount[customer.id][job.id]) ? this.customerTaskPerJobCount[customer.id][job.id] : 0;
      const taskTotal = job.taskCount;
      if (taskTotal == 0) return 'grey';
      return (taskDoneCount == taskTotal) ? "green" : (taskDoneCount == 0) ? 'red' : 'orange';
    },
    showStudioJobDialog: function (customer, job) {
      this.currentCustomer = customer;
      this.currentJob = job;
      this.showJobDialog = true;
    },
    updateLegalStatusFilter: function(data){

      if (data != null)
      {this.selectedLegalStatusIds = data.val;}
    }

  },


}
</script>
<style>
.anno_field.v-input input{
  margin-bottom: 3px !important; ;
}
</style>
