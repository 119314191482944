import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"white--text ml-2",attrs:{"fab":"","small":"","loading":_vm.pdf_downloading > 0,"color":"red"},on:{"click":function($event){_vm.show_account_balance_pdf_dialog = true;}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(_vm._s(_vm.customers_selected.length == 1 ? 'mdi-file-table' : 'mdi-folder-zip-outline'))])],1)]}}])},[_c('span',[_vm._v("Genera Estratto Conto")])]),_c(VDialog,{attrs:{"max-width":"300px"},model:{value:(_vm.show_account_balance_pdf_dialog),callback:function ($$v) {_vm.show_account_balance_pdf_dialog=$$v},expression:"show_account_balance_pdf_dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"small mb-4"},[_vm._v("Scarica Estratti Conto "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.show_account_balance_pdf_dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_c('MjDateOrTimePicker',{attrs:{"label":"Da","model_format":"YYYY-MM-DD","mode":"date","display_format":"DD-MM-YYYY"},model:{value:(_vm.account_balance_from),callback:function ($$v) {_vm.account_balance_from=$$v},expression:"account_balance_from"}}),_c('MjDateOrTimePicker',{attrs:{"label":"A","model_format":"YYYY-MM-DD","mode":"date","display_format":"DD-MM-YYYY"},model:{value:(_vm.account_balance_to),callback:function ($$v) {_vm.account_balance_to=$$v},expression:"account_balance_to"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red white--text","loading":_vm.pdf_downloading > 0},on:{"click":_vm.downloadPDF}},[_vm._v("Scarica PDF")]),_c(VBtn,{attrs:{"color":"blue darken-4 white--text","loading":_vm.html_downloading > 0},on:{"click":_vm.downloadHTML}},[_vm._v("Scarica DOC ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }