<template>
  <v-list outlined  two-line dense nav class="pa-0">
    <v-list-item-group v-model="selected" @change="$emit('input',wordTemplates[selected])" >
      <v-progress-linear indeterminate v-if="loading > 0"></v-progress-linear>
      <template v-for="(wordTemplate,index) in wordTemplates">
      <v-divider
          v-if="index > 0"
          :key="'divider'+ index"
      ></v-divider>
        <v-list-item :key="index" class="my-0">
        <v-list-item-avatar>
          <v-icon>mdi-microsoft-word</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{wordTemplate.title}}</v-list-item-title>
          <v-list-item-subtitle>descrizione</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>


import mj_axios from "@/mj_axios";

export default {
  name: "wordTemplatesList",
  mixins: [],
  props:{value:{default:()=>null}},
  mounted() {
    this.loadWordTemplates()
  },
  data: () =>
      ({
        selected: null,
        wordTemplates:[],
        loading:0
      }),
  methods:{
    loadWordTemplates(){
      this.loading++;
      const axios_promise = mj_axios.get('/api/word_templates');
      axios_promise.then(response => {
        this.wordTemplates = response.data.data;
      }).catch(() => {
        this.$store.commit("update_modal_dialog", {
          title: "Info!!",
          text: "Errore di caricamento, è consigliato, prima di proseguire, risolvere il problema.",
          visible: true,
        });

      }).then(() => {
        this.loading--;
      });
      return axios_promise;
    }
  },
  watch:{
    value:{
      immediate:true,
      handler(){this.selected = this.value;}
    }
  }
}
</script>

<style scoped>

</style>