<template>
  <v-container>

    <v-row>
      <v-btn class="mx-2 primary">Button Primary</v-btn>
      <v-btn class="mx-2">Button Default </v-btn>
      <v-btn class="mx-2 secondary">Button Secondary</v-btn>
    </v-row>
    <v-row class="mt-2">
      <!-- Apre il dialog costruito dentro questa View -->
      <v-btn class="mx-2 mt-1" @click="show_dialog = true">Open Dialog</v-btn>
      <!-- Apre il dialog comune che si trova dentro App -->
      <v-btn class="mx-2 mt-1" color='green' @click="showStoreDialog()">Open Store Dialog</v-btn>

      <v-btn class="mx-2 mt-1 info" @click="snackbar_info = true"
        >Snackbar Info</v-btn
      >
      <v-btn class="mx-2 mt-1 warning" @click="snackbar_warning = true"
        >Snackbar Warning</v-btn
      >
      <v-btn class="mx-2 mt-1 danger" @click="snackbar_danger = true"
        >Snackbar Danger</v-btn
      >
    </v-row>
     
      <div class='mt-3 mx-2 caption'>caption</div>
      <div class='mx-2 overline'>overline</div>
      <div class='mx-2 subtitle-1'>subtitle-1</div>
      <div class='mx-2 subtitle-2'>subtitle-2</div>
      <a href='#'>Questo è un link a HREF</a>
    
    <v-row class="mt-3">
      <v-alert class="mx-2" type="info" prominent>Alert Info</v-alert>
      <v-alert class="mx-2" type="warning" prominent>Alert Warning</v-alert>
      <v-alert class="mx-2" type="error" prominent>Alert Error</v-alert>
    </v-row>
    <v-snackbar
      app
      v-model="snackbar_info"
      type="info"
      width="100%"
      color="info"
      vertical="vertical"
      >snackbar info</v-snackbar
    >
    <v-snackbar
      app
      v-model="snackbar_warning"
      type="warning"
      width="100%"
      color="warning"
      vertical="vertical"
      >snackbar warning</v-snackbar
    >
    <v-snackbar
      app
      v-model="snackbar_danger"
      type="danger"
      width="100%"
      color="danger"
      vertical="vertical"
      >snackbar danger</v-snackbar
    >

    <MjConfirmDialog 
      v-model="show_dialog"
      @confirm-from-dialog="show_dialog = false;"
      @cancel-from-dialog="show_dialog = false"
      dialog_title="Info Dialog Test"
      dialog_text="Dialog definito dentro TestView.vue"/>
  </v-container>
</template>

<script>
import store from "@/store.js";
import MjConfirmDialog from "@/components/MjConfirmDialog.vue";

export default {
  name: "TestView",
  components: { MjConfirmDialog},
  store,
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build

  computed: {},
  data: () => ({
    snackbar_danger: false,
    snackbar_info: false,
    snackbar_warning: false,
    show_dialog: false,
  }),

  methods: {
    
    showStoreDialog: function () {
      this.$store.commit("update_modal_dialog", {
        title: "Informazione",
        text: "App Dialog definito dentro App.vue e controllato tramite vuex",
        visible: true,
      });
    },
  },
  watch: {},
};
</script>
