<template>
  <v-container>
    <v-overlay :value="loading_overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-container>
        <v-row  class="align-baseline">
          <v-col md="2"><h4>Cliente ID: {{ $route.params.id }}</h4> </v-col>
          <v-col md="3">
            <v-autocomplete
              prepend-icon="mdi-account-hard-hat"
              label='Collaboratore Contabilità'
              v-model='form.collaborator_accounting_id.val'
              item-text="name" item-value="id" :items="this.selects.collaborator_accounting_id" cache-items
          >
          </v-autocomplete>
          </v-col>
            <v-col md="3">
              <v-autocomplete
                prepend-icon="mdi-account-hard-hat"
                label='Collaboratore Adempimenti'
                v-model='form.collaborator_studio_jobs_id.val'
                item-text="name" item-value="id" :items="this.selects.collaborator_studio_jobs_id" cache-items
            >
            </v-autocomplete>
            </v-col>

            <v-col md="2">
                <v-switch label="Cessato" v-model="form.ceased.val" :true-value="1" :false-value="0"></v-switch>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Cod'
                v-model='form.cod.val'
                :rules="[rules.max(16)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='5'>
            <v-text-field
                label='Ragione sociale'
                v-model='form.business_name.val'
                :rules="[rules.required,rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-autocomplete
                prepend-icon="mdi-account-tie"
                label='Rappresentante legale'
                v-model='form.legal_representative_id.val'
                :rules="[rules.required]"
                :item-text="(legalRepresentative) => legalRepresentative.name+' '+legalRepresentative.surname" item-value="id" :items="this.selects.legal_representative_id" cache-items
            >
            </v-autocomplete>
          </v-col>
          </v-row>
        <v-row>
          <v-col cols='12' md='4'>
            <v-autocomplete
                prepend-icon="mdi-scale-balance"
                label='Forma Giuridica'
                v-model='form.legal_status_id.val'
                :item-text="(legal_status) => `${legal_status.cod} - ${legal_status.description}`" item-value="id" :items="this.selects.legal_status_id" cache-items
            >
            </v-autocomplete>
          </v-col>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Partita iva'
                v-model='form.vat_id.val'
                :rules="[rules.piva,rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Codice fiscale'
                v-model='form.fiscal_code.val'
                :rules="[rules.cf,rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='2'>
            <v-text-field
                label='Codice SDI'
                v-model='form.sdi_code.val'
                :rules="[rules.max(7)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols='12' md='7'>
            <v-text-field
                label='Indirizzo'
                v-model='form.address.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='3'>
            <v-text-field
                label='Citta'
                v-model='form.city.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='2'>
            <v-text-field label='Cap' v-model='form.postal_code.val' ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='6'>
            <v-text-field
                label='Email'
                v-model='form.email.val'
                :rules="[rules.required, rules.email,rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='6'>
            <v-text-field
                label='Pec'
                v-model='form.pec.val'
                :rules="[rules.email,rules.max(255)]">
            </v-text-field>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Mobile number'
                v-model='form.mobile_number.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Telephone'
                v-model='form.telephone.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
          <v-col cols='12' md='4'>
            <v-text-field
                label='Fax'
                v-model='form.fax.val'
                :rules="[rules.max(255)]">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'>
            <v-textarea
                label='Note'
                v-model='form.note.val'
                rows='2'
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' >
            <MjToggleButtons label="Numero rate annue" small :items_prop="[{text:'Annuale',value:1},{text:'Semestrale',value:2},{text:'Trimestrale',value:4},{text:'Quadrimestrale',value:3},{text:'Mensile',value:12}]" v-model="form.yearly_number_of_installments.val" item_description_field="text" item_value_field="value" :mandatory="true" active_class="secondary"></MjToggleButtons>
          </v-col>
        </v-row>
        <v-row>

          <v-col cols='12' md='6'>
            <MjTextField
                label='Canone annuale contabilità'
                v-model='form.yearly_accounting_amount.val'
                type="money"
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                locale='it-IT'
                :rules="[rules.required]"
            >
            </MjTextField>
          </v-col>
          <v-col cols='12' md='6'>
            <MjTextField
                label='Costo Cedolino'
                type="money"
                v-model='form.cedolino_rate.val'
                decimal_separator=',' thousand_separator='.' max_fraction_digits='2' max_significant_digits='8'
                locale='it-IT'
                :rules="[rules.required]"
            >
            </MjTextField>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12' md='4'>
            <MjDateOrTimePicker
                label='Data acquisizione'
                v-model='form.acquisition_date.val'
                model_format='YYYY-MM-DD'
                mode='date'
                display_format='DD-MM-YYYY'
                :rules="[rules.required]">
            </MjDateOrTimePicker>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch v-model="form.money_laundering_module_av1_enable_default_values.val" :true-value="1" :false-value="0" label="av1 abilita" :persistent-hint="true" hint="se attivo i valori di default nel modulo antiriclaggio per la sezione av1 saranno precompilati"></v-switch>

          </v-col>
        </v-row>
        <MjSchedaToolbar
            :error_show="error_show"
            :error_text="error_text"
            :edit_not_saved="edit_not_saved"
            :edit_mode="editMode"
            :new_button_visibile="true"
            :restore_item_show="restore_item_show"
            v-on:restore-item-click="restoreItem()"
            v-on:new-item-click="newItem()"
            v-on:save-item-click="saveItem()"
            v-on:go-back-click="goBack()"
        />
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import schedaMixin from "@/mixins/SchedaMixin.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjSchedaToolbar from "@/components/MjSchedaToolbar.vue";
import MjTextField from '@/components/form/MjTextField.vue';
import MjDateOrTimePicker from "../../components/form/MjDateOrTimePicker";
import moment from "moment";
import MjToggleButtons from "../../components/form/MjToggleButtons";


export default {
  name: "SchedaCustomer",
  mixins: [schedaMixin, ValidationRulesMixin],
  components: {MjToggleButtons, MjSchedaToolbar, MjTextField, MjDateOrTimePicker},
  data: () => ({
    form: {
      cod: {type: 'string', val: '', default_val: ''},
      business_name: {type: 'string', val: '', default_val: ''},
      vat_id: {type: 'string', val: '', default_val: ''},
      fiscal_code: {type: 'string', val: '', default_val: ''},
      address: {type: 'string', val: '', default_val: ''},
      postal_code: {type: 'integer', val: '', default_val: ''},
      city: {type: 'string', val: '', default_val: ''},
      pec: {type: 'string', val: '', default_val: ''},
      telephone: {type: 'string', val: '', default_val: ''},
      fax: {type: 'string', val: '', default_val: ''},
      mobile_number: {type: 'string', val: '', default_val: ''},
      email: {type: 'string', val: '', default_val: ''},
      note: {type: 'text', val: '', default_val: ''},
      yearly_number_of_installments: {type: 'integer', val: '', default_val: '0'},
      yearly_accounting_amount: {type: 'decimal', val: '', default_val: '0'},
      cedolino_rate: {type: 'decimal', val: '', default_val: '0'},
      acquisition_date: {type: 'datetime', val: '', default_val: moment().format('YYYY-MM-DD')},
      money_laundering_module_av1_enable_default_values:{type:'integer', val:'', default_val:''},
      sdi_code:{type:'string', val:'', default_val:'0000000'},
        ceased:{type:'integer', val:'', default_val:1 },
      legal_representative_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/legal_representatives'
      },
      legal_status_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/legal_statuses'
      },
      collaborator_accounting_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/collaborators'
      },
      collaborator_studio_jobs_id: {
        type: 'integer',
        val: '',
        default_val: '',
        loadSelect: true,
        apiUrl: '/api/collaborators'
      },
    },
    apiUrl: "/api/customers"
  }),

  methods: {}
};
</script>
