import mj_axios from "@/mj_axios";

const studioMixin = {

    data: function () {
        return {
            loggedStudio: {},
            loadingLoggedStudio: 0,
            loadingMissingRequiredFields:0,
            missingRequiredFields:[],
            loadingStudios:0,
            studios:[],
        };
    },

    methods: {
        loadStudios() {
            this.loadingStudios++;
            const axios_promise = mj_axios.get('/api/studios');
            axios_promise.then(response => {
                this.studios = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento studio(s), è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingStudios--;
            });
            return axios_promise;
        },
        loadLoggedStudio() {
            this.loadingLoggedStudio++;
            const axios_promise = mj_axios.get('/api/studio_logged');
            axios_promise.then(response => {
                this.loggedStudio = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento studio loggato, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingLoggedStudio--;
            });
            return axios_promise;
        },
        getMissingRequiredFields() {
            this.loadingMissingRequiredFields++;
            const axios_promise = mj_axios.get('/api/loggedStudio/missingRequiredFields');
            axios_promise.then(response => {
                this.missingRequiredFields = response.data.data;
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: "Errore di caricamento missing required fields, è consigliato, prima di proseguire, risolvere il problema.",
                    visible: true,
                });
            }).then(() => {
                this.loadingMissingRequiredFields--;
            });
            return axios_promise;
        },

    },

};

export default studioMixin;