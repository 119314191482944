import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c(VDialog,{attrs:{"width":"500","fullscreen":_vm.fullscreen},model:{value:(_vm.dialog_show),callback:function ($$v) {_vm.dialog_show=$$v},expression:"dialog_show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.dialog_title))]),_c(VCardText,{staticClass:"pt-4 pb-4"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.dialog_text)}}),_vm._t("default")],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),(_vm.cancel_button_visible)?_c(VBtn,{attrs:{"color":"default","text":""},on:{"click":function($event){return _vm.$emit('cancel-from-dialog')}}},[_vm._v(_vm._s(_vm.cancel_button_text)+" ")]):_vm._e(),(_vm.confirm_button_visible)?_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.$emit('confirm-from-dialog')}}},[_vm._v(_vm._s(_vm.confirm_button_text)+" ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }