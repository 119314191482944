<template>
    <v-container class="text-center">
        <div v-show="finalizing" class="my-6">
            Sto finalizzando il pagamento, attendere qualche secondo..
        <v-progress-linear v-show="finalizing" indeterminate></v-progress-linear>
        </div>

        <div v-show="paymentCompleted" class="my-6">
            <h2 class="primary--text">Ben Fatto!</h2>
            Il pagamento è stato completato, il tuo abbonamento è valido fino al {{ subscription_expire_date }}
        </div>

        <v-btn to="Menu" color="primary">Vai al Menu</v-btn>

    </v-container>
</template>

<script>


import moment from "moment";
import mj_axios from "@/mj_axios";

export default {
    name: "PaymentReturnView",
    computed: {
        baseUrl() {
            return window.location.protocol + "://" + window.location.hostname + ":" + window.location.port + "/#"
        },
        subscription_expire_date() {
            return this.$store.state.token_obj.subscription_expire_date
                ? moment(this.$store.state.token_obj.subscription_expire_date, 'YYYY-MM-DD').format('DD-MM-YYYY')
                : "";
        },

    },
    data: () => ({
        finalizing: false,
        paymentCompleted: false,
    }),
    methods: {
        finalizePayment() {
            this.finalizing = true;
            const params = {
                "token": this.$route.query.token,
            };
            const axios_promise = mj_axios.post('/api/paypal/actions/finalize', params);
            axios_promise.then(response => {
                const status = response.data?.status;
                if (status === 'COMPLETED') {
                    this.paymentCompleted = true;
                    this.$store.dispatch('reloadLoggedUser');
                    this.$route.path.replace(this.$route.query.token, "");
                } else {
                    this.$store.commit("update_modal_dialog", {
                        title: "Attenzione!!",
                        text: `Errore di finalizzazione del pagamento , ${response.data?.errorMessage}`,
                        visible: true,
                    });
                }
            }).catch(() => {
                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: `Errore di inizializzazione del pagamento , è consigliato, prima di proseguire, risolvere il problema.`,
                    visible: true,
                });
            }).then(() => {
                this.finalizing = false;
            });
            return axios_promise;
        }
    },
    watch:{
        "$route.query.token":{
            immediate:true,
            handler(){
                if (this.$route.query.token)
                {

                    this.finalizePayment();
                }
            }
        }
    }
}
</script>

<style scoped>

</style>