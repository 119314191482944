import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VOverlay,{attrs:{"value":_vm.loading_overlay}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1),_c(VForm,{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VContainer,[_c('div',[_vm._v("Ruolo "+_vm._s(_vm.$route.params.id))]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"label":"Id Ruolo","disabled":"","required":""},model:{value:(_vm.form.id.val),callback:function ($$v) {_vm.$set(_vm.form.id, "val", $$v)},expression:"form.id.val"}})],1),_c(VCol,{attrs:{"cols":"12","md":"4"}},[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"Nome Ruolo","required":"","disabled":_vm.editMode},model:{value:(_vm.form.name.val),callback:function ($$v) {_vm.$set(_vm.form.name, "val", $$v)},expression:"form.name.val"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VCombobox,{attrs:{"item-text":"name","item-value":"id","items":_vm.selects.permissions,"deletable-chips":"","label":"Permessi","multiple":"","chips":""},model:{value:(_vm.form.permissions.val),callback:function ($$v) {_vm.$set(_vm.form.permissions, "val", $$v)},expression:"form.permissions.val"}})],1)],1),_c('MjSchedaToolbar',{attrs:{"error_show":_vm.error_show,"error_text":_vm.error_text,"edit_not_saved":_vm.edit_not_saved,"edit_mode":_vm.editMode,"new_button_visibile":true,"restore_item_show":_vm.restore_item_show},on:{"new-item-click":function($event){return _vm.newItem()},"save-item-click":function($event){return _vm.saveItem()},"goBackClick":function($event){return _vm.goBack()},"restoreItemClick":function($event){return _vm.restoreItem()}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }