<template>
    <v-container fluid>
        <MjTableItems
                :loadItemsOnCreated="false"
                ref="table_payment_log"
                model-name-prop="PaymentLog"
                resource-collection-name="PaymentLogResourceListCollection"
                :customEditItem="()=>{}"


        >
            <template slot="mj_filters_section"  slot-scope="props">

                <MjFilterGroup
                        ref="filter_group"
                        :expand_filter="true"
                        class="mt-0 px-2"
                        @reset-all="
            $refs.table_payment_log.filters = [];
            $refs.table_payment_log.loadItems(true);
          "
                >

                    <template slot="filters" slot-scope="props_filters">
                        <v-row no-gutters>
                            <v-col>
                                <MjDateIntervalFilter
                                        label-from="Da"
                                        label-to="A"
                                        time-model-format="HH:mm:ss"
                                        time-display-format="HH:mm"
                                        date-model-format="YYYY-MM-DD"
                                        date-display-format="DD/MM/YYYY"
                                        :value-from="start_of_current_month"
                                        :value-to="end_of_current_month"
                                        mode="date"
                                        glue="and"
                                        @change="props.updateFilters"
                                        :silent_reset="props_filters.reset_all"
                                        filter_name="created_at"
                                />
                            </v-col>
                        </v-row>

                    </template>
                </MjFilterGroup>

            </template>
        </MjTableItems>
    </v-container>
</template>
<style>

</style>
<script>

import MjTableItems from "@/components/MjTableItems";
import MjFilterGroup from "@/components/filters/MjFilterGroup";
import moment from "moment";
import MjDateIntervalFilter from "@/components/filters/MjDateIntervalFilter";

export default {
    name: "TablePaymentLog",
    components: {MjTableItems, MjFilterGroup, MjDateIntervalFilter},

    data: () => ({
        start_of_current_month: moment().startOf('month').format('YYYY-MM-DD'),
        end_of_current_month: moment().endOf('month').format('YYYY-MM-DD'),
        pdf_downloading: 0,

    }),
    methods: {

        updateFilters: function (data) {
            this.$nextTick(() => {
                // The whole view is rendered, so I can safely access or query
                // the DOM. ¯\_(ツ)_/¯
                this.$refs.table_transactions.updateFilters(data);

            })
        },

    },
    watch: {}
};
</script>