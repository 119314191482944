<template>
  <v-container fluid>
    <MjTableItems
        :loadItemsOnCreated="false"
        ref="table_transactions"
        model-name-prop="Transaction"
        resource-collection-name="TransactionResourceListCollection"
        @selectedChange="(selected) => transactions_selected = selected"
        :base-query-filter="this.$route.params.customer_id ? [{name: 'customer_id',type: 'AND',val: [this.$route.params.customer_id],glue: 'and',}] : []"
    >
      <template slot="mj_filters_section"  slot-scope="props">

        <MjFilterGroup
            ref="filter_group"
            :expand_filter="true"
            class="mt-0 px-2"
            @reset-all="
            $refs.table_transactions.filters = [];
            $refs.table_transactions.loadItems(true);
          "
        >

          <template slot="filters" slot-scope="props_filters">
            <v-row no-gutters>
              <v-col>
                <MjDateIntervalFilter
                    label-from="Da"
                    label-to="A"
                    time-model-format="HH:mm:ss"
                    time-display-format="HH:mm"
                    date-model-format="YYYY-MM-DD"
                    date-display-format="DD/MM/YYYY"
                    :value-from="start_of_current_month"
                    :value-to="end_of_current_month"
                    mode="date"
                    glue="and"
                    @change="props.updateFilters"
                    :silent_reset="props_filters.reset_all"
                    filter_name="date"
                />
              </v-col>
            </v-row>
            <v-row align="baseline"  v-if="$route.params.customer_id == null">
              <v-col cols="9">
                <MjComboBoxFilter
                    label="Cliente"
                    load_items_url="/api/list/Customer/CustomerResource4SelectCollection"
                    filter_name="customer_id"
                    :item_description_field="(item) => `${item.business_name}`"
                    item_value_field="id"
                    glue="and"
                    :priority="1"
                    @change="(data) => {current_customer_id_filter = data.val;this;updateFilters(data)}"
                    :silent_reset="props.reset_all"
                />
              </v-col>
              <v-col cols="3">
                <MjToggleFilter
                    small :items_prop="[{text:'solo rate', value:1}]" item_description_field="text" item_value_field="value"
filter_name="is_yearly_installment"
                    @change="(data) => {this;updateFilters(data)}"
                    :silent_reset="props.reset_all"
                >
                </MjToggleFilter>
              </v-col>
            </v-row>
          </template>
        </MjFilterGroup>

      </template>
    </MjTableItems>
  </v-container>
</template>
<style>

</style>
<script>

import MjTableItems from "@/components/MjTableItems";
import MjComboBoxFilter from "@/components/filters/MjComboBoxFilter";
import MjFilterGroup from "@/components/filters/MjFilterGroup";
import moment from "moment";
import MjDateIntervalFilter from "@/components/filters/MjDateIntervalFilter";
import MjToggleFilter from "../../components/filters/MjToggleFilter";

export default {
  name: "TableTransactions",
  components: {MjToggleFilter, MjTableItems,MjComboBoxFilter, MjFilterGroup, MjDateIntervalFilter},

  data: () => ({
    start_of_current_month: moment().startOf('month').format('YYYY-MM-DD'),
    end_of_current_month: moment().endOf('month').format('YYYY-MM-DD'),
    pdf_downloading: 0,

  }),
  methods: {

    updateFilters: function (data) {
      this.$nextTick(() => {
        // The whole view is rendered, so I can safely access or query
        // the DOM. ¯\_(ツ)_/¯
        this.$refs.table_transactions.updateFilters(data);

      })
    },

  },
  watch: {}
};
</script>
