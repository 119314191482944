<template>
    <v-container>
        <v-card elevation="0">
            <v-card-text>
                <v-row>
                    <v-col cols="6">
                        <v-autocomplete label="Anno" v-model="year" auto-select-first :items="years"
                                        :return-object="false"></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                        <v-autocomplete label="Mese" v-model="month" auto-select-first :items="months"
                                        item-value="value"
                                        :return-object="false"></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row align="baseline" v-for="customerRow in customerRows" :key="customerRow.customerId">
                    <v-col>
                        {{ customerRow.business_name }}
                    </v-col>

                    <v-col cols="3">
                        <v-text-field reverse label="numero cedolini" v-model="customerRow.cedoliniQuantity"
                                      type="number"></v-text-field>
                    </v-col>

                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="confirmButtonColor"
                       @click="sure ? insertCedolini() : confirmButtonText = 'Sicuro?'; sure=true; confirmButtonColor = 'warning'"
                       :loading="insertingCedolini > 0">{{ confirmButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import moment from "moment";
import mj_string_utils from "@/misc/mj_string_utils.js";
import mj_axios from "@/mj_axios";

export default {
    name: "CedoliniView",
    props: {
        customers: {},
    },
    computed: {
        years: function () {
            let years = [];
            const currentYear = new Date().getFullYear();
            for (let i = 50; i > 0; i--) {
                years.push(currentYear - i);
            }
            for (let i = 0; i < 50; i++) {
                years.push(currentYear + i);
            }
            return years;
        },
        months: function () {
            let months = [];

            for (let i = 1; i <= 12; i++) {
                const monthDescription = mj_string_utils.capitalize(moment().locale('it').set('month', i - 1).format('MMM'))
                months.push({'text': monthDescription, value: i});
            }
            return months;
        },

    },
    created() {

    },
    data: () => ({
        moment: moment,
        mj_string_utils: mj_string_utils,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),

        customerRows: [],
        cedolini_quantity: 0,
        insertingCedolini: 0,
        sure: false,
        confirmButtonText: "Inserisci",
        confirmButtonColor: "primary",
    }),
    methods: {
        insertCedolini: function () {
            this.insertingCedolini++;

            this.customerRows = this.customerRows.map((customer) => {
                customer.month = this.month;
                customer.year = this.year;
                return customer;
            });

            const params = {
                "customerData": JSON.stringify(this.customerRows)
            };
            const axios_promise = mj_axios.post('/api/cedolini', params);
            axios_promise.then(() => {
                this.$emit("saved", {});
            }).catch((e) => {
                let message =
                    e.response && e.response.data ? e.response.data.message : '';

                this.$store.commit("update_modal_dialog", {
                    title: "Attenzione!!",
                    text: `Errore di salvataggio ${message} , è consigliato, prima di proseguire, risolvere il problema.`,
                    visible: true,
                });
            }).then(() => {
                this.insertingCedolini--;
                this.confirmButtonText = "Inserisci";
                this.sure = false;
                this.confirmButtonColor = "primary"
            });
            return axios_promise;
        }
    },
    watch: {
        customers: {
            immediate: true,
            handler() {
                this.customerRows = this.customers.map((customer) => {
                    const customerRow = {};
                    customerRow.customerId = customer.id
                    customerRow.business_name = customer["Ragione Sociale"];

                    customerRow.cedoliniQuantity = 1;
                    return customerRow;
                });
            }
        }
    }
}
</script>

<style scoped>

</style>