import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8","lg":"8"}},[_c('h1',{staticClass:"text-center mb-2"},[_vm._v("Il mio account")]),_c('h3',{staticClass:"text-center mb-4"},[_vm._v("Modifica il tuo profilo..")]),_c('mj-my-account',{attrs:{"additional-form-fields":_vm.additionalFormFields},scopedSlots:_vm._u([{key:"additional_fields",fn:function(ref){
var form = ref.form;
return [_c(VTextField,{attrs:{"label":"Cognome"},model:{value:(form["cognome"]["val"]),callback:function ($$v) {_vm.$set(form["cognome"], "val", $$v)},expression:"form[\"cognome\"][\"val\"]"}}),_c(VTextField,{attrs:{"label":"Cellulare","rules":[_vm.rules.phone]},model:{value:(form["cellulare"]["val"]),callback:function ($$v) {_vm.$set(form["cellulare"], "val", $$v)},expression:"form[\"cellulare\"][\"val\"]"}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }