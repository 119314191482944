<template>
  <span>
  <v-tooltip bottom >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-bind="attrs"
          v-on="on"
          fab
          small
          color="orange white--text"
          class="ml-2"
          @click="show_installment_transactions_dialog = true; show_generated_report = false"
      >
        <v-icon>mdi-cash-multiple</v-icon>
      </v-btn>
    </template>
    <span>Genera Rate</span>
  </v-tooltip>
  <v-dialog v-model="show_installment_transactions_dialog" max-width="600px">
    <v-card>
      <v-card-title>Generatore Competenze
        <v-spacer></v-spacer>
        <v-btn @click="show_installment_transactions_dialog = false" fab x-small>
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container v-show="!show_generated_report">
          <div>Il generatore effettua le seguenti operazioni relativamente all'anno solare corrente</div>
          <ul class="mt-1">
            <li> Cancella, se presenti, eventuali competenze generate in automatico</li>
            <li> Inserisce le nuove competenze per i clienti selezionati</li>
          </ul>
          <div class="mt-2">
            Potrai visualizzare le competenze (crediti) generati nella sezione "Report Crediti e Incassi" utilizzando
            gli appositi filtri
          </div>
        </v-container>
        <v-container v-show="show_generated_report">
          <h4 class="text-center">Riepilogo Rate Generate</h4>
          <v-simple-table dense class="generated_report">
            <thead>
            <tr>
              <th>Cliente</th>
              <th>Data</th>
              <th>Importo</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="transaction in creditTransactionsGeneratedReport" :key="transaction.id">
              <td class="smaller">
                {{ transaction.customer["business_name"] }}
              </td>
              <td>
                {{ moment(transaction.date, "YYYY-MM-DD HH:mm:ss").format('DD-MM-YYYY') }}
              </td>
              <td>
                €{{ formatter.format(transaction.amount) }}
              </td>

            </tr>
            </tbody>
          </v-simple-table>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="loading_generate>0" @click="generateCreditTransactionForYearInstallment">
          Procedi con la Generazione
          <v-icon right>mdi-cash-multiple</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </span>
</template>
<script>


import moment from "moment";
import mj_axios from "@/mj_axios";

export default {
  name: "installmentGeneratorBtn",
  components: {},
  props:{
    customers_selected:{type:Array, default:()=>[]},
  },
  data:()=>({
    show_installment_transactions_dialog: false,
    show_generated_report: false,
    creditTransactionsGeneratedReport: [],
    loading_generate:0,
    formatter: new Intl.NumberFormat('it-IT', {
      useGrouping: true,
    }),
  }),
  methods:{
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },
    generateCreditTransactionForYearInstallment() {
      this.show_generated_report = false;
      this.loading_generate++;
      const customer_ids = this.customers_selected.map(customer => customer.id);
      const params = {customer_ids: customer_ids};
      const axios_promise = mj_axios.post('/api/generate_credit_transactions', params);
      axios_promise.then((response) => {
        this.creditTransactionsGeneratedReport = response.data;
        this.show_generated_report = true;
      })
          .catch(() => {
            this.show_generated_report = false;
            this.$store.commit("update_modal_dialog", {
              title: "Attenzione!!",
              text: "Errore di generazione, è consigliato, prima di proseguire, risolvere il problema.",
              visible: true,
            });
          }).then(() => {

        this.loading_generate--;
      });
      return axios_promise;
    },

  }
}
</script>


<style scoped>

</style>