import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VBtn,{staticClass:"mr-2",on:{"click":function($event){_vm.item_id = null; _vm.purchase_additional_data = null; _vm.price = 100; _vm.stripe_dialog_show = true}}},[_vm._v("Paga importo fisso: 100")]),_c('div',{staticClass:"my-4"},[_vm._v("oppure")]),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"placeholder":"id del prodotto da acquistare"},model:{value:(_vm.item_id),callback:function ($$v) {_vm.item_id=$$v},expression:"item_id"}})],1),_c(VCol,[_c(VBtn,{on:{"click":function($event){_vm.purchase_additional_data = {"n_partecipants":30,"note":"questa è una nota di prova","team_name":"MERCEDES STARS"};_vm.stripe_dialog_show = true}}},[_vm._v("Paga")])],1)],1),_c('MjStripeDialog',{attrs:{"price":_vm.price,"purchasing_item_id":_vm.item_id,"purchase_additional_data":_vm.purchase_additional_data,"dialog_title":"Test Pagamento Stripe"},on:{"payment-cancel":function($event){_vm.stripe_dialog_show=false},"payment-done":function($event){_vm.stripe_dialog_show=false}},model:{value:(_vm.stripe_dialog_show),callback:function ($$v) {_vm.stripe_dialog_show=$$v},expression:"stripe_dialog_show"}},[_c('div',{staticClass:"mb-6"},[_vm._v(" Inserisci i dati della tua carta per procedere al pagamento. "),_c(VDivider,{staticClass:"mt-6"}),_c('div',[_vm._v("Importo Richiesto "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.price/100)+" €")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }