<template>
  <v-container>

    <MjMenuTree size='large' api-url="/api/menu_items"/>
  </v-container>
</template>

<script>
import MjMenuTree from "@/components/MjMenuTree.vue";


export default {
  name: "Menu",
  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  components: { MjMenuTree},
 
};
</script>
