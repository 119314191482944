<template>
  <v-card flat outlined shaped >
    <v-card-subtitle class="overline py-0 border_bottom mb-3 primary white--text">Studio Info</v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col>
      <v-text-field readonly label="Fornitore" :value="loggedStudio.name"
                    persistent-hint
                    hint="I Dati del fornitore sono quelli presenti nella configurazione Studio, la configurazione è a carico di Super Amministratore"
                    :loading="loadingLoggedStudio > 0"
      >
      </v-text-field>

        </v-col>
      </v-row>
    </v-card-text>

  </v-card>
</template>

<script>
import StudioMixin from "@/mixins/entities/StudioMixin";

export default {
  name: "MjStudioCard",
  mixins: [StudioMixin],
  created() {
    this.loadLoggedStudio().then(()=>this.$emit('load',this.loggedStudio));
  }
}
</script>

<style scoped>

</style>