import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":"","color":"blue darken-2 white--text"},on:{"click":function($event){_vm.show_word_templates = true}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-microsoft-word")])],1)]}}])},[_c('span',[_vm._v("Modelli Word")])]),_c(VDialog,{attrs:{"max-width":"600px"},model:{value:(_vm.show_word_templates),callback:function ($$v) {_vm.show_word_templates=$$v},expression:"show_word_templates"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Modelli Word "),_c(VSpacer),_c(VBtn,{attrs:{"fab":"","x-small":""},on:{"click":function($event){_vm.show_word_templates = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,[_vm._v(" Numero clienti selezionati: "+_vm._s(_vm.customers_selected.length)+" "),_c('WordTemplatesList',{model:{value:(_vm.wordTemplateSelected),callback:function ($$v) {_vm.wordTemplateSelected=$$v},expression:"wordTemplateSelected"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","right":"","disabled":_vm.wordTemplateSelected == null,"loading":_vm.downloading > 0},on:{"click":_vm.downloadWordFile}},[_vm._v(" Scarica "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-download")])],1),_c(VBtn,{staticClass:"mr-2",attrs:{"color":"secondary","disabled":_vm.wordTemplateSelected == null,"loading":_vm.sending_email > 0},on:{"click":_vm.emailWordFile}},[_vm._v(" Invia Email "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-email-outline")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }