<template>
  <span>
<v-tooltip bottom >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                color="blue-grey white--text"
                class="ml-2"
                @click="show_annual_balancer = true"
            >
              <v-icon>mdi-scale-unbalanced</v-icon>
            </v-btn>
          </template>
          <span>Chiusura Anno con compensazione</span>
        </v-tooltip>
        <v-dialog v-model="show_annual_balancer" max-width="600px">
      <v-card>
        <v-card-title>Chiudi Bilancio Annuo
          <v-spacer></v-spacer>
          <v-btn @click="show_annual_balancer = false" fab x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <AnnualBalancer :customers="customers_selected"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>
<script>



import moment from "moment";
import AnnualBalancer from "@/components/custom/AnnualBalancer.vue";

export default {
  name: "annualBalancerBtn",
  components: {AnnualBalancer},
  props:{
    customers_selected:{type:Array, default:()=>[]},
  },
  data:()=>({
    show_annual_balancer: false,

  }),
  methods:{
    moment: function (date_string, date_format) {
      return moment(date_string, date_format);
    },


  }
}
</script>


<style scoped>

</style>