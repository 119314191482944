<template>
  <v-container fluid>
    <v-progress-linear indeterminate v-show="validating>0"></v-progress-linear>
  <v-alert v-if="isValid !== null" outlined :type="isValid ? 'success' : 'warning'" >
      <h5> {{ validationMessage }}</h5>
      <div>{{validationDetailedMessage}}</div>
  </v-alert>
  </v-container>
</template>

<script>


import mj_axios from "@/mj_axios";

export default {
  name: "MjEDocumentValidator",
  props: ['eDocumentId'],
  created() {

  },
  data: () => ({
    validationMessage: '',
    validationDetailedMessage:'',
    validating: 0,
    isValid:null,
  }),
  methods: {
    validate() {
      this.validating++;
      const axios_promise = mj_axios.get(`/api/edocuments/${this.eDocumentId}/actions/validate_xml`);
      axios_promise.then((response) => {
        this.validationMessage = response.data.message ?? 'N.D.';
        this.validationDetailedMessage = response.data.details ?? '';
        this.isValid = true;
      }).catch((e) => {
        const status = (e.response) ? e.response.status : null;


        if (status == 400) {
          this.isValid = false;
          this.validationMessage = (e.response) && (e.response.data) ? e.response.data.message : 'N.D.';
          this.validationDetailedMessage = (e.response) && (e.response.data) ? e.response.data.details : 'N.D.';
        }
        else {
          this.$store.commit("update_modal_dialog", {
            title: "Attenzione!!",
            text: "Errore di validazione, è consigliato, prima di proseguire, risolvere il problema.",
            visible: true,
          });
        }
      }).then(() => {
        this.validating--;
      });
      return axios_promise;
    }
  }
}
</script>

<style scoped>

</style>